import React from "react";
import { motion } from "framer-motion";

import { CharacterZen } from "./CharacterZen";

type CharacterShape = "circle" | "square" | "triangle" | "cloud" | "blob";
type FaceType = "simple" | "happy" | "wink" | "cat" | "sleepy";

interface Character {
  color: string;
  size: number;
  shape: CharacterShape;
  face: FaceType;
}

const characters: Character[] = [
  { color: "#60A5FA", size: 80, shape: "square", face: "simple" },
  { color: "#34D399", size: 70, shape: "circle", face: "happy" },
  { color: "#FBBF24", size: 75, shape: "triangle", face: "wink" },
  { color: "#F87171", size: 85, shape: "cloud", face: "cat" },
  { color: "#A78BFA", size: 70, shape: "blob", face: "sleepy" },
];

const PlayfulAnimatedCharacters: React.FC = () => {
  return (
    <div className="relative w-full h-screen overflow-hidden bg-gray-100">
      {characters.map((char, index) => (
        <motion.div
          key={index}
          className="absolute"
          initial={{
            x: Math.random() * window.innerWidth,
            y: Math.random() * window.innerHeight,
            opacity: 0,
            scale: 0,
          }}
          animate={{
            x: Math.random() * window.innerWidth,
            y: Math.random() * window.innerHeight,
            opacity: 1,
            scale: 1,
            rotate: [0, 5, -5, 0],
          }}
          transition={{
            duration: 15 + Math.random() * 10,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut",
          }}
        >
          <CharacterComponent {...char} />
        </motion.div>
      ))}
      <div className="absolute inset-0 flex items-center justify-center">
        <motion.div
          className="text-4xl font-bold text-gray-800"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 1 }}
        >
          0xZero web3 securities
          <CharacterZen />
        </motion.div>
      </div>
    </div>
  );
};

const shapePath: Record<CharacterShape, string> = {
  circle: "M50,10 a40,40 0 1,0 0,80 a40,40 0 1,0 0,-80",
  square: "M10,10 h80 v80 h-80 Z",
  triangle: "M50,10 L90,90 L10,90 Z",
  cloud: "M25,60 a20,20 0 0,1 0,-40 h50 a20,20 0 0,1 0,40 Z",
  blob: "M50,10 C20,10 10,30 10,50 C10,70 20,90 50,90 C80,90 90,70 90,50 C90,30 80,10 50,10",
};

const faceElements: Record<FaceType, JSX.Element> = {
  simple: (
    <g>
      <circle cx="35" cy="40" r="5" fill="black" />
      <circle cx="65" cy="40" r="5" fill="black" />
      <path
        d="M40,60 Q50,70 60,60"
        stroke="black"
        strokeWidth="3"
        fill="none"
      />
    </g>
  ),
  happy: (
    <g>
      <circle cx="35" cy="40" r="5" fill="black" />
      <circle cx="65" cy="40" r="5" fill="black" />
      <path
        d="M30,60 Q50,80 70,60"
        stroke="black"
        strokeWidth="3"
        fill="none"
      />
    </g>
  ),
  wink: (
    <g>
      <circle cx="35" cy="40" r="5" fill="black" />
      <path
        d="M60,40 Q65,35 70,40"
        stroke="black"
        strokeWidth="3"
        fill="none"
      />
      <path
        d="M30,60 Q50,70 70,60"
        stroke="black"
        strokeWidth="3"
        fill="none"
      />
    </g>
  ),
  cat: (
    <g>
      <path d="M30,35 L40,25 L40,45 Z" fill="black" />
      <path d="M70,35 L60,25 L60,45 Z" fill="black" />
      <circle cx="35" cy="50" r="3" fill="black" />
      <circle cx="65" cy="50" r="3" fill="black" />
      <path d="M50,55 L45,60 L55,60 Z" fill="black" />
    </g>
  ),
  sleepy: (
    <g>
      <path
        d="M30,40 Q35,35 40,40"
        stroke="black"
        strokeWidth="3"
        fill="none"
      />
      <path
        d="M60,40 Q65,35 70,40"
        stroke="black"
        strokeWidth="3"
        fill="none"
      />
      <path
        d="M40,60 Q50,55 60,60"
        stroke="black"
        strokeWidth="3"
        fill="none"
      />
    </g>
  ),
};

const CharacterComponent: React.FC<Character> = ({
  color,
  size,
  shape,
  face,
}) => {
  return (
    <motion.svg
      width={size}
      height={size}
      viewBox="0 0 100 100"
      initial="hidden"
      animate="visible"
    >
      {/* Body */}
      <motion.path
        d={shapePath[shape]}
        fill={color}
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.5 }}
      />

      {/* Face */}
      {faceElements[face]}

      {/* Arms and Legs */}
      <motion.line
        x1="15"
        y1="50"
        x2="0"
        y2="50"
        stroke="black"
        strokeWidth="3"
        animate={{ rotate: [0, 30, 0] }}
        transition={{ yoyo: Infinity, duration: 2 }}
      />
      <motion.line
        x1="85"
        y1="50"
        x2="100"
        y2="50"
        stroke="black"
        strokeWidth="3"
        animate={{ rotate: [0, -30, 0] }}
        transition={{ yoyo: Infinity, duration: 2 }}
      />
      <motion.line
        x1="40"
        y1="90"
        x2="40"
        y2="110"
        stroke="black"
        strokeWidth="3"
        animate={{ rotate: [0, 15, 0] }}
        transition={{ yoyo: Infinity, duration: 1.5 }}
      />
      <motion.line
        x1="60"
        y1="90"
        x2="60"
        y2="110"
        stroke="black"
        strokeWidth="3"
        animate={{ rotate: [0, -15, 0] }}
        transition={{ yoyo: Infinity, duration: 1.5 }}
      />
    </motion.svg>
  );
};

export default PlayfulAnimatedCharacters;
