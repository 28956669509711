import React from "react";

export const CharacterZen: React.FC = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="100%"
      viewBox="0 0 1024 1024"
      enable-background="new 0 0 1024 1024"
    >
      <path
        fill="transparent"
        opacity="1.000000"
        stroke="none"
        d="
M544.000000,1025.000000 
	C362.666656,1025.000000 181.833328,1025.000000 1.000000,1025.000000 
	C1.000000,683.666687 1.000000,342.333344 1.000000,1.000000 
	C342.333344,1.000000 683.666687,1.000000 1025.000000,1.000000 
	C1025.000000,342.333344 1025.000000,683.666687 1025.000000,1025.000000 
	C864.833313,1025.000000 704.666687,1025.000000 544.000000,1025.000000 
M292.392761,886.020264 
	C292.392761,886.020264 292.803162,886.116577 292.890259,886.859497 
	C294.033844,892.887329 298.666962,892.859985 303.291473,892.860474 
	C404.395721,892.872437 505.500000,892.889648 606.604248,892.897156 
	C654.408081,892.900757 702.211853,892.902100 750.015686,892.859497 
	C755.938965,892.854248 761.910889,893.046875 765.731934,886.883179 
	C766.539734,885.580139 769.202209,885.426880 771.861572,884.889709 
	C773.581787,884.855530 775.301941,884.821350 777.502502,884.901855 
	C777.502502,884.901855 777.989563,884.820374 778.645630,884.896973 
	C779.084229,884.868958 779.522888,884.840942 780.897400,884.957886 
	C801.854980,884.974060 822.812622,885.005554 843.770142,884.972839 
	C845.204651,884.970581 846.638428,884.488220 848.790955,884.235718 
	C849.509338,884.231995 850.227661,884.228333 851.540649,884.687683 
	C854.256592,885.396484 856.365784,885.017639 855.961609,881.561951 
	C855.393982,876.709412 857.767944,875.723572 862.099792,875.798401 
	C875.531860,876.030518 888.969971,875.922424 902.405701,875.940063 
	C905.054749,875.943542 907.705017,875.920166 906.413513,871.202332 
	C906.569031,869.477844 906.724548,867.753418 907.273682,865.365784 
	C909.037231,864.616394 910.781677,863.279358 912.567810,863.221130 
	C919.650330,862.989868 926.821411,862.539124 933.810730,863.391296 
	C938.668945,863.983582 939.284912,862.199524 939.035828,858.435242 
	C938.959717,857.284607 938.938049,856.130371 939.002197,854.229553 
	C938.822449,852.529114 938.642639,850.828735 938.839172,848.442993 
	C939.499451,845.831055 938.419128,844.807556 935.804626,844.871948 
	C930.871582,844.993469 925.935486,844.989075 920.114685,844.977722 
	C917.749268,845.019104 915.383850,845.060425 912.173767,845.003479 
	C902.464417,845.131714 909.601562,836.755554 905.848328,833.234314 
	C905.499573,833.195740 904.537292,832.995789 903.575256,832.997131 
	C889.601685,833.015930 875.627991,833.041992 861.654785,833.134033 
	C860.380615,833.142395 859.109497,833.614746 857.237305,833.853394 
	C856.836914,833.860962 856.436462,833.868530 855.995728,833.427734 
	C855.995728,833.427734 855.872375,832.994751 855.984375,832.094299 
	C856.065308,822.315002 856.065369,822.316162 846.368408,822.889160 
	C845.871216,822.918518 845.371216,822.901733 844.872498,822.901855 
	C827.084656,822.906372 809.296326,822.845581 791.509277,822.970276 
	C788.054810,822.994446 785.609985,822.010132 783.423279,819.215515 
	C780.881531,815.967041 777.977966,812.686829 772.247192,814.037048 
	C771.507446,814.074646 770.767761,814.112305 769.092590,814.018738 
	C716.422913,814.025452 663.753235,814.032166 610.921753,813.108765 
	C610.888550,796.783386 610.862183,780.457947 610.818848,764.132568 
	C610.800903,757.374817 610.207458,756.833374 603.284851,756.886475 
	C599.518799,756.915405 595.752258,756.891724 591.670044,756.891724 
	C591.670044,748.961731 591.670044,741.566528 591.670044,733.599731 
	C593.777344,733.599731 595.566528,733.604492 597.355774,733.598938 
	C607.520630,733.567627 617.686340,733.447510 627.849915,733.545532 
	C631.654114,733.582153 633.379211,732.156799 633.190979,728.259338 
	C633.018616,724.690613 633.154358,721.107056 633.154358,717.116638 
	C641.780762,717.116638 649.598877,717.226318 657.412292,717.081299 
	C663.453003,716.969177 667.187134,713.237610 667.622803,707.258240 
	C667.776978,705.142334 667.839233,703.019653 667.943237,700.904297 
	C672.899292,699.776245 679.395447,702.018494 681.356079,696.015991 
	C682.844299,691.459656 682.100891,686.174438 682.349670,681.228638 
	C690.859741,679.310547 690.849670,679.310547 690.832764,670.347168 
	C690.818115,662.577637 690.829712,654.808105 690.829712,646.838684 
	C692.248901,646.771790 693.218933,646.686584 694.188904,646.686157 
	C714.352417,646.677002 734.516846,646.574646 754.678589,646.761292 
	C758.802551,646.799500 760.526123,645.407898 760.443176,641.381165 
	C760.359192,637.304565 760.424683,633.224915 760.424683,629.294800 
	C770.874207,628.144104 777.414246,625.080688 776.826355,612.423035 
	C776.046387,595.630493 776.709045,578.770447 776.784180,561.939575 
	C776.792114,560.156067 777.016418,558.373413 777.140381,556.590393 
	C778.554688,557.740662 778.740295,558.881714 779.183044,559.912354 
	C781.150635,564.492004 783.636353,568.859558 789.327881,568.910767 
	C806.823669,569.068237 824.322754,569.058716 841.818420,568.887085 
	C847.135986,568.834961 850.282410,565.484375 851.015991,560.169189 
	C851.192444,558.890442 851.411011,557.617615 851.627197,556.233521 
	C856.661499,555.797058 861.657959,556.121948 866.164307,554.789429 
	C872.384705,552.950073 868.931274,546.340149 871.059265,541.943237 
	C889.569702,543.557251 884.657288,529.306091 885.795532,519.386230 
	C893.060547,518.147339 895.410522,515.794434 895.179688,508.851013 
	C894.764709,496.365875 895.034424,483.857666 895.048340,471.359131 
	C895.049927,469.936859 895.246094,468.514801 895.257385,468.362732 
	C901.607361,466.830353 907.707214,466.567871 912.470093,463.909760 
	C918.745544,460.407410 914.794617,452.920654 916.305176,447.120392 
	C923.079163,447.120392 929.709473,447.174927 936.338440,447.104736 
	C942.697937,447.037415 944.718872,445.001221 944.807068,438.746246 
	C944.850769,435.646118 944.814697,432.544800 944.814697,429.129425 
	C946.968689,429.129425 948.442322,429.179382 949.911621,429.120819 
	C956.464966,428.859680 957.094910,428.233459 957.154480,421.874237 
	C957.188232,418.269592 957.160522,414.664307 957.160522,411.092987 
	C962.025391,410.260529 966.854309,410.275787 966.805054,403.673462 
	C966.658386,384.011047 966.640625,364.346985 966.725037,344.684204 
	C966.743591,340.350067 964.840820,338.132172 960.712219,337.657440 
	C957.450745,337.282379 954.172852,337.051361 950.779968,336.745392 
	C950.779968,333.031372 950.757568,329.555695 950.785583,326.080414 
	C950.820801,321.702637 948.617676,319.688324 944.305542,319.752869 
	C941.208069,319.799225 938.109375,319.761871 934.620728,319.761871 
	C934.620728,308.920349 934.620728,298.686829 934.620728,288.508942 
	C942.835144,287.425964 943.056030,287.157745 943.017517,279.041870 
	C943.000366,275.434540 943.014587,271.827087 943.014587,268.446320 
	C951.203125,265.541473 951.247681,265.512390 951.302063,257.724152 
	C951.360229,249.391953 951.295959,241.058182 951.438721,232.727722 
	C951.488770,229.804626 950.520508,227.440430 947.577393,227.363480 
	C940.780518,227.185791 941.113037,222.873856 941.472961,218.124588 
	C941.989563,211.308624 938.798828,207.914139 931.908447,207.918228 
	C902.578735,207.935623 873.248901,207.990707 843.919312,208.069717 
	C837.521545,208.086945 835.650085,209.954102 835.739563,215.905548 
	C835.897583,226.415619 835.900452,226.477905 825.470459,226.963181 
	C821.566101,227.144852 819.748474,228.747757 819.907104,232.831711 
	C820.113892,238.156387 819.953247,243.495270 819.952881,248.828049 
	C819.952087,261.141602 819.952637,273.455139 819.952637,286.152344 
	C817.368530,286.395660 815.073914,286.891022 812.812927,286.768097 
	C807.655701,286.487732 806.043396,289.160706 806.251343,293.796448 
	C806.368042,296.398041 806.272339,299.009216 806.272339,301.991638 
	C800.244263,301.991638 794.947571,301.996002 789.650818,301.990570 
	C782.177673,301.982910 779.506409,304.595428 779.221741,312.095764 
	C779.123047,314.697357 778.873169,317.293182 778.692322,319.891663 
	C778.112427,319.851562 777.532593,319.811493 776.952698,319.771393 
	C776.952698,317.874878 776.955261,315.978394 776.952332,314.081879 
	C776.912170,288.419495 776.877380,262.757111 776.826111,237.094757 
	C776.813843,230.957626 773.933899,227.704224 767.801636,226.965332 
	C765.691284,226.711060 763.551575,226.700867 760.880310,226.547989 
	C760.880310,223.227097 760.835876,220.112564 760.889404,216.999695 
	C761.008667,210.063004 759.943542,209.025284 752.836792,209.129028 
	C740.340698,209.311432 727.844482,209.497025 715.347656,209.601425 
	C712.228088,209.627487 709.106018,209.349030 705.806702,209.202606 
	C705.806702,205.466537 705.815186,202.181290 705.804688,198.896088 
	C705.789917,194.293213 703.763489,192.116638 699.222534,192.006256 
	C696.392578,191.937469 693.557922,192.097641 690.725342,192.098343 
	C568.571899,192.129120 446.418488,192.153976 324.265076,192.177597 
	C316.037109,192.179184 314.304382,193.956741 314.295776,201.961456 
	C314.287720,209.477936 315.612640,209.643784 306.953705,209.854782 
	C298.957764,210.049606 295.725769,213.578461 295.712982,221.601532 
	C295.663879,252.431290 295.613831,283.261078 295.596893,314.090851 
	C295.570007,363.084900 295.503296,412.079132 295.662994,461.072632 
	C295.678467,465.809723 294.104797,467.174988 289.686890,466.869446 
	C285.039917,466.548035 280.359863,466.700562 275.694153,466.656219 
	C263.000183,466.535583 264.683777,467.039490 264.559967,477.359924 
	C264.541901,478.865601 263.291138,481.660461 262.650543,481.648926 
	C257.402069,481.554535 257.875702,485.073669 257.815033,488.445160 
	C257.783112,490.219574 257.551971,491.990417 257.399994,493.898560 
	C251.239944,493.898560 245.572800,494.189148 239.973221,493.702515 
	C238.499146,493.574402 236.473679,491.239136 236.042694,489.570923 
	C235.310822,486.738068 235.582520,483.630768 235.544464,480.637390 
	C235.488190,476.211578 235.529922,471.784576 235.529922,467.681183 
	C232.319321,466.923370 230.062622,466.390747 227.805908,465.858093 
	C227.913986,465.337524 228.022049,464.816925 228.130112,464.296326 
	C229.697311,464.296326 231.264542,464.288330 232.831680,464.297729 
	C239.765884,464.339355 240.992493,463.138367 241.032166,456.213593 
	C241.048889,453.294983 241.034973,450.376190 241.034973,447.046722 
	C245.712326,447.046722 249.529953,446.885040 253.327621,447.091217 
	C257.293152,447.306519 258.749451,445.258087 258.803345,441.720490 
	C258.863556,437.772461 258.817169,433.822815 258.817169,429.772552 
	C260.131897,429.572968 260.937897,429.339630 261.741791,429.346619 
	C266.670898,429.389740 268.530853,426.981354 268.106781,422.128815 
	C267.790497,418.509857 268.044403,414.841064 268.044403,411.240204 
	C277.005035,409.562836 276.957733,409.562866 276.950104,400.401855 
	C276.929993,376.237793 276.887695,352.073334 277.021820,327.909973 
	C277.046967,323.379181 275.095703,321.243988 270.837250,320.454865 
	C263.942932,319.177246 263.958496,319.091736 263.889740,311.766357 
	C263.880341,310.766510 263.884796,309.766449 263.866699,308.766785 
	C263.773621,303.623230 262.205536,302.036224 256.979340,301.984100 
	C251.670654,301.931152 246.361038,301.972382 240.593536,301.972382 
	C240.460724,299.244049 240.257919,296.781586 240.236725,294.317627 
	C240.176804,287.353302 239.532639,286.381714 232.526474,286.788147 
	C227.857895,287.058990 226.897980,285.254425 226.962723,281.055695 
	C227.211929,264.894226 227.109207,248.727524 227.296494,232.564621 
	C227.339188,228.880020 226.220718,227.083527 222.278290,226.843460 
	C212.469910,226.246201 212.477692,226.117661 212.758163,216.421906 
	C212.767776,216.089050 212.789429,215.756256 212.789368,215.423431 
	C212.788239,210.393814 210.643158,207.946426 205.595200,207.945770 
	C174.765442,207.941788 143.935410,207.991013 113.106140,208.148758 
	C107.365211,208.178116 104.902344,211.097641 104.891121,216.959641 
	C104.888573,218.292801 104.792458,219.635849 104.918312,220.957306 
	C105.269470,224.644516 104.304359,227.096405 100.013908,227.323441 
	C96.343307,227.517685 95.048111,229.770691 95.113174,233.371826 
	C95.260651,241.534790 95.204506,249.702866 95.132256,257.867981 
	C95.060516,265.975739 95.259857,266.339081 102.981155,268.886688 
	C102.981155,271.954102 102.998070,275.107666 102.977913,278.260925 
	C102.922791,286.886108 103.168373,287.184387 111.961838,288.380371 
	C111.961838,295.441284 111.962677,302.578094 111.961624,309.714905 
	C111.960167,319.533478 111.959557,319.533478 102.078377,319.532867 
	C97.261452,319.532562 95.473869,321.297974 95.422577,326.191284 
	C95.386223,329.659271 95.415527,333.127960 95.415527,336.996246 
	C93.144157,337.131165 91.013077,337.333588 88.879112,337.370789 
	C81.515282,337.499115 80.123215,338.857269 80.113419,346.131958 
	C80.089195,364.130096 80.088898,382.128235 80.048058,400.126343 
	C80.026222,409.749512 80.500053,410.319519 90.145393,411.146301 
	C90.145393,413.086761 90.123764,415.065948 90.149017,417.044525 
	C90.280090,427.315887 88.142143,429.382965 101.620842,429.451050 
	C101.883041,429.452423 102.143486,429.802673 102.733070,430.225739 
	C102.733070,432.802948 102.712196,435.615662 102.737152,438.427948 
	C102.800827,445.602722 104.340881,447.120911 111.685333,447.154633 
	C118.641907,447.186584 125.598747,447.161530 133.118240,447.161530 
	C133.118240,450.161346 133.096313,452.776672 133.122299,455.391541 
	C133.204727,463.688416 136.752670,467.030365 145.213989,466.893921 
	C148.995621,466.832886 152.779068,466.883606 156.985535,466.883606 
	C156.985535,480.757477 157.264114,494.220337 156.879135,507.664246 
	C156.654846,515.497192 158.578827,518.308411 167.115341,519.508423 
	C167.115341,523.408875 167.003143,527.382751 167.139557,531.348083 
	C167.394730,538.765137 170.888718,541.861145 178.263428,541.456360 
	C179.678116,541.378723 181.100708,541.445251 182.683899,541.445251 
	C182.814011,543.194214 182.939850,544.503418 183.004349,545.815674 
	C183.390717,553.676147 185.610855,555.777161 193.433960,555.709534 
	C196.708435,555.681152 199.983368,555.704773 203.304123,555.704773 
	C203.438385,556.998413 203.562241,557.816895 203.602737,558.639465 
	C204.055511,567.837708 205.444031,569.123413 214.755295,569.093994 
	C226.920532,569.055542 239.085876,569.032898 251.251160,569.038696 
	C262.306549,569.043945 262.306549,569.064758 262.778900,558.006897 
	C275.992767,558.976257 286.666870,554.002991 295.496643,541.999817 
	C295.496643,551.319580 295.332825,559.056091 295.529785,566.783447 
	C295.980316,584.460205 292.605347,582.325012 309.970184,589.038330 
	C310.280426,589.158264 310.713348,589.164062 310.894623,589.383667 
	C313.510590,592.552917 316.614044,595.499573 318.344513,599.102844 
	C318.821167,600.095337 315.065674,602.983826 313.442291,605.145203 
	C306.698975,614.123535 298.044647,617.979065 286.723572,616.121887 
	C283.162445,615.537720 280.776978,617.791565 281.067810,621.903748 
	C281.208740,623.896179 281.136047,625.902771 281.228394,627.899780 
	C281.355225,630.642944 281.086182,632.824463 278.036957,634.338379 
	C276.601074,635.051147 275.429871,637.758301 275.392609,639.587280 
	C275.168579,650.580750 275.381927,661.582275 275.291809,672.579773 
	C275.254028,677.188110 276.241028,680.833374 281.712402,681.375366 
	C281.712402,683.504456 281.712463,685.333801 281.712402,687.163147 
	C281.712341,688.996277 281.682709,690.829956 281.717468,692.662415 
	C281.833649,698.787842 283.349640,700.455811 289.388977,700.586487 
	C295.498016,700.718689 301.612122,700.615662 307.986633,700.615662 
	C308.050232,701.693970 308.114685,702.492615 308.141907,703.292542 
	C308.514984,714.257385 311.675598,717.269897 322.835449,717.278259 
	C328.978546,717.282898 335.121643,717.279053 341.627167,717.279053 
	C341.627167,720.374329 341.630615,723.174255 341.626495,725.974182 
	C341.616547,732.736816 342.511719,733.701172 349.451141,733.661316 
	C361.615204,733.591492 373.778534,733.404724 385.942322,733.279846 
	C388.708374,733.251404 391.474915,733.275635 394.211975,733.275635 
	C394.211975,741.593994 394.211975,749.134949 394.211975,756.970337 
	C391.094879,756.970337 388.440186,756.984375 385.785645,756.967896 
	C375.980927,756.907104 376.026276,756.903320 376.017761,766.920776 
	C376.004456,782.580688 375.922729,798.240601 374.949402,813.941223 
	C349.639709,813.947144 324.329865,813.992432 299.020477,813.908020 
	C295.937653,813.897766 293.647797,814.440430 291.993744,817.374084 
	C289.477722,821.836304 285.559143,823.157837 280.323730,823.063354 
	C261.846771,822.729614 243.360138,822.946411 224.877243,822.916626 
	C221.054367,822.910461 217.199265,823.022278 213.416489,822.583862 
	C207.418854,821.888794 206.932480,821.911072 207.135986,827.968201 
	C207.278900,832.222290 205.731628,833.310669 201.676163,833.227600 
	C188.526352,832.958191 175.369171,833.036987 162.214783,833.013428 
	C160.277954,833.010010 158.340805,833.178955 156.519592,833.262878 
	C154.803986,844.638855 154.803986,844.638855 144.096054,844.983276 
	C141.730667,845.003052 139.365265,845.022827 136.069229,844.951172 
	C121.554077,844.771729 121.640709,844.769287 122.024506,858.410278 
	C122.139633,862.502197 123.858215,864.100281 127.861244,863.894836 
	C134.829803,863.537231 141.806351,863.245483 148.782562,863.152893 
	C155.230026,863.067261 156.060776,863.806519 155.855927,870.290039 
	C155.718277,874.646790 157.208359,876.121704 161.610809,876.034241 
	C174.931839,875.769409 188.261398,875.964172 201.585526,875.803772 
	C205.213806,875.760132 207.447617,876.567139 207.048309,880.752808 
	C206.738861,883.996704 208.400848,885.126160 211.558990,885.069397 
	C221.381393,884.892883 231.207031,884.896301 241.736069,884.939331 
	C242.851730,884.729736 243.967377,884.520142 245.903198,884.322449 
	C249.895844,884.325867 253.888489,884.329285 258.584106,884.771606 
	C263.399139,884.785034 268.214203,884.798462 273.953003,884.923462 
	C278.974091,884.897400 283.995178,884.871338 289.416351,885.386902 
	C290.278259,885.545288 291.140137,885.703674 292.392761,886.020264 
z"
      />
      <path
        fill="#050415"
        opacity="1.000000"
        stroke="none"
        d="
M375.869995,813.900513 
	C375.922729,798.240601 376.004456,782.580688 376.017761,766.920776 
	C376.026276,756.903320 375.980927,756.907104 385.785645,756.967896 
	C388.440186,756.984375 391.094879,756.970337 394.211975,756.970337 
	C394.211975,749.134949 394.211975,741.593994 394.211975,733.275635 
	C391.474915,733.275635 388.708374,733.251404 385.942322,733.279846 
	C373.778534,733.404724 361.615204,733.591492 349.451141,733.661316 
	C342.511719,733.701172 341.616547,732.736816 341.626495,725.974182 
	C341.630615,723.174255 341.627167,720.374329 341.627167,717.279053 
	C335.121643,717.279053 328.978546,717.282898 322.835449,717.278259 
	C311.675598,717.269897 308.514984,714.257385 308.141907,703.292542 
	C308.114685,702.492615 308.050232,701.693970 307.986633,700.615662 
	C301.612122,700.615662 295.498016,700.718689 289.388977,700.586487 
	C283.349640,700.455811 281.833649,698.787842 281.717468,692.662415 
	C281.682709,690.829956 281.712341,688.996277 281.712402,687.163147 
	C281.712463,685.333801 281.712402,683.504456 281.712402,681.375366 
	C276.241028,680.833374 275.254028,677.188110 275.291809,672.579773 
	C275.381927,661.582275 275.168579,650.580750 275.392609,639.587280 
	C275.429871,637.758301 276.601074,635.051147 278.036957,634.338379 
	C281.086182,632.824463 281.355225,630.642944 281.228394,627.899780 
	C281.136047,625.902771 281.208740,623.896179 281.067810,621.903748 
	C280.776978,617.791565 283.162445,615.537720 286.723572,616.121887 
	C298.044647,617.979065 306.698975,614.123535 313.442291,605.145203 
	C315.065674,602.983826 318.821167,600.095337 318.344513,599.102844 
	C316.614044,595.499573 313.510590,592.552917 310.894623,589.383667 
	C310.713348,589.164062 310.280426,589.158264 309.970184,589.038330 
	C292.605347,582.325012 295.980316,584.460205 295.529785,566.783447 
	C295.332825,559.056091 295.496643,551.319580 295.496643,541.999817 
	C286.666870,554.002991 275.992767,558.976257 262.778900,558.006897 
	C262.306549,569.064758 262.306549,569.043945 251.251160,569.038696 
	C239.085876,569.032898 226.920532,569.055542 214.755295,569.093994 
	C205.444031,569.123413 204.055511,567.837708 203.602737,558.639465 
	C203.562241,557.816895 203.438385,556.998413 203.304123,555.704773 
	C199.983368,555.704773 196.708435,555.681152 193.433960,555.709534 
	C185.610855,555.777161 183.390717,553.676147 183.004349,545.815674 
	C182.939850,544.503418 182.814011,543.194214 182.683899,541.445251 
	C181.100708,541.445251 179.678116,541.378723 178.263428,541.456360 
	C170.888718,541.861145 167.394730,538.765137 167.139557,531.348083 
	C167.003143,527.382751 167.115341,523.408875 167.115341,519.508423 
	C158.578827,518.308411 156.654846,515.497192 156.879135,507.664246 
	C157.264114,494.220337 156.985535,480.757477 156.985535,466.883606 
	C152.779068,466.883606 148.995621,466.832886 145.213989,466.893921 
	C136.752670,467.030365 133.204727,463.688416 133.122299,455.391541 
	C133.096313,452.776672 133.118240,450.161346 133.118240,447.161530 
	C125.598747,447.161530 118.641907,447.186584 111.685333,447.154633 
	C104.340881,447.120911 102.800827,445.602722 102.737152,438.427948 
	C102.712196,435.615662 102.733070,432.802948 102.733070,430.225739 
	C102.143486,429.802673 101.883041,429.452423 101.620842,429.451050 
	C88.142143,429.382965 90.280090,427.315887 90.149017,417.044525 
	C90.123764,415.065948 90.145393,413.086761 90.145393,411.146301 
	C80.500053,410.319519 80.026222,409.749512 80.048058,400.126343 
	C80.088898,382.128235 80.089195,364.130096 80.113419,346.131958 
	C80.123215,338.857269 81.515282,337.499115 88.879112,337.370789 
	C91.013077,337.333588 93.144157,337.131165 95.415527,336.996246 
	C95.415527,333.127960 95.386223,329.659271 95.422577,326.191284 
	C95.473869,321.297974 97.261452,319.532562 102.078377,319.532867 
	C111.959557,319.533478 111.960167,319.533478 111.961624,309.714905 
	C111.962677,302.578094 111.961838,295.441284 111.961838,288.380371 
	C103.168373,287.184387 102.922791,286.886108 102.977913,278.260925 
	C102.998070,275.107666 102.981155,271.954102 102.981155,268.886688 
	C95.259857,266.339081 95.060516,265.975739 95.132256,257.867981 
	C95.204506,249.702866 95.260651,241.534790 95.113174,233.371826 
	C95.048111,229.770691 96.343307,227.517685 100.013908,227.323441 
	C104.304359,227.096405 105.269470,224.644516 104.918312,220.957306 
	C104.792458,219.635849 104.888573,218.292801 104.891121,216.959641 
	C104.902344,211.097641 107.365211,208.178116 113.106140,208.148758 
	C143.935410,207.991013 174.765442,207.941788 205.595200,207.945770 
	C210.643158,207.946426 212.788239,210.393814 212.789368,215.423431 
	C212.789429,215.756256 212.767776,216.089050 212.758163,216.421906 
	C212.477692,226.117661 212.469910,226.246201 222.278290,226.843460 
	C226.220718,227.083527 227.339188,228.880020 227.296494,232.564621 
	C227.109207,248.727524 227.211929,264.894226 226.962723,281.055695 
	C226.897980,285.254425 227.857895,287.058990 232.526474,286.788147 
	C239.532639,286.381714 240.176804,287.353302 240.236725,294.317627 
	C240.257919,296.781586 240.460724,299.244049 240.593536,301.972382 
	C246.361038,301.972382 251.670654,301.931152 256.979340,301.984100 
	C262.205536,302.036224 263.773621,303.623230 263.866699,308.766785 
	C263.884796,309.766449 263.880341,310.766510 263.889740,311.766357 
	C263.958496,319.091736 263.942932,319.177246 270.837250,320.454865 
	C275.095703,321.243988 277.046967,323.379181 277.021820,327.909973 
	C276.887695,352.073334 276.929993,376.237793 276.950104,400.401855 
	C276.957733,409.562866 277.005035,409.562836 268.044403,411.240204 
	C268.044403,414.841064 267.790497,418.509857 268.106781,422.128815 
	C268.530853,426.981354 266.670898,429.389740 261.741791,429.346619 
	C260.937897,429.339630 260.131897,429.572968 258.817169,429.772552 
	C258.817169,433.822815 258.863556,437.772461 258.803345,441.720490 
	C258.749451,445.258087 257.293152,447.306519 253.327621,447.091217 
	C249.529953,446.885040 245.712326,447.046722 241.034973,447.046722 
	C241.034973,450.376190 241.048889,453.294983 241.032166,456.213593 
	C240.992493,463.138367 239.765884,464.339355 232.831680,464.297729 
	C231.264542,464.288330 229.697311,464.296326 228.130112,464.296326 
	C228.022049,464.816925 227.913986,465.337524 227.805908,465.858093 
	C230.062622,466.390747 232.319321,466.923370 235.529922,467.681183 
	C235.529922,471.784576 235.488190,476.211578 235.544464,480.637390 
	C235.582520,483.630768 235.310822,486.738068 236.042694,489.570923 
	C236.473679,491.239136 238.499146,493.574402 239.973221,493.702515 
	C245.572800,494.189148 251.239944,493.898560 257.399994,493.898560 
	C257.551971,491.990417 257.783112,490.219574 257.815033,488.445160 
	C257.875702,485.073669 257.402069,481.554535 262.650543,481.648926 
	C263.291138,481.660461 264.541901,478.865601 264.559967,477.359924 
	C264.683777,467.039490 263.000183,466.535583 275.694153,466.656219 
	C280.359863,466.700562 285.039917,466.548035 289.686890,466.869446 
	C294.104797,467.174988 295.678467,465.809723 295.662994,461.072632 
	C295.503296,412.079132 295.570007,363.084900 295.596893,314.090851 
	C295.613831,283.261078 295.663879,252.431290 295.712982,221.601532 
	C295.725769,213.578461 298.957764,210.049606 306.953705,209.854782 
	C315.612640,209.643784 314.287720,209.477936 314.295776,201.961456 
	C314.304382,193.956741 316.037109,192.179184 324.265076,192.177597 
	C446.418488,192.153976 568.571899,192.129120 690.725342,192.098343 
	C693.557922,192.097641 696.392578,191.937469 699.222534,192.006256 
	C703.763489,192.116638 705.789917,194.293213 705.804688,198.896088 
	C705.815186,202.181290 705.806702,205.466537 705.806702,209.202606 
	C709.106018,209.349030 712.228088,209.627487 715.347656,209.601425 
	C727.844482,209.497025 740.340698,209.311432 752.836792,209.129028 
	C759.943542,209.025284 761.008667,210.063004 760.889404,216.999695 
	C760.835876,220.112564 760.880310,223.227097 760.880310,226.547989 
	C763.551575,226.700867 765.691284,226.711060 767.801636,226.965332 
	C773.933899,227.704224 776.813843,230.957626 776.826111,237.094757 
	C776.877380,262.757111 776.912170,288.419495 776.952332,314.081879 
	C776.955261,315.978394 776.952698,317.874878 776.952698,319.771393 
	C777.532593,319.811493 778.112427,319.851562 778.692322,319.891663 
	C778.873169,317.293182 779.123047,314.697357 779.221741,312.095764 
	C779.506409,304.595428 782.177673,301.982910 789.650818,301.990570 
	C794.947571,301.996002 800.244263,301.991638 806.272339,301.991638 
	C806.272339,299.009216 806.368042,296.398041 806.251343,293.796448 
	C806.043396,289.160706 807.655701,286.487732 812.812927,286.768097 
	C815.073914,286.891022 817.368530,286.395660 819.952637,286.152344 
	C819.952637,273.455139 819.952087,261.141602 819.952881,248.828049 
	C819.953247,243.495270 820.113892,238.156387 819.907104,232.831711 
	C819.748474,228.747757 821.566101,227.144852 825.470459,226.963181 
	C835.900452,226.477905 835.897583,226.415619 835.739563,215.905548 
	C835.650085,209.954102 837.521545,208.086945 843.919312,208.069717 
	C873.248901,207.990707 902.578735,207.935623 931.908447,207.918228 
	C938.798828,207.914139 941.989563,211.308624 941.472961,218.124588 
	C941.113037,222.873856 940.780518,227.185791 947.577393,227.363480 
	C950.520508,227.440430 951.488770,229.804626 951.438721,232.727722 
	C951.295959,241.058182 951.360229,249.391953 951.302063,257.724152 
	C951.247681,265.512390 951.203125,265.541473 943.014587,268.446320 
	C943.014587,271.827087 943.000366,275.434540 943.017517,279.041870 
	C943.056030,287.157745 942.835144,287.425964 934.620728,288.508942 
	C934.620728,298.686829 934.620728,308.920349 934.620728,319.761871 
	C938.109375,319.761871 941.208069,319.799225 944.305542,319.752869 
	C948.617676,319.688324 950.820801,321.702637 950.785583,326.080414 
	C950.757568,329.555695 950.779968,333.031372 950.779968,336.745392 
	C954.172852,337.051361 957.450745,337.282379 960.712219,337.657440 
	C964.840820,338.132172 966.743591,340.350067 966.725037,344.684204 
	C966.640625,364.346985 966.658386,384.011047 966.805054,403.673462 
	C966.854309,410.275787 962.025391,410.260529 957.160522,411.092987 
	C957.160522,414.664307 957.188232,418.269592 957.154480,421.874237 
	C957.094910,428.233459 956.464966,428.859680 949.911621,429.120819 
	C948.442322,429.179382 946.968689,429.129425 944.814697,429.129425 
	C944.814697,432.544800 944.850769,435.646118 944.807068,438.746246 
	C944.718872,445.001221 942.697937,447.037415 936.338440,447.104736 
	C929.709473,447.174927 923.079163,447.120392 916.305176,447.120392 
	C914.794617,452.920654 918.745544,460.407410 912.470093,463.909760 
	C907.707214,466.567871 901.607361,466.830353 895.257385,468.362732 
	C895.246094,468.514801 895.049927,469.936859 895.048340,471.359131 
	C895.034424,483.857666 894.764709,496.365875 895.179688,508.851013 
	C895.410522,515.794434 893.060547,518.147339 885.795532,519.386230 
	C884.657288,529.306091 889.569702,543.557251 871.059265,541.943237 
	C868.931274,546.340149 872.384705,552.950073 866.164307,554.789429 
	C861.657959,556.121948 856.661499,555.797058 851.627197,556.233521 
	C851.411011,557.617615 851.192444,558.890442 851.015991,560.169189 
	C850.282410,565.484375 847.135986,568.834961 841.818420,568.887085 
	C824.322754,569.058716 806.823669,569.068237 789.327881,568.910767 
	C783.636353,568.859558 781.150635,564.492004 779.183044,559.912354 
	C778.740295,558.881714 778.554688,557.740662 777.140381,556.590393 
	C777.016418,558.373413 776.792114,560.156067 776.784180,561.939575 
	C776.709045,578.770447 776.046387,595.630493 776.826355,612.423035 
	C777.414246,625.080688 770.874207,628.144104 760.424683,629.294800 
	C760.424683,633.224915 760.359192,637.304565 760.443176,641.381165 
	C760.526123,645.407898 758.802551,646.799500 754.678589,646.761292 
	C734.516846,646.574646 714.352417,646.677002 694.188904,646.686157 
	C693.218933,646.686584 692.248901,646.771790 690.829712,646.838684 
	C690.829712,654.808105 690.818115,662.577637 690.832764,670.347168 
	C690.849670,679.310547 690.859741,679.310547 682.349670,681.228638 
	C682.100891,686.174438 682.844299,691.459656 681.356079,696.015991 
	C679.395447,702.018494 672.899292,699.776245 667.943237,700.904297 
	C667.839233,703.019653 667.776978,705.142334 667.622803,707.258240 
	C667.187134,713.237610 663.453003,716.969177 657.412292,717.081299 
	C649.598877,717.226318 641.780762,717.116638 633.154358,717.116638 
	C633.154358,721.107056 633.018616,724.690613 633.190979,728.259338 
	C633.379211,732.156799 631.654114,733.582153 627.849915,733.545532 
	C617.686340,733.447510 607.520630,733.567627 597.355774,733.598938 
	C595.566528,733.604492 593.777344,733.599731 591.670044,733.599731 
	C591.670044,741.566528 591.670044,748.961731 591.670044,756.891724 
	C595.752258,756.891724 599.518799,756.915405 603.284851,756.886475 
	C610.207458,756.833374 610.800903,757.374817 610.818848,764.132568 
	C610.862183,780.457947 610.888550,796.783386 610.921997,814.030518 
	C610.922302,820.373108 610.922302,825.793884 610.922302,831.299561 
	C636.163269,831.299561 660.725769,831.299561 685.504028,831.504883 
	C686.103088,831.675903 686.486389,831.641541 687.324951,831.706848 
	C692.955505,832.181335 698.130920,832.556152 703.303467,833.377930 
	C703.300537,837.089355 703.300537,840.353821 703.300537,844.109985 
	C710.447876,844.109985 716.677979,844.109985 723.215515,844.109985 
	C723.215515,847.994202 723.215515,851.373657 723.215515,855.335938 
	C710.206970,855.335938 697.654541,855.335938 684.662048,855.335938 
	C684.662048,861.530334 684.662048,867.149841 684.331604,873.015625 
	C682.260498,873.534790 680.520691,874.039734 678.779114,874.045471 
	C656.136597,874.120117 633.493713,874.102966 610.851074,874.154114 
	C598.870972,874.181213 586.891052,874.296143 574.460999,874.263977 
	C568.042358,874.227966 562.073730,874.299316 555.747375,874.275513 
	C554.904663,874.245361 554.419739,874.310547 553.716919,874.289795 
	C553.499084,874.203918 553.071594,874.395081 552.607422,874.300476 
	C541.079712,874.242737 530.016174,874.279602 518.542969,874.211060 
	C516.450195,874.182007 514.767151,874.258301 512.618042,874.244934 
	C502.744080,874.210510 493.336121,874.265747 483.487396,874.215515 
	C479.726562,874.181213 476.406464,874.252380 472.621674,874.225342 
	C457.700684,874.132935 443.244354,874.127197 428.788116,874.147095 
	C406.194916,874.178223 383.601685,874.226685 360.667114,874.148132 
	C359.365936,873.937744 358.406097,873.846985 357.284668,873.326660 
	C357.123047,867.291138 357.123047,861.685303 357.123047,855.249756 
	C350.548828,855.249756 344.652252,855.249756 338.524841,855.249756 
	C338.524841,851.052124 338.524841,847.697388 338.524841,843.979248 
	C340.789490,843.979248 342.607666,843.991516 344.425659,843.977295 
	C356.616058,843.881836 356.616058,843.880005 357.563599,831.979248 
	C362.636017,831.652100 367.307556,831.415894 372.195129,831.246582 
	C372.704498,831.265869 372.988708,831.185974 373.679779,831.168457 
	C374.742493,830.135742 375.905334,829.026245 375.945465,827.877625 
	C376.108063,823.225159 375.925323,818.560608 375.869995,813.900513 
M476.519958,817.851318 
	C476.757996,817.590271 476.996033,817.329224 477.896484,816.953552 
	C480.752106,814.589294 483.325256,811.684631 486.538757,810.005127 
	C490.115356,808.135803 491.753326,805.923767 491.070984,801.154663 
	C491.322723,798.424683 491.574493,795.694763 492.395142,792.808411 
	C492.930237,792.559937 493.465302,792.311462 494.893951,792.074768 
	C506.127838,791.981934 506.392700,791.735840 505.864990,781.174194 
	C505.795715,779.782104 505.726471,778.389954 505.758057,776.300842 
	C504.821442,775.246460 504.068359,773.893921 502.918457,773.185913 
	C495.322021,768.508972 487.466309,764.232422 480.043732,759.300659 
	C474.485870,755.607849 470.481445,750.720215 471.776947,743.138672 
	C472.150238,740.953918 470.940979,738.532349 471.128571,736.275146 
	C471.490295,731.922607 468.574493,730.369995 465.448395,730.283997 
	C461.789429,730.183411 456.889801,727.690063 453.991150,733.386658 
	C440.575226,733.386658 427.159302,733.386658 413.324707,733.386658 
	C413.324707,736.020691 413.335327,737.832581 413.322968,739.644287 
	C413.249054,750.476013 413.257782,761.309753 412.999146,772.137207 
	C412.959625,773.791565 411.676270,775.416321 410.070618,776.958191 
	C407.909729,777.075989 405.739410,777.380737 403.589874,777.272583 
	C399.110718,777.047180 397.653992,778.950500 397.721039,783.445862 
	C397.897247,795.262146 398.325226,807.152405 397.262604,818.887268 
	C396.229187,830.299194 399.183777,839.383240 408.001709,846.591553 
	C408.249939,846.794556 408.540497,846.945679 409.174408,847.701050 
	C411.750275,849.744812 414.147125,853.197510 416.937592,853.551941 
	C423.656830,854.405457 430.562927,853.905762 437.387329,853.719543 
	C438.873444,853.679016 440.943298,853.092041 441.691071,852.021301 
	C443.990204,848.728882 446.998260,847.764709 450.735931,847.916565 
	C455.125671,848.094971 457.589478,846.476074 457.000366,841.504639 
	C456.670593,838.721497 457.052124,835.854187 457.791595,832.886841 
	C460.220428,831.269226 462.649261,829.651550 465.677185,827.746948 
	C468.152466,825.250183 470.627747,822.753479 473.727173,820.096313 
	C474.505463,819.413757 475.283752,818.731201 476.019318,817.995911 
	C476.019318,817.995911 476.028076,818.063171 476.519958,817.851318 
M916.639709,284.134918 
	C916.639709,284.134918 916.681641,284.088898 917.120911,283.597351 
	C917.079407,282.731873 917.037903,281.866394 917.320862,280.264709 
	C917.476257,277.161346 917.631714,274.057983 917.792908,270.839142 
	C919.270508,270.735931 920.242676,270.653290 921.216553,270.602356 
	C929.017029,270.194519 929.060059,270.196594 929.105774,262.629639 
	C929.155090,254.472931 928.933472,246.310196 929.181702,238.160812 
	C929.319641,233.632965 927.466736,230.946671 922.948853,228.926392 
	C922.127136,228.426712 921.308228,227.500992 920.483276,227.495514 
	C914.509155,227.455826 908.505676,227.277496 902.568054,227.793747 
	C897.960388,228.194382 895.202515,231.998138 895.176270,236.765533 
	C895.142517,242.911118 895.375244,249.067703 895.048767,255.195892 
	C894.958679,256.886475 893.675476,259.050140 892.267212,260.032410 
	C888.189270,262.876648 885.458069,266.022552 885.810242,271.439880 
	C886.160095,276.822601 885.886780,282.245819 885.886780,287.684814 
	C881.001892,287.684814 877.026978,287.684814 873.035950,286.788025 
	C873.066101,271.806244 872.733215,256.810883 873.292969,241.848892 
	C873.540161,235.240707 871.425110,231.096039 864.895813,227.952484 
	C860.754578,227.941147 856.592957,227.669357 852.477783,227.990997 
	C848.271912,228.319702 842.382019,233.846954 842.330994,237.546951 
	C842.122009,252.689362 842.054199,267.835327 842.183472,282.977875 
	C842.194214,284.232422 843.921082,286.346741 845.107727,286.548462 
	C848.327454,287.095856 851.685486,286.829193 855.678162,287.420227 
	C855.678162,292.309418 855.678162,297.198608 855.678162,302.113647 
	C861.375732,302.554718 866.124084,302.922272 870.950256,304.188141 
	C871.028015,311.099762 871.105774,318.011353 870.977783,325.638977 
	C870.884155,326.376617 870.790588,327.114288 870.155396,328.261719 
	C866.907898,333.585327 864.254089,339.651489 856.730591,340.018799 
	C844.674744,340.607300 844.680908,340.563904 844.701904,352.836578 
	C844.716614,361.449371 844.704773,370.062225 844.704773,378.833466 
	C846.479492,378.926117 847.818604,379.193481 849.101501,379.027161 
	C853.478149,378.459564 855.085999,380.182861 854.770020,384.626801 
	C854.454285,389.066345 854.811279,393.553680 854.709229,398.799774 
	C854.052063,405.032074 853.530701,405.218719 846.292908,405.075775 
	C841.415222,404.979431 840.603516,402.346283 840.864868,398.331635 
	C841.052185,395.453461 840.901245,392.553223 840.901245,390.257599 
	C834.959595,389.449829 829.852234,388.755493 824.878357,387.342499 
	C824.783936,386.870789 824.689514,386.399048 824.702332,385.005371 
	C824.659180,368.697632 824.616089,352.389862 824.838257,335.286438 
	C824.826782,334.174042 824.815308,333.061646 825.041748,331.282410 
	C825.656067,330.612823 826.270325,329.943207 827.702087,329.578522 
	C831.117004,329.467682 834.531982,329.356812 838.519836,329.633514 
	C845.324890,329.221924 846.533203,327.835999 847.541504,319.285675 
	C848.658447,309.814697 847.279846,306.744263 840.393982,304.435669 
	C837.067078,304.429596 833.736511,304.320831 830.413879,304.436584 
	C820.213379,304.791931 820.027405,304.766846 816.966370,314.551666 
	C815.808594,318.252472 813.784424,319.659363 810.182007,319.584045 
	C808.689209,319.552856 807.186340,319.880310 805.697144,319.820068 
	C800.658997,319.616302 798.615723,321.724457 798.783142,326.975983 
	C799.110779,337.248932 798.879333,347.539703 798.879333,357.824036 
	C797.639282,358.124298 796.843201,358.352478 796.033081,358.507660 
	C788.828491,359.887787 788.264954,360.520294 788.208801,367.990753 
	C788.155029,375.150818 788.179932,382.311554 788.184082,389.471985 
	C788.190735,400.918610 788.195618,400.918610 799.075806,404.911255 
	C799.687378,408.957306 800.298889,413.003387 801.148315,417.688049 
	C802.945740,419.797729 804.881165,421.808075 806.495361,424.049683 
	C807.711121,425.738159 808.135864,428.120178 809.577576,429.497559 
	C813.144897,432.905731 817.093811,435.914398 821.113220,439.721252 
	C822.510620,441.088959 823.912354,442.452332 825.304626,443.825287 
	C828.031372,446.514282 830.805542,449.074066 836.048767,447.761108 
	C852.512451,447.661743 868.977173,447.628143 885.438477,447.375549 
	C887.382996,447.345734 889.528015,446.457184 891.204651,445.381439 
	C897.477905,441.356354 902.273987,436.296143 900.963684,427.632477 
	C908.290039,427.632477 914.919556,427.740204 921.541016,427.556793 
	C923.198364,427.510895 925.827209,426.972382 926.303772,425.901276 
	C928.600159,420.739929 932.549622,416.005219 931.105774,409.639221 
	C930.651306,407.635498 931.034851,405.441772 931.034851,402.821869 
	C933.438782,402.821869 935.401001,402.847870 937.362366,402.817200 
	C944.531433,402.705048 945.090759,402.195526 945.107910,395.185120 
	C945.145264,379.884155 945.125671,364.583038 945.133301,349.281982 
	C945.136719,342.437653 943.121521,339.964661 935.058472,337.909515 
	C931.408752,337.935791 927.759033,337.953156 924.109375,337.989990 
	C914.155579,338.090485 904.198120,338.070465 894.249939,338.364655 
	C889.958862,338.491547 885.341125,339.668457 885.150452,344.856812 
	C884.736389,356.122864 884.983398,367.416412 885.156433,378.695740 
	C885.173401,379.799133 886.544983,381.810150 887.303894,381.817902 
	C891.204773,381.857544 895.110901,381.379974 899.799927,381.207825 
	C908.252502,378.475525 902.328735,371.023895 904.927002,365.874969 
	C911.375244,365.874969 918.052917,365.874969 924.858154,365.874969 
	C924.858154,373.757599 924.858154,381.143433 924.858154,388.630951 
	C922.432190,388.947571 920.339111,389.220734 917.285461,389.619263 
	C917.285461,393.839111 917.285461,397.949432 916.693909,402.725037 
	C903.208008,402.793365 889.722168,402.889191 876.236267,402.890015 
	C874.790710,402.890076 873.345215,402.336517 871.797241,401.264801 
	C870.602539,397.886078 869.424255,394.501434 868.207703,391.130585 
	C867.347534,388.747314 865.716858,386.397644 865.667603,384.007507 
	C865.418274,371.904205 865.561218,359.792542 865.591614,347.683838 
	C865.613525,338.927277 866.796448,337.478729 876.051453,335.607452 
	C883.410522,333.337372 883.410522,333.337372 883.296753,323.237518 
	C884.671326,322.647156 886.012878,321.665863 887.427124,321.546600 
	C890.506409,321.286865 893.622742,321.466400 897.203674,321.466400 
	C896.352295,314.976471 898.281006,311.804230 904.660645,312.709595 
	C907.903442,313.169891 908.893372,310.989838 908.889893,308.014099 
	C908.881714,301.032104 909.016052,294.049927 909.008057,287.010986 
	C909.008057,287.010986 909.066772,287.094421 910.000732,287.147827 
	C912.154602,285.991699 916.435364,289.604034 916.639709,284.134918 
M540.297180,782.925720 
	C538.598755,784.512451 536.900330,786.099182 534.628662,787.927002 
	C532.980774,790.260254 531.332947,792.593506 529.028442,795.019958 
	C525.636414,797.934082 522.105713,800.703430 518.880615,803.791870 
	C511.621796,810.743103 511.848206,817.687622 519.689148,823.943054 
	C523.456726,826.948792 527.684082,829.378235 531.870544,832.982605 
	C531.870544,837.617798 531.870544,842.252991 531.870544,847.222046 
	C535.446838,847.461487 538.594238,847.493896 541.677368,847.955627 
	C543.164490,848.178345 545.342285,848.859619 545.805786,849.936523 
	C547.463989,853.789124 550.348267,854.124634 553.879700,853.941833 
	C557.696838,853.744263 561.549255,853.660217 565.354797,853.945740 
	C570.505676,854.332214 574.568176,853.225525 577.777710,848.649170 
	C580.129639,845.295654 583.728149,842.843018 586.395508,839.672852 
	C587.766235,838.043762 589.022034,835.755676 589.115540,833.709900 
	C589.555786,824.074768 589.448914,814.415955 589.792297,804.774658 
	C590.002136,798.881226 590.245972,793.142761 584.946106,788.467102 
	C583.069214,785.513000 581.510986,782.278198 579.225647,779.683960 
	C576.951294,777.102417 573.616272,775.449463 571.371338,772.849609 
	C569.704529,770.919250 568.569153,768.221985 568.050659,765.691467 
	C567.490845,762.959412 567.923950,760.023987 567.923950,755.959229 
	C557.619446,761.218628 551.696777,769.013489 544.417358,776.272888 
	C543.277344,778.658020 542.137268,781.043152 541.071716,783.492065 
	C541.071716,783.492065 540.976868,783.462524 540.297180,782.925720 
M152.901398,258.069763 
	C152.919708,251.993607 152.566284,245.887115 153.059326,239.849716 
	C153.567505,233.626999 150.738251,230.358414 144.664581,227.978134 
	C138.538605,227.952499 132.394714,227.627686 126.292114,227.992752 
	C121.097878,228.303482 116.911072,233.361221 116.833115,238.594437 
	C116.731705,245.402939 116.631889,252.212936 116.664185,259.021637 
	C116.710052,268.691437 116.762497,268.686951 126.170250,270.379974 
	C126.446083,270.429565 126.665955,270.790100 127.368065,271.405426 
	C127.368065,276.090820 127.368065,281.178955 127.368065,286.407410 
	C128.581894,286.603088 129.377914,286.864410 130.163513,286.836060 
	C134.615005,286.675385 136.138412,288.628906 135.793762,293.138580 
	C135.391388,298.403564 135.798370,303.730408 135.679596,309.777710 
	C136.804230,310.703094 137.928864,311.628448 139.843750,312.803436 
	C141.217804,312.778168 142.591873,312.752899 144.713074,313.015503 
	C145.931946,315.496124 147.150803,317.976776 148.343475,320.404114 
	C152.054230,320.899475 156.067001,321.435120 159.948959,322.877411 
	C159.971420,324.024475 160.114288,325.184143 159.995636,326.316437 
	C159.463364,331.396240 161.046936,334.366516 166.631058,335.558380 
	C169.856781,336.246887 172.610809,339.145477 175.627274,341.801880 
	C175.756424,342.536560 175.885559,343.271240 175.859604,344.665863 
	C175.904205,345.110840 175.948807,345.555786 175.759201,346.912567 
	C175.748764,359.066956 175.852142,371.223419 175.626892,383.373840 
	C175.584473,385.662415 174.287292,387.946533 173.472488,390.197723 
	C172.157883,393.829712 170.763000,397.432678 168.964493,401.759674 
	C168.421219,402.085236 167.883514,402.684723 167.333847,402.695923 
	C155.752625,402.931183 144.169083,403.212250 132.589188,403.112762 
	C131.398712,403.102539 129.438202,400.869415 129.206558,399.444763 
	C128.654755,396.050964 128.924179,392.523682 129.081284,388.128815 
	C129.081284,380.854218 129.081284,373.579620 129.081284,365.822449 
	C131.566254,365.822449 133.203751,365.845062 134.840469,365.818695 
	C143.768311,365.674988 144.033386,365.674927 143.893936,374.667969 
	C143.814621,379.781952 145.872864,381.640686 150.668121,381.255768 
	C152.441101,381.113434 154.223251,381.085266 156.357742,380.988953 
	C156.357742,368.066589 156.529266,355.599640 156.162247,343.148590 
	C156.110596,341.396423 153.425842,339.721832 151.043106,337.825928 
	C138.921555,337.941437 126.799164,338.003571 114.678841,338.196106 
	C109.050179,338.285522 103.059273,342.665436 103.018730,346.953705 
	C102.857033,364.057587 102.899757,381.163361 102.863625,398.268433 
	C102.857140,401.336609 104.336357,402.805939 107.444504,402.825562 
	C110.219170,402.843079 112.992516,403.070892 116.379829,403.235718 
	C116.379829,408.029968 116.379829,412.468292 116.147537,417.620483 
	C117.869919,424.319031 122.039940,427.730225 128.522369,427.746643 
	C131.686935,427.754669 134.851501,427.765228 138.016068,427.777832 
	C145.353271,427.807129 145.353271,427.807892 146.294006,435.522034 
	C148.386017,437.835419 150.478027,440.148834 152.941696,443.010559 
	C155.162766,444.581787 157.332062,447.410583 159.613953,447.504486 
	C169.039200,447.892548 178.498535,447.451965 188.689377,447.593414 
	C189.464767,447.496796 190.240143,447.400177 191.822464,447.563263 
	C194.884689,447.422485 197.946930,447.281708 201.790512,447.354401 
	C202.857498,447.265778 203.924484,447.177124 205.779572,447.293365 
	C206.855591,447.218109 207.931610,447.142853 209.385727,447.292969 
	C209.385727,447.292969 209.825790,447.301300 210.108932,447.590363 
	C210.450287,447.457184 210.791626,447.324005 211.753647,447.157928 
	C212.160126,447.083374 212.566620,447.008820 213.686020,447.151123 
	C214.584671,446.780640 215.765686,446.657532 216.339569,446.002533 
	C224.693008,436.468109 237.564865,430.844513 242.005325,417.126404 
	C242.921875,415.055359 244.243530,413.059967 244.648056,410.893341 
	C245.109833,408.420074 244.754959,405.794312 244.754959,403.318970 
	C253.989822,402.070618 253.989822,402.070618 254.190155,394.093781 
	C254.035355,383.100525 253.880539,372.107269 253.873276,360.173981 
	C253.905609,351.075928 253.946732,341.977875 253.967377,332.879791 
	C253.983093,325.960815 251.072128,322.454926 243.450806,320.444763 
	C241.614212,320.332916 239.777603,320.221100 237.102890,319.861725 
	C235.608109,319.801086 234.106857,319.621216 232.619766,319.702057 
	C228.056976,319.950134 226.174606,317.734222 225.620682,313.298187 
	C224.399475,303.518250 224.984619,304.139648 214.888443,304.317871 
	C214.057022,304.332550 213.224594,304.415070 212.395020,304.389221 
	C201.812164,304.059143 200.336639,308.661102 200.233490,317.113464 
	C200.094666,328.487823 200.610321,328.978699 211.773758,329.270782 
	C217.294342,329.415222 218.563614,330.723999 218.541077,336.063171 
	C218.475098,351.695892 218.452942,367.328796 218.371719,382.961456 
	C218.361618,384.906921 218.099243,386.851105 217.930344,389.108765 
	C212.510193,389.381561 207.468567,389.635315 202.103134,389.905365 
	C202.103134,393.364136 202.023483,396.339386 202.125366,399.308411 
	C202.228607,402.316650 201.515930,404.962708 198.044159,404.869873 
	C194.667709,404.779602 189.919342,407.065857 189.139740,400.087860 
	C189.204330,393.198395 189.268921,386.308929 190.180801,379.157654 
	C193.390350,379.157654 196.599884,379.157654 200.231216,379.157654 
	C200.231216,368.910736 200.202240,359.657654 200.238617,350.404846 
	C200.283051,339.103912 200.306778,339.104004 188.117569,340.185425 
	C186.613998,339.921875 185.110443,339.658325 183.283798,338.819763 
	C181.304535,336.594086 179.325272,334.368408 177.154556,331.279755 
	C176.833923,321.882904 176.513290,312.486023 177.071411,303.004120 
	C180.831604,302.781708 184.591782,302.559296 188.661911,302.318542 
	C188.925751,297.449951 189.165421,293.027344 190.215073,288.601685 
	C193.117035,287.987885 196.018982,287.374054 199.766800,286.663330 
	C204.636292,287.501068 206.015060,285.305939 205.936096,280.608429 
	C205.704758,266.846008 205.686676,253.072601 205.989670,239.312408 
	C206.105591,234.047501 203.614120,231.444199 198.721649,229.154129 
	C194.123260,228.716843 189.433746,227.503494 184.948517,228.030090 
	C178.728943,228.760330 174.962402,233.848206 174.907333,239.943039 
	C174.778046,254.247787 174.784225,268.554047 174.578812,282.857422 
	C174.553162,284.644287 173.581207,286.417542 172.166306,287.873810 
	C167.976715,287.873810 163.787109,287.873810 159.547516,287.873810 
	C159.276459,285.233337 159.111328,283.624878 159.135025,281.096344 
	C159.435837,273.018219 160.594559,264.690948 152.901398,258.069763 
M544.980347,681.883240 
	C540.058167,681.883240 535.135986,681.883240 529.820251,681.883240 
	C529.820251,687.226318 529.820251,691.657471 529.257324,696.757324 
	C528.866333,697.038269 528.497681,697.525024 528.081116,697.570251 
	C517.362549,698.733521 506.638489,699.845886 495.918182,700.993042 
	C495.466553,701.041321 495.041656,701.339111 494.180420,701.696655 
	C494.180420,706.406738 494.180420,711.183044 494.180420,716.741089 
	C487.809631,716.741089 481.896698,716.741089 475.669098,716.741089 
	C475.174683,723.756653 473.759674,730.018066 476.470551,736.234924 
	C477.888123,744.299866 484.535583,748.159424 490.231140,753.721863 
	C491.469971,754.356689 492.695282,755.509766 493.949829,755.542542 
	C502.277130,755.759949 510.613495,755.629944 518.940918,755.844238 
	C522.416504,755.933655 523.027771,754.320923 523.093628,751.286987 
	C523.142334,749.039856 524.184021,746.814148 525.711670,744.861755 
	C534.166077,745.746826 542.126770,745.163574 548.892639,738.922668 
	C550.997253,736.981567 554.094421,735.986206 556.879639,734.935547 
	C569.122559,730.317444 580.605347,724.563416 590.001709,714.048035 
	C591.761047,713.770386 593.518494,713.270996 595.280151,713.255615 
	C601.234619,713.203674 607.191589,713.414551 613.145630,713.348755 
	C617.958313,713.295593 620.377869,710.935608 620.812134,706.138245 
	C621.017395,703.870972 621.068481,701.589661 621.198181,699.149902 
	C624.897095,698.871277 628.317139,698.613586 632.617371,698.690186 
	C638.859741,699.516541 644.700195,698.353638 649.386353,694.077026 
	C653.778687,690.068420 658.203857,685.919189 661.734131,681.176514 
	C671.202515,668.456543 671.375793,653.925293 668.230164,639.223694 
	C665.191162,625.020386 657.230286,618.904297 642.940247,618.790344 
	C640.778381,618.773071 638.616699,618.734253 636.107605,617.968689 
	C635.744080,616.043213 635.134888,614.127136 635.061096,612.190674 
	C634.789124,605.053101 634.529053,604.217468 627.535339,604.127869 
	C607.213806,603.867554 586.886658,603.976501 566.562988,604.123535 
	C565.399719,604.131897 563.296448,605.735046 563.259216,606.661255 
	C563.113037,610.303650 560.932068,611.116150 558.037781,611.367737 
	C555.390625,611.597839 552.702148,611.576172 550.105347,612.059998 
	C548.929138,612.279114 547.008423,613.433167 546.991821,614.197205 
	C546.779663,623.947632 546.851990,633.704285 546.851990,643.998840 
	C558.407654,643.998840 569.198853,643.998840 580.925537,644.084045 
	C596.839478,644.048340 612.754272,644.093567 628.666626,643.917297 
	C632.422607,643.875793 634.239624,644.968567 633.979858,648.968811 
	C633.754822,652.435974 633.889771,655.927246 633.885925,659.407837 
	C633.882568,662.435547 632.589050,663.924622 629.361450,663.833618 
	C625.896301,663.735840 622.420776,664.006104 618.035828,663.920166 
	C601.237244,664.190002 584.438721,664.459839 568.415466,664.717285 
	C566.675110,670.502502 565.409302,674.710449 563.687988,679.129456 
	C563.474426,679.383972 563.260803,679.638489 562.111633,679.801636 
	C558.620361,679.879517 555.111877,679.776611 551.644470,680.105957 
	C549.693298,680.291382 547.809875,681.190063 544.980347,681.883240 
M386.845123,664.133484 
	C374.562683,664.078979 362.280212,664.024414 349.091736,663.746765 
	C348.759247,663.753540 348.426544,663.771240 348.094269,663.765503 
	C331.620331,663.480774 334.297180,665.849792 333.890594,649.333557 
	C333.786133,645.090576 335.484528,643.873535 339.472015,643.918579 
	C350.608948,644.044373 361.749115,643.879944 373.834503,643.935852 
	C382.651215,643.925354 391.474152,643.725891 400.281067,644.014343 
	C403.086853,644.106262 406.668793,644.659302 408.486237,646.439819 
	C415.123138,652.941833 422.993439,653.509277 431.496429,652.978760 
	C437.130615,652.627197 442.804718,652.932251 448.460724,652.903320 
	C450.211243,652.894348 451.961090,652.753906 454.137848,652.654053 
	C454.137848,647.411987 454.010864,642.763977 454.177460,638.126465 
	C454.314545,634.311157 452.823303,632.781860 448.920349,632.842407 
	C439.607483,632.986816 430.289154,632.738831 420.974457,632.817871 
	C414.899536,632.869324 409.689423,631.311646 405.323822,626.754150 
	C402.841675,624.162903 399.742340,620.566772 396.731506,620.373413 
	C387.203339,619.761475 377.585938,620.539001 367.261475,620.658447 
	C365.308228,619.621094 361.767365,621.175903 361.961334,616.302124 
	C361.989410,613.344788 362.224945,610.367615 361.943909,607.439941 
	C361.830902,606.262634 360.576141,604.222168 359.855560,604.228455 
	C353.567078,604.283569 347.281860,604.713806 340.296173,605.039917 
	C337.033997,608.286316 333.885132,611.659668 330.457703,614.721130 
	C328.940033,616.076782 326.934967,617.449524 325.016785,617.675842 
	C321.142120,618.133118 317.175598,617.812134 312.857880,617.812134 
	C312.826141,625.065002 312.246155,631.175598 305.181427,633.836609 
	C304.650513,634.036560 304.320404,634.823059 303.942688,635.368591 
	C296.226929,646.512085 297.227112,668.216492 305.967865,679.836548 
	C308.320984,682.548950 310.674103,685.261292 313.290558,688.593079 
	C316.016418,690.720886 318.749695,692.839233 321.466278,694.978760 
	C324.922760,697.701172 328.516327,699.956177 334.125427,698.743347 
	C338.744843,698.743347 343.364258,698.743347 348.191650,698.743347 
	C348.445557,700.341736 348.692749,701.616516 348.845123,702.902588 
	C349.791046,710.887329 353.097137,713.847778 361.195740,713.853455 
	C382.771423,713.868591 404.347107,713.865723 426.857910,714.035034 
	C433.672699,714.051086 440.489227,714.170349 447.301819,714.056152 
	C455.174164,713.924316 461.251678,706.483887 459.304535,699.218994 
	C454.616608,698.971802 449.807983,698.958313 445.080078,698.333862 
	C443.504883,698.125793 441.395569,696.668335 440.789185,695.245544 
	C435.315155,682.402466 429.749969,678.945801 415.305054,679.997437 
	C414.738129,679.811951 414.171204,679.626404 413.430786,678.740112 
	C411.245392,675.186768 409.540466,671.192444 406.771149,668.174927 
	C401.547821,662.483459 394.457581,663.801880 386.845123,664.133484 
M841.184448,472.119629 
	C841.109253,478.918671 841.146851,485.722229 840.875854,492.513428 
	C840.811951,494.115051 840.157898,496.245880 839.004395,497.153534 
	C834.526794,500.677063 831.018372,504.517761 832.135315,510.901459 
	C825.612671,511.136932 819.293701,509.864990 813.195862,513.915405 
	C810.867859,513.906494 808.537659,513.960693 806.212341,513.877441 
	C796.778992,513.539673 790.178833,507.077942 790.416260,497.793823 
	C790.530884,493.311096 789.101562,490.337769 784.942322,488.959595 
	C782.026855,487.993591 778.973572,486.903534 775.965027,486.852203 
	C763.011108,486.631104 762.910950,486.958466 762.943298,499.796204 
	C762.952454,503.420135 763.332764,506.925385 767.280518,507.663361 
	C776.444763,509.376495 777.851196,515.404846 776.822266,523.161133 
	C776.757690,523.647705 776.891174,524.156982 776.879150,524.654480 
	C776.774841,528.977905 778.766418,531.104431 783.198608,530.935181 
	C786.300964,530.816711 789.411499,530.912354 792.613586,530.912354 
	C793.264038,535.776367 792.187744,540.238464 797.250549,543.209656 
	C808.790100,549.981812 823.705872,548.657410 833.269836,539.293823 
	C834.312012,538.273499 835.641479,537.546692 837.766479,536.900940 
	C846.757385,538.418152 854.024353,536.177490 858.667053,527.678223 
	C859.681274,525.821594 861.432983,524.370544 862.830078,522.720032 
	C869.144226,515.260376 874.721008,507.422638 874.843201,497.093811 
	C874.943665,488.599670 874.737854,480.100616 874.922852,471.609314 
	C875.006226,467.781860 873.335205,466.500854 869.818909,466.862854 
	C868.501160,466.998535 867.180176,467.103485 864.969910,467.035797 
	C861.006104,467.082733 857.042297,467.129669 852.154846,467.041016 
	C849.702942,467.087006 847.242432,467.282166 844.801270,467.141510 
	C841.683228,466.961853 840.573792,468.229980 841.184448,472.119629 
M208.887939,477.248596 
	C208.901962,474.751740 209.121216,472.230347 208.827316,469.770264 
	C208.712418,468.808411 207.459091,467.226624 206.700287,467.212463 
	C197.261597,467.036469 187.818497,467.097412 177.903839,467.097412 
	C177.903839,471.038635 177.903839,474.481049 177.773590,478.862976 
	C177.779846,485.020020 178.122192,491.200226 177.700317,497.327789 
	C177.255035,503.795380 179.501572,509.055847 183.538986,513.742981 
	C189.073029,520.167603 194.970078,526.296265 200.228043,532.934814 
	C202.828217,536.217712 205.649124,537.048401 209.532745,537.045471 
	C212.353165,537.043335 216.378174,537.190247 217.795532,538.944031 
	C225.665390,548.682251 236.169998,548.078796 246.613693,546.743896 
	C251.407379,546.131226 256.491455,544.199524 260.344879,541.369568 
	C262.210938,539.999084 261.531372,535.175659 262.041870,531.921448 
	C262.083679,531.654663 262.414703,531.433167 262.712006,531.066467 
	C263.263336,531.022156 263.912415,530.935120 264.562927,530.922668 
	C278.153625,530.663391 279.345673,533.958008 278.999237,516.085205 
	C278.907288,511.341797 280.504242,509.929871 285.011200,509.386688 
	C292.696014,508.460510 293.925720,506.921478 294.228668,500.862366 
	C294.903290,487.369690 294.167480,486.626831 280.325195,486.826691 
	C270.985962,486.961548 267.287567,490.725739 267.080627,500.009216 
	C267.031097,502.231445 267.109985,505.700378 265.858337,506.437866 
	C260.273987,509.728149 255.329987,514.812744 247.906723,514.003479 
	C244.963959,513.682678 241.948013,514.032837 238.370697,513.647034 
	C233.621933,509.069824 227.652649,511.501648 222.080658,510.059784 
	C220.891251,507.302612 220.469940,503.275543 218.378143,502.010315 
	C209.781052,496.810425 207.586563,489.360046 208.799927,480.138855 
	C208.884018,479.499939 208.721207,478.828522 208.887939,477.248596 
M724.182007,242.718765 
	C724.188904,308.487976 724.192993,374.257202 724.228088,440.026428 
	C724.228638,441.052307 724.609619,442.078033 724.973572,443.913055 
	C728.915039,440.461273 732.619080,437.849030 735.499573,434.523499 
	C738.593811,430.951202 741.017334,432.058167 743.909546,434.362000 
	C747.311157,437.071533 750.801636,439.669586 755.238525,443.071075 
	C755.238525,438.493958 755.239502,435.363342 755.238403,432.232727 
	C755.223572,390.772308 755.231567,349.311890 755.183411,307.851501 
	C755.160156,287.878387 755.030640,267.905426 755.065979,247.021271 
	C755.977539,239.613037 756.064026,232.655853 746.346069,231.047531 
	C746.187439,231.021301 746.019836,230.947891 745.892456,230.849747 
	C739.189941,225.686478 731.762817,228.564697 724.388916,228.239792 
	C724.388916,233.205521 724.388916,237.490005 724.182007,242.718765 
M702.106140,353.110779 
	C702.062927,350.406403 702.019653,347.701996 702.084290,344.065765 
	C702.084290,309.328522 702.083191,274.591248 702.084900,239.854004 
	C702.085510,227.186722 702.088501,227.186722 688.877197,227.020752 
	C688.877197,222.251007 688.877197,217.481277 688.877197,212.689026 
	C569.477051,212.689026 450.798920,212.689026 331.530792,212.689026 
	C331.530792,217.853455 331.530792,222.609482 331.530792,227.844177 
	C327.482269,227.844177 323.972687,227.523392 320.577209,228.005722 
	C319.261261,228.192642 317.811401,230.012497 317.146515,231.428436 
	C316.568024,232.660385 317.001312,234.371155 317.001038,235.870331 
	C316.981873,344.820953 316.965118,453.771545 316.943695,562.722168 
	C316.942261,569.994568 316.925995,569.987854 324.475403,569.997559 
	C326.639618,570.000366 328.803802,570.054016 331.117859,570.981323 
	C331.318359,575.548828 331.518860,580.116272 331.734955,585.038635 
	C334.868561,585.038635 337.176025,585.038635 339.483459,585.038635 
	C431.214569,585.036560 522.945679,585.034119 614.676758,585.032776 
	C637.484802,585.032471 660.293274,584.964722 683.100464,585.109314 
	C686.821228,585.132874 688.616333,584.153992 688.611389,580.203674 
	C688.606873,576.566284 689.078796,572.928284 690.114990,569.271851 
	C691.262817,569.448975 692.404968,569.693115 693.559448,569.792419 
	C701.454407,570.471191 702.036377,569.991028 702.058167,562.041077 
	C702.118774,539.926208 702.156006,517.811096 702.137024,495.696136 
	C702.103088,456.124756 702.021057,416.553436 702.066833,376.103027 
	C702.032227,373.741119 701.997681,371.379181 702.086731,368.264679 
	C702.047974,367.505341 702.009216,366.746002 702.085144,365.073669 
	C702.052856,361.384399 702.020569,357.695099 702.106140,353.110779 
M750.913208,583.875732 
	C752.096619,584.253906 753.280029,584.632019 754.509094,585.024719 
	C754.828918,584.051514 755.116516,583.583435 755.117310,583.114868 
	C755.145874,566.137207 755.177490,549.159363 755.086304,532.182190 
	C755.080200,531.048218 754.389282,529.629761 753.551270,528.834045 
	C746.922729,522.540222 740.217773,516.325256 733.455444,510.174896 
	C730.873962,507.827057 728.081360,505.711334 724.393860,502.673248 
	C724.248230,505.936371 724.102417,507.699890 724.101074,509.463531 
	C724.086853,527.774841 723.105225,546.157959 724.425415,564.373779 
	C725.405884,577.902161 721.215454,586.375793 708.851074,591.642700 
	C703.336121,593.992065 703.443237,601.322327 708.712463,604.125488 
	C717.078857,608.576355 725.759644,612.444763 734.056763,617.014648 
	C739.625061,620.081665 745.414062,620.182251 751.420532,620.106018 
	C754.161621,620.071228 755.318787,619.160400 755.254944,616.337891 
	C755.127991,610.725159 755.217346,605.107544 755.217346,598.924194 
	C752.759094,598.924194 750.307068,598.924133 747.855042,598.924133 
	C745.403687,598.924133 742.952332,598.924194 740.568237,598.924194 
	C739.311218,595.060364 740.989624,589.841248 734.791138,589.978088 
	C734.546082,589.983582 734.062744,589.191406 734.063110,588.771179 
	C734.066711,584.464050 733.532410,579.989929 734.625122,575.963257 
	C734.909912,574.913940 740.332458,575.248169 743.398315,574.981079 
	C743.697510,574.954956 744.017761,575.170410 744.328186,575.273682 
	C744.122986,575.475769 743.917786,575.677795 743.712524,575.879822 
	C745.891968,578.419617 748.071350,580.959473 750.913208,583.875732 
M516.426270,680.038391 
	C519.553772,679.884277 523.494202,680.884888 525.657837,679.361572 
	C532.406433,674.610291 538.559753,668.989502 544.713867,663.450378 
	C545.410828,662.823120 544.757690,659.279236 544.542480,659.260620 
	C537.770813,658.674194 534.678772,652.904846 530.036194,649.347229 
	C528.459045,648.138611 526.268066,647.113464 524.340271,647.082520 
	C513.860535,646.914124 503.375275,647.070923 492.892181,647.152466 
	C491.743439,647.161377 490.365143,647.040405 489.486145,647.598389 
	C483.647827,651.304993 476.887268,654.277039 472.484802,659.294067 
	C469.121857,663.126465 466.044037,662.907166 462.261810,663.015564 
	C460.013702,663.080017 457.762238,663.027344 455.050751,663.027344 
	C455.050751,667.516296 455.263092,671.169678 454.995514,674.787659 
	C454.680695,679.044312 456.510864,680.106384 460.532166,680.070007 
	C478.836060,679.904236 497.142487,680.019348 516.426270,680.038391 
M819.775269,488.020355 
	C819.775269,480.405396 819.775269,472.790466 819.775269,464.743347 
	C817.515442,464.743347 815.721863,464.778778 813.930054,464.736969 
	C807.663208,464.590790 805.920654,462.836639 805.899536,456.684570 
	C805.888855,453.580902 805.897705,450.477173 805.897705,447.289734 
	C800.614929,447.289734 795.989258,447.220551 791.366638,447.309570 
	C786.917114,447.395203 784.463074,445.846680 784.899109,440.878693 
	C785.210449,437.330627 784.959534,433.733185 784.959534,430.628632 
	C781.668762,429.627655 779.534485,428.978485 777.120728,428.244293 
	C777.120728,440.924347 777.120728,453.486237 777.120728,466.775574 
	C780.264832,466.775574 783.216553,466.811096 786.167114,466.766418 
	C789.680725,466.713226 791.361023,468.233429 791.155945,471.901123 
	C790.975464,475.128876 791.118225,478.374634 791.118225,482.042633 
	C803.239685,479.519318 798.299194,489.601196 801.267578,494.113617 
	C805.487244,494.113617 809.800293,493.970947 814.099243,494.156311 
	C817.919495,494.321014 819.579407,492.518433 819.775269,488.020355 
z"
      />
      <path
        fill="#7ED8F2"
        opacity="1.000000"
        stroke="none"
        d="
M257.881134,884.332764 
	C253.888489,884.329285 249.895844,884.325867 245.431519,883.943359 
	C243.638565,883.378052 242.317307,883.191956 240.528351,883.005981 
	C234.374283,883.000854 228.687897,882.995544 222.959930,882.621460 
	C222.700912,881.645813 222.614365,880.937927 222.239838,880.452209 
	C221.354370,879.303955 220.331909,878.261353 219.364838,877.176025 
	C218.585251,878.466797 217.725082,879.718323 217.066452,881.068115 
	C216.809830,881.594055 217.007263,882.341431 216.598297,882.991699 
	C215.133087,882.993652 214.067459,882.991943 213.001251,882.552917 
	C212.172638,879.160095 215.389313,874.180420 208.559402,874.028931 
	C207.110229,874.014099 206.056320,874.000916 204.961472,873.635254 
	C202.571487,871.511719 201.207825,866.321655 196.531464,871.000000 
	C189.375076,871.000000 182.687241,871.000000 175.996979,870.578491 
	C175.088470,869.109558 174.278320,867.285156 173.258484,867.159241 
	C170.073837,866.766052 166.809937,867.014954 163.307175,867.014954 
	C163.617447,868.798035 163.809036,869.898987 163.754456,871.170227 
	C163.336914,871.901489 163.165512,872.462524 162.607971,873.015747 
	C157.035019,872.326782 158.119156,868.353516 158.390289,864.992432 
	C159.440247,864.444580 160.118790,863.906250 160.797363,863.367920 
	C159.526260,862.914978 158.255142,862.462036 156.765350,861.903564 
	C156.273605,861.602295 156.084564,861.344849 156.151352,860.707153 
	C157.732697,859.601929 159.142258,858.815247 160.551804,858.028564 
	C158.550919,856.972595 156.792007,857.117981 155.064682,856.946106 
	C149.182175,856.360596 149.186417,856.317932 149.529266,861.975037 
	C142.039734,861.653687 135.018478,861.322266 127.790344,860.730896 
	C126.722588,860.315002 125.861717,860.159119 124.855698,859.684875 
	C124.484161,858.244324 124.257774,857.122192 124.434875,856.000000 
	C126.083817,855.765137 128.238388,855.834167 128.434082,855.248535 
	C130.382126,849.419434 135.182663,851.108643 139.167755,851.065857 
	C142.389359,851.031250 144.998901,850.524475 146.435181,847.003418 
	C148.911301,846.993774 150.950409,846.988159 153.427048,846.988770 
	C156.080399,846.994873 158.296234,846.994873 160.612320,846.994873 
	C159.423141,839.207886 160.255676,838.369507 167.016418,839.503357 
	C167.660873,839.611450 168.315598,839.819641 168.953522,839.781372 
	C178.513687,839.206543 188.076508,838.658630 197.624420,837.919434 
	C198.791962,837.829102 199.876709,836.669189 201.399918,836.002258 
	C202.867020,836.000549 203.934021,836.001465 205.075760,836.375122 
	C206.135406,837.099548 207.301666,837.983032 208.076614,837.718872 
	C214.313980,835.592163 222.006836,839.487732 227.191315,833.124390 
	C236.449936,832.912415 245.708252,832.685852 254.967285,832.493774 
	C261.047821,832.367554 267.129852,832.312744 273.210175,832.179382 
	C275.070557,832.138611 277.063446,831.442749 278.762634,831.904602 
	C286.272247,833.945251 286.511292,833.947998 290.818878,827.809570 
	C291.480042,826.867310 292.702240,826.251099 293.168152,825.252319 
	C294.575989,822.234253 295.739319,819.102234 297.466675,816.005249 
	C311.291748,815.998596 324.648102,816.001282 338.042053,816.301514 
	C338.485687,816.916687 338.891693,817.234314 339.297668,817.551941 
	C339.530182,817.036255 339.762665,816.520569 340.460083,815.999146 
	C345.284149,815.997253 349.643311,816.001038 354.018066,816.418152 
	C355.914551,817.554260 357.741150,818.561340 359.689056,818.933472 
	C362.623657,819.494141 365.643066,819.611084 368.626434,819.916626 
	C368.591003,820.735962 368.555573,821.555298 368.520142,822.374634 
	C363.823853,822.719971 359.127594,823.065369 354.639618,823.395386 
	C355.551331,825.947388 356.275665,827.974915 356.756714,830.008057 
	C356.513397,830.013611 356.026672,830.017090 355.683167,829.982910 
	C352.562286,830.942566 349.784912,831.936462 346.538971,832.953857 
	C338.906128,832.988342 331.741882,833.007202 324.577606,833.008240 
	C314.024078,833.009705 313.808105,833.010437 314.113525,843.752808 
	C314.249634,848.541565 312.798859,850.359802 307.914093,850.021057 
	C299.966797,849.469910 291.989899,849.310791 284.022400,849.105347 
	C281.395782,849.037598 279.103943,849.492859 278.908508,853.063904 
	C278.710052,856.689026 280.593567,857.119019 283.510620,857.090820 
	C293.455811,856.994690 303.402527,857.054016 314.053406,857.054016 
	C314.053406,861.741943 314.053406,865.856689 313.780579,870.184204 
	C314.698364,872.218262 315.592255,875.129333 317.162415,875.556885 
	C318.759369,875.991882 321.022705,873.980103 323.411560,873.020142 
	C325.221344,873.012451 326.613708,873.024353 328.092957,873.417480 
	C331.265686,875.245789 334.340271,876.562134 337.462402,873.016357 
	C343.286591,873.013550 348.643127,873.028748 354.060852,873.386963 
	C355.616852,874.677307 357.090881,875.659424 358.610382,876.565369 
	C363.048523,879.211304 368.364563,880.256775 373.021667,881.488892 
	C381.154480,883.640625 390.112396,882.847351 398.728729,882.957153 
	C411.633423,883.121704 424.542999,883.113037 437.448303,882.975159 
	C460.378479,882.730286 483.306244,882.232117 506.236694,882.060181 
	C513.349182,882.006836 520.465210,882.900024 527.582703,882.954285 
	C545.641602,883.091980 563.702271,883.000000 581.762207,883.000000 
	C604.900452,883.000000 628.040100,882.866150 651.175598,883.117004 
	C655.615784,883.165161 660.039551,884.725342 664.470825,885.587952 
	C664.420471,886.054321 664.370056,886.520691 664.319641,886.987061 
	C662.403625,886.987061 660.487610,886.985962 658.571594,886.987183 
	C651.166565,886.992004 643.756897,886.868347 636.361572,887.138245 
	C635.214661,887.180054 634.133484,889.023804 632.718384,890.322571 
	C624.610779,890.738525 616.806580,890.866089 609.000061,890.594360 
	C607.881409,888.830627 606.664795,886.236816 605.666626,886.318359 
	C601.715820,886.640930 597.192688,885.264954 593.860718,889.342041 
	C592.465088,890.096741 591.232544,890.548401 589.910889,890.609131 
	C586.088745,888.737976 582.359253,887.306519 578.643005,890.998779 
	C576.190674,890.998413 574.095337,890.999207 572.064575,890.650513 
	C573.233704,889.537048 574.338257,888.773132 576.191406,887.491577 
	C570.552734,886.943298 565.708435,886.147583 560.851990,886.064941 
	C549.275879,885.868042 537.671936,885.606384 526.127014,886.240234 
	C522.342346,886.448059 518.704834,889.333862 514.532410,890.997681 
	C508.710571,890.996155 503.356293,890.991943 497.959106,890.611572 
	C496.351959,888.825684 494.806152,886.213989 493.220764,886.189819 
	C474.653198,885.905884 456.079590,885.984375 437.507843,886.043762 
	C435.018524,886.051697 432.794464,886.775269 432.849487,890.277222 
	C432.116882,890.677124 431.558716,890.837341 430.922180,890.623413 
	C424.368469,888.832886 417.904480,886.272766 411.415833,886.208679 
	C389.597687,885.993225 367.769745,886.615479 345.947876,887.038025 
	C340.831360,887.137146 335.709442,887.570496 330.615753,888.098511 
	C321.775879,889.014893 312.969727,890.487305 304.194336,887.409851 
	C303.513550,887.171143 302.080841,889.076538 300.607300,889.989136 
	C297.364990,890.219055 295.792328,889.071411 295.974457,885.668884 
	C295.528992,882.205200 293.936035,882.579041 291.626953,883.986206 
	C290.500702,883.976318 289.749908,883.963196 288.696289,883.665588 
	C283.258453,883.256592 278.123474,883.131958 272.524048,883.007507 
	C267.333435,883.449341 262.607269,883.891052 257.881134,884.332764 
M352.720367,882.899658 
	C353.759186,881.965637 354.797974,881.031616 356.362732,879.624756 
	C347.792328,878.375366 338.357391,879.838684 336.215607,882.982727 
	C341.704956,882.982727 346.780457,882.982727 352.720367,882.899658 
M359.234192,879.531494 
	C358.846924,879.404236 358.464355,879.256042 358.068481,879.167480 
	C357.990448,879.150024 357.861115,879.361816 357.755432,879.467834 
	C358.142609,879.594910 358.529816,879.721924 359.234192,879.531494 
z"
      />
      <path
        fill="#7ED7F1"
        opacity="1.000000"
        stroke="none"
        d="
M770.028015,814.149902 
	C770.767761,814.112305 771.507446,814.074646 772.665466,814.394165 
	C773.723328,815.168335 774.362854,815.585449 775.003052,816.389526 
	C775.999634,818.313843 776.995605,819.851257 778.150085,821.633301 
	C777.611633,821.922791 776.562805,822.330200 775.707031,822.988525 
	C774.766541,823.712158 773.975037,824.643372 773.224731,825.570129 
	C773.171204,825.636353 774.098389,826.842346 774.378662,826.770752 
	C775.381042,826.514709 776.286926,825.903198 777.256104,825.488586 
	C778.490479,824.960693 779.750854,824.493408 780.845581,824.353821 
	C780.659485,826.274292 780.627747,827.840881 780.596069,829.407532 
	C782.139771,829.237976 783.737854,829.268555 785.218140,828.865906 
	C793.754822,826.543701 802.331421,826.058655 810.707458,828.292725 
	C805.776855,828.846436 801.139832,829.105042 795.910217,829.396667 
	C799.775146,833.101440 830.383179,832.831543 835.138184,829.000000 
	C831.575989,829.000000 828.833740,829.121887 826.106567,828.971313 
	C822.065063,828.748108 818.034241,828.331665 814.294495,827.702881 
	C820.050964,827.318787 825.517395,827.349243 830.970581,827.096069 
	C834.334717,826.939880 837.678650,826.351379 841.474487,825.960571 
	C843.905029,825.921021 845.925903,826.094177 847.874084,825.794495 
	C853.860718,824.873291 853.861450,824.812622 853.658569,831.006348 
	C852.692810,831.508545 852.065979,832.006775 851.439148,832.505005 
	C852.294922,832.665894 853.150757,832.826843 854.318176,832.992798 
	C855.044006,832.996765 855.458191,832.995728 855.872375,832.994751 
	C855.872375,832.994751 855.995728,833.427734 856.117126,833.968628 
	C856.492310,835.339661 856.746155,836.169800 856.812622,837.270752 
	C853.972168,839.717224 855.822449,840.847534 857.791199,840.994690 
	C864.354004,841.485413 870.943848,841.604126 877.521179,841.912231 
	C884.682007,842.247620 891.840515,842.635132 899.000793,843.375793 
	C899.667725,844.835205 900.333862,845.918823 900.995361,847.369202 
	C900.680176,850.138916 899.503113,852.256226 897.409912,849.610535 
	C895.219788,846.842285 892.719055,847.175415 889.944092,846.945435 
	C886.189514,846.634155 882.474976,845.584351 878.728333,845.498230 
	C874.012207,845.389771 869.283142,846.087097 864.555725,846.153503 
	C860.007568,846.217468 855.438477,846.076965 850.919556,845.620728 
	C850.205017,845.548645 849.552917,843.381958 849.159241,842.095703 
	C848.865906,841.137390 849.036194,840.037048 849.036255,838.640869 
	C850.587402,834.813416 848.228210,834.156311 845.743591,834.061462 
	C841.255371,833.890076 836.746155,834.198608 832.263611,833.964050 
	C818.222656,833.229614 803.861938,837.894958 790.106079,831.462830 
	C789.143555,831.012756 787.343506,832.353882 784.510376,833.374512 
	C788.235229,834.744385 791.600647,834.953552 792.329102,836.448181 
	C794.301392,840.494324 797.414856,840.035828 800.706726,840.006714 
	C804.035828,839.977295 807.368164,839.914246 810.693909,840.023865 
	C815.042480,840.167175 815.908142,842.002563 813.601074,845.719543 
	C812.313416,847.794067 811.130859,849.939514 810.002441,852.105774 
	C808.285706,855.401550 806.657104,858.743164 805.372070,861.305664 
	C802.235107,861.106079 799.864929,860.955200 797.494751,860.804382 
	C797.341980,861.546448 797.189270,862.288513 797.036560,863.030579 
	C798.691040,863.687073 800.345520,864.343567 801.962341,865.362793 
	C800.842773,870.898193 796.462158,870.899109 792.555298,872.113220 
	C789.122925,873.179932 786.018005,875.300354 782.349487,877.168823 
	C786.069641,879.673767 788.534790,881.333618 790.541931,882.998291 
	C786.723389,883.002808 783.362915,883.002625 779.805908,882.731079 
	C777.677063,881.130188 775.744873,879.800537 774.070679,878.648499 
	C776.348816,872.273560 787.515747,870.861450 781.237244,861.965393 
	C784.523804,858.234497 788.314148,858.676636 792.340210,859.598511 
	C796.791321,860.617615 800.894897,857.411133 799.892456,853.650635 
	C799.924744,852.943848 799.930847,852.539307 799.958496,851.695862 
	C799.911743,844.685547 799.715271,844.558044 793.158875,845.774048 
	C791.710327,846.042664 789.969116,845.532898 788.773682,846.162231 
	C787.294434,846.941040 786.243774,848.533936 784.570862,849.826172 
	C781.090027,849.603577 778.046204,849.329834 774.992554,848.631592 
	C774.654480,845.260864 774.485291,842.284302 773.929260,839.381775 
	C773.661865,837.985840 772.664490,836.729675 771.997681,835.410217 
	C771.341492,836.895325 770.272461,838.338623 770.116577,839.874451 
	C769.808594,842.908936 770.005981,845.994690 769.621094,849.070374 
	C768.500854,849.073730 767.753967,849.065857 766.967163,848.735168 
	C766.303345,847.943726 765.679443,847.475037 765.032776,846.547058 
	C764.961426,842.419617 764.912781,838.751465 765.120483,834.906372 
	C765.687012,832.539612 765.122925,831.632568 762.489746,833.054443 
	C743.029480,833.022034 724.034485,833.013794 704.975037,832.613403 
	C702.924988,831.478699 700.943237,830.101135 698.953430,830.089600 
	C671.054199,829.926941 643.153625,829.988708 615.255005,829.782166 
	C614.194336,829.774292 612.656860,827.761169 612.242065,826.406738 
	C611.860168,825.159485 613.078735,823.497009 612.921387,822.092651 
	C612.369507,817.167297 614.511536,815.889587 619.225281,815.903381 
	C668.484802,816.048096 717.744934,815.998657 767.003235,816.422852 
	C767.002930,817.657959 766.738098,818.604980 767.062317,819.260620 
	C767.693726,820.537415 768.655273,821.650879 769.480103,822.832031 
	C770.299072,821.412170 771.409241,820.066650 771.799988,818.537354 
	C771.966858,817.884338 770.632507,816.847656 769.988342,815.678711 
	C770.006958,814.964722 770.017456,814.557312 770.028015,814.149902 
M789.325928,863.042236 
	C788.360718,862.940674 787.395508,862.839050 786.430298,862.737488 
	C786.496399,863.315491 786.562500,863.893494 786.628662,864.471497 
	C787.626404,864.205811 788.624084,863.940125 789.325928,863.042236 
M783.312988,839.477966 
	C782.975281,840.022949 782.637573,840.567932 782.299927,841.112976 
	C782.573364,841.247314 782.846802,841.381653 783.120239,841.515991 
	C783.324768,840.982239 783.529297,840.448547 783.312988,839.477966 
z"
      />
      <path
        fill="#7BD9F3"
        opacity="1.000000"
        stroke="none"
        d="
M849.000000,839.000000 
	C849.036194,840.037048 848.865906,841.137390 849.159241,842.095703 
	C849.552917,843.381958 850.205017,845.548645 850.919556,845.620728 
	C855.438477,846.076965 860.007568,846.217468 864.555725,846.153503 
	C869.283142,846.087097 874.012207,845.389771 878.728333,845.498230 
	C882.474976,845.584351 886.189514,846.634155 889.944092,846.945435 
	C892.719055,847.175415 895.219788,846.842285 897.409912,849.610535 
	C899.503113,852.256226 900.680176,850.138916 901.290405,847.226318 
	C902.733276,846.137878 903.876404,845.559204 905.315552,845.007385 
	C906.071960,845.363037 906.532349,845.691772 907.034790,846.369385 
	C908.215088,849.063660 909.429260,850.399536 911.329468,847.004639 
	C912.101013,846.988464 912.541992,846.980957 912.989136,847.392822 
	C912.996094,849.207275 912.996826,850.602417 912.605469,851.999878 
	C911.210144,852.507263 910.206726,853.012329 909.203308,853.517456 
	C909.744019,853.980774 910.284790,854.444214 910.825562,854.907593 
	C911.551208,853.939209 912.276855,852.970825 913.415100,851.948303 
	C916.742676,851.596069 919.673828,850.950745 922.568665,851.085449 
	C926.408081,851.264160 928.506287,849.829224 929.390137,846.038696 
	C932.185242,847.020874 934.596252,848.006775 937.004150,849.422058 
	C937.004761,851.568604 937.008484,853.285828 937.006104,855.001465 
	C937.000000,855.000000 937.003235,855.012207 936.615601,855.007690 
	C934.270630,855.293091 932.313293,855.583008 930.356018,855.872925 
	C932.405762,856.581848 934.454529,857.293762 936.507507,857.993225 
	C936.652283,858.042542 936.828613,857.999451 936.943726,858.385986 
	C935.602417,859.516785 934.323059,860.871887 933.010498,860.904907 
	C925.011963,861.106018 917.005981,861.006653 908.797363,860.757568 
	C906.777466,861.469849 904.962708,862.432068 903.147949,863.394287 
	C904.082397,864.266541 905.016785,865.138733 906.183350,866.019409 
	C906.415466,866.027954 906.880066,866.028931 906.880066,866.028931 
	C906.724548,867.753418 906.569031,869.477844 906.067505,871.588867 
	C905.481201,871.987732 905.240845,872.000000 904.602295,871.999756 
	C901.785522,872.203369 898.845825,868.721741 896.601746,873.007751 
	C894.138123,873.334717 892.072693,873.656372 890.017395,873.593811 
	C890.733521,872.482483 891.359314,871.645691 892.165588,871.055481 
	C893.237183,870.271057 894.449524,869.678833 895.601440,869.004028 
	C894.279480,868.336853 892.952209,867.095764 891.636597,867.108154 
	C887.355652,867.148376 883.063660,868.034302 878.804871,867.865234 
	C874.117981,867.679199 872.334778,869.463013 872.549316,873.979004 
	C869.739685,873.979736 867.373413,873.974976 864.940063,873.615723 
	C862.700012,872.259460 860.582397,871.090393 858.325500,870.341309 
	C857.471313,870.057739 855.543152,870.514893 855.388733,871.024048 
	C854.397888,874.289917 853.763977,877.664124 852.968018,881.328735 
	C852.264160,882.511353 851.605042,883.367981 850.946045,884.224609 
	C850.227661,884.228333 849.509338,884.231995 848.161133,883.888977 
	C845.744812,883.363708 843.958618,883.034607 842.171631,883.030090 
	C825.114502,882.986511 808.057251,882.997925 791.000000,882.993530 
	C788.534790,881.333618 786.069641,879.673767 782.349487,877.168823 
	C786.018005,875.300354 789.122925,873.179932 792.555298,872.113220 
	C796.462158,870.899109 800.842773,870.898193 802.386169,865.362793 
	C810.122681,864.879456 810.471802,864.559509 811.017944,857.684631 
	C811.082886,856.866821 811.105896,855.979004 811.441345,855.260376 
	C813.180664,851.534180 815.686768,848.015442 816.634705,844.111023 
	C817.660095,839.887390 819.780579,838.882202 823.622192,838.952148 
	C832.078918,839.106201 840.540466,839.000000 849.000000,839.000000 
z"
      />
      <path
        fill="#97D7E3"
        opacity="1.000000"
        stroke="none"
        d="
M590.000000,891.000000 
	C591.232544,890.548401 592.465088,890.096741 594.302856,889.335327 
	C599.606140,889.681580 604.304321,890.337646 609.002441,890.993652 
	C616.806580,890.866089 624.610779,890.738525 633.165771,890.317139 
	C638.024658,890.350708 642.132141,890.953064 646.240967,890.961914 
	C680.104126,891.034424 713.967590,891.011353 747.830872,890.950378 
	C749.554443,890.947266 751.276978,890.330017 753.424622,890.000244 
	C757.733215,890.562439 760.838440,889.346985 763.317261,885.834473 
	C765.993042,884.600098 767.987427,880.623596 771.010193,884.743896 
	C769.202209,885.426880 766.539734,885.580139 765.731934,886.883179 
	C761.910889,893.046875 755.938965,892.854248 750.015686,892.859497 
	C702.211853,892.902100 654.408081,892.900757 606.604248,892.897156 
	C505.500000,892.889648 404.395721,892.872437 303.291473,892.860474 
	C298.666962,892.859985 294.033844,892.887329 293.244629,886.447876 
	C294.390350,886.037720 295.181702,886.039307 295.973083,886.040894 
	C295.792328,889.071411 297.364990,890.219055 301.036469,889.995117 
	C304.806671,890.328369 307.756927,890.942627 310.708191,890.947449 
	C350.805573,891.013550 390.903107,890.995117 431.000580,890.997559 
	C431.558716,890.837341 432.116882,890.677124 433.296570,890.271118 
	C455.279419,890.346191 476.640747,890.666992 498.002045,890.987793 
	C503.356293,890.991943 508.710571,890.996155 515.001099,891.001709 
	C534.624878,891.002014 553.312439,891.001038 572.000000,891.000000 
	C574.095337,890.999207 576.190674,890.998413 579.101074,891.001587 
	C583.277405,891.003662 586.638733,891.001831 590.000000,891.000000 
z"
      />
      <path
        fill="#97D7E3"
        opacity="1.000000"
        stroke="none"
        d="
M338.004456,816.004028 
	C324.648102,816.001282 311.291748,815.998596 297.157959,816.158447 
	C294.740082,820.820984 291.174103,823.234497 286.526306,824.984741 
	C261.995575,824.987793 237.932861,824.973633 213.870270,825.017029 
	C211.759537,825.020813 208.809082,824.049072 209.016739,828.425598 
	C208.943878,831.947327 209.897400,835.613892 205.001038,836.002441 
	C203.934021,836.001465 202.867020,836.000549 201.106033,835.704895 
	C188.043106,835.270020 175.666367,835.336182 163.309753,834.869141 
	C159.391327,834.721130 157.881012,836.505554 158.132111,839.571533 
	C158.477310,843.786560 156.077545,845.475342 152.989517,846.982544 
	C150.950409,846.988159 148.911301,846.993774 146.194672,846.838379 
	C145.337143,846.137268 145.157150,845.597046 144.977173,845.056885 
	C154.803986,844.638855 154.803986,844.638855 156.519592,833.262878 
	C158.340805,833.178955 160.277954,833.010010 162.214783,833.013428 
	C175.369171,833.036987 188.526352,832.958191 201.676163,833.227600 
	C205.731628,833.310669 207.278900,832.222290 207.135986,827.968201 
	C206.932480,821.911072 207.418854,821.888794 213.416489,822.583862 
	C217.199265,823.022278 221.054367,822.910461 224.877243,822.916626 
	C243.360138,822.946411 261.846771,822.729614 280.323730,823.063354 
	C285.559143,823.157837 289.477722,821.836304 291.993744,817.374084 
	C293.647797,814.440430 295.937653,813.897766 299.020477,813.908020 
	C324.329865,813.992432 349.639709,813.947144 375.409698,813.920898 
	C375.925323,818.560608 376.108063,823.225159 375.945465,827.877625 
	C375.905334,829.026245 374.742493,830.135742 373.907166,830.661377 
	C373.812561,829.703979 373.906586,829.348450 374.216003,828.886780 
	C374.701416,828.584778 374.881714,828.326416 374.981018,827.549316 
	C374.989960,824.654663 375.036652,822.215088 374.977631,819.777954 
	C374.915924,817.228943 374.158508,815.309998 370.520508,815.988953 
	C364.703766,815.995056 359.353119,816.000000 354.002441,816.004883 
	C349.643311,816.001038 345.284149,815.997253 340.128357,815.993652 
	C338.889252,815.997192 338.446869,816.000610 338.004456,816.004028 
z"
      />
      <path
        fill="#97D7E3"
        opacity="1.000000"
        stroke="none"
        d="
M769.560303,814.084351 
	C770.017456,814.557312 770.006958,814.964722 769.616821,815.679932 
	C768.493103,815.992859 767.748962,815.997986 767.004883,816.003052 
	C717.744934,815.998657 668.484802,816.048096 619.225281,815.903381 
	C614.511536,815.889587 612.369507,817.167297 612.921387,822.092651 
	C613.078735,823.497009 611.860168,825.159485 612.242065,826.406738 
	C612.656860,827.761169 614.194336,829.774292 615.255005,829.782166 
	C643.153625,829.988708 671.054199,829.926941 698.953430,830.089600 
	C700.943237,830.101135 702.924988,831.478699 704.684570,832.617737 
	C704.074463,832.986572 703.690430,832.958801 703.306335,832.931030 
	C698.130920,832.556152 692.955505,832.181335 687.091797,831.551880 
	C686.031799,831.298035 685.660095,831.298767 685.288330,831.299561 
	C660.725769,831.299561 636.163269,831.299561 610.922302,831.299561 
	C610.922302,825.793884 610.922302,820.373108 611.002930,814.495605 
	C663.753235,814.032166 716.422913,814.025452 769.560303,814.084351 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M771.435913,884.816772 
	C767.987427,880.623596 765.993042,884.600098 763.321045,885.464111 
	C763.643311,884.181641 764.068176,882.890625 764.941711,882.087891 
	C770.059143,877.384949 770.095093,876.240723 764.931763,871.411682 
	C764.961304,868.127991 765.161072,865.286316 764.933044,862.479431 
	C764.654846,859.055359 765.818665,857.736328 769.390991,857.871094 
	C776.032288,858.121643 782.700989,858.209717 789.334656,857.884827 
	C793.112000,857.699829 797.427063,858.091797 799.866333,853.952820 
	C800.894897,857.411133 796.791321,860.617615 792.340210,859.598511 
	C788.314148,858.676636 784.523804,858.234497 781.237244,861.965393 
	C787.515747,870.861450 776.348816,872.273560 774.070679,878.648499 
	C775.744873,879.800537 777.677063,881.130188 779.801392,883.033569 
	C779.982849,884.009216 779.972168,884.411072 779.961487,884.812927 
	C779.522888,884.840942 779.084229,884.868958 778.226562,884.722839 
	C777.481934,884.353027 777.220154,884.432495 777.022156,884.787109 
	C775.301941,884.821350 773.581787,884.855530 771.435913,884.816772 
z"
      />
      <path
        fill="#97D7E3"
        opacity="1.000000"
        stroke="none"
        d="
M155.979553,861.025635 
	C156.084564,861.344849 156.273605,861.602295 156.796631,862.222046 
	C157.370682,863.431335 157.694794,864.216675 158.018906,865.002014 
	C158.119156,868.353516 157.035019,872.326782 162.907806,873.305176 
	C175.394638,873.727661 187.195480,873.852783 199.425278,873.984253 
	C201.570282,873.989624 203.286331,873.988708 205.002396,873.987793 
	C206.056320,874.000916 207.110229,874.014099 208.570190,874.463623 
	C209.827332,877.840942 206.647263,882.787781 213.001831,882.990234 
	C214.067459,882.991943 215.133087,882.993652 217.027054,882.996887 
	C219.570770,882.995728 221.286133,882.992981 223.001511,882.990234 
	C228.687897,882.995544 234.374283,883.000854 240.533203,883.310669 
	C241.014297,884.020081 241.022858,884.425049 241.031418,884.829956 
	C231.207031,884.896301 221.381393,884.892883 211.558990,885.069397 
	C208.400848,885.126160 206.738861,883.996704 207.048309,880.752808 
	C207.447617,876.567139 205.213806,875.760132 201.585526,875.803772 
	C188.261398,875.964172 174.931839,875.769409 161.610809,876.034241 
	C157.208359,876.121704 155.718277,874.646790 155.855927,870.290039 
	C156.060776,863.806519 155.230026,863.067261 148.782562,863.152893 
	C141.806351,863.245483 134.829803,863.537231 127.861244,863.894836 
	C123.858215,864.100281 122.139633,862.502197 122.024506,858.410278 
	C121.640709,844.769287 121.554077,844.771729 136.246918,845.276611 
	C132.944016,846.398499 129.463455,847.195007 125.723267,848.199158 
	C125.300934,849.266602 125.138206,850.126404 124.703140,851.242065 
	C124.297661,852.998718 124.164528,854.499329 124.031387,856.000000 
	C124.257774,857.122192 124.484161,858.244324 125.059631,859.948486 
	C126.271553,860.683899 127.134392,860.837402 127.997231,860.990906 
	C135.018478,861.322266 142.039734,861.653687 149.913239,861.981201 
	C152.503510,861.660156 154.241531,861.342896 155.979553,861.025635 
z"
      />
      <path
        fill="#97D7E3"
        opacity="1.000000"
        stroke="none"
        d="
M775.002441,816.002563 
	C774.362854,815.585449 773.723328,815.168335 773.032959,814.448242 
	C777.977966,812.686829 780.881531,815.967041 783.423279,819.215515 
	C785.609985,822.010132 788.054810,822.994446 791.509277,822.970276 
	C809.296326,822.845581 827.084656,822.906372 844.872498,822.901855 
	C845.371216,822.901733 845.871216,822.918518 846.368408,822.889160 
	C856.065369,822.316162 856.065308,822.315002 855.928345,832.544556 
	C855.458191,832.995728 855.044006,832.996765 854.317871,832.662048 
	C854.003113,831.885010 854.000305,831.443726 853.997559,831.002441 
	C853.861450,824.812622 853.860718,824.873291 847.874084,825.794495 
	C845.925903,826.094177 843.905029,825.921021 841.169067,825.673767 
	C838.614929,825.251282 836.809387,825.008179 835.003296,825.004456 
	C820.057312,824.973206 805.111145,825.018250 790.165527,824.942383 
	C788.437561,824.933655 786.712585,824.327026 784.836731,823.693848 
	C781.458923,820.927673 778.230652,818.465149 775.002441,816.002563 
z"
      />
      <path
        fill="#97D7E3"
        opacity="1.000000"
        stroke="none"
        d="
M865.007141,873.970215 
	C867.373413,873.974976 869.739685,873.979736 873.017700,873.988647 
	C879.288757,873.987854 884.648071,873.982971 890.007324,873.978088 
	C892.072693,873.656372 894.138123,873.334717 897.010742,873.007935 
	C900.045959,871.467041 903.161682,876.368591 905.000549,872.012207 
	C905.240845,872.000000 905.481201,871.987732 906.085693,871.963562 
	C907.705017,875.920166 905.054749,875.943542 902.405701,875.940063 
	C888.969971,875.922424 875.531860,876.030518 862.099792,875.798401 
	C857.767944,875.723572 855.393982,876.709412 855.961609,881.561951 
	C856.365784,885.017639 854.256592,885.396484 851.243286,884.456177 
	C851.605042,883.367981 852.264160,882.511353 853.238892,881.100586 
	C856.530334,872.263367 857.062317,871.957886 865.007141,873.970215 
z"
      />
      <path
        fill="#97D7E3"
        opacity="1.000000"
        stroke="none"
        d="
M912.982910,846.973511 
	C912.541992,846.980957 912.101013,846.988464 910.979248,846.997070 
	C909.196533,846.672302 908.094666,846.346375 906.992798,846.020447 
	C906.532349,845.691772 906.071960,845.363037 905.311646,844.551697 
	C905.009949,842.912292 904.849060,841.729431 905.034058,840.603271 
	C905.812012,835.868469 903.643005,834.469849 899.223022,834.949890 
	C895.504700,835.353638 891.740051,835.500488 887.997803,835.476379 
	C881.612488,835.435303 875.228027,835.058350 868.844788,835.095154 
	C867.557190,835.102539 866.276611,836.322937 864.578369,836.984009 
	C862.785095,836.982788 861.406128,836.981628 860.015991,836.616638 
	C859.282166,835.459045 858.559570,834.665283 857.836975,833.871582 
	C859.109497,833.614746 860.380615,833.142395 861.654785,833.134033 
	C875.627991,833.041992 889.601685,833.015930 903.575256,832.997131 
	C904.537292,832.995789 905.499573,833.195740 905.848328,833.234314 
	C909.601562,836.755554 902.464417,845.131714 912.591736,845.364014 
	C913.000732,846.140869 912.991821,846.557190 912.982910,846.973511 
z"
      />
      <path
        fill="#97D7E3"
        opacity="1.000000"
        stroke="none"
        d="
M780.429443,884.885376 
	C779.972168,884.411072 779.982849,884.009216 779.997986,883.304932 
	C783.362915,883.002625 786.723389,883.002808 790.541931,882.998291 
	C808.057251,882.997925 825.114502,882.986511 842.171631,883.030090 
	C843.958618,883.034607 845.744812,883.363708 847.801941,883.885620 
	C846.638428,884.488220 845.204651,884.970581 843.770142,884.972839 
	C822.812622,885.005554 801.854980,884.974060 780.429443,884.885376 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M125.982864,847.991455 
	C129.463455,847.195007 132.944016,846.398499 136.712234,845.322266 
	C139.365265,845.022827 141.730667,845.003052 144.536621,845.020081 
	C145.157150,845.597046 145.337143,846.137268 145.757660,846.842407 
	C144.998901,850.524475 142.389359,851.031250 139.167755,851.065857 
	C135.182663,851.108643 130.382126,849.419434 128.434082,855.248535 
	C128.238388,855.834167 126.083817,855.765137 124.434868,856.000000 
	C124.164528,854.499329 124.297661,852.998718 125.093536,851.246033 
	C126.763069,850.449707 127.769867,849.905334 128.776642,849.361023 
	C127.845383,848.904541 126.914124,848.447998 125.982864,847.991455 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M912.989136,847.392822 
	C912.991821,846.557190 913.000732,846.140869 913.014038,845.413208 
	C915.383850,845.060425 917.749268,845.019104 920.840088,845.286987 
	C924.045715,845.745056 926.525940,845.893738 929.006165,846.042419 
	C928.506287,849.829224 926.408081,851.264160 922.568665,851.085449 
	C919.673828,850.950745 916.742676,851.596069 913.413818,851.947083 
	C913.000000,852.000000 912.997559,851.997559 912.997559,851.997559 
	C912.996826,850.602417 912.996094,849.207275 912.989136,847.392822 
z"
      />
      <path
        fill="#97D7E3"
        opacity="1.000000"
        stroke="none"
        d="
M907.076904,865.697388 
	C906.880066,866.028931 906.415466,866.027954 906.202271,865.662231 
	C906.993591,863.866821 907.997986,862.437134 909.002441,861.007446 
	C917.005981,861.006653 925.011963,861.106018 933.010498,860.904907 
	C934.323059,860.871887 935.602417,859.516785 936.949585,858.012573 
	C937.002380,856.505676 937.002808,855.758972 937.003235,855.012207 
	C937.003235,855.012207 937.000000,855.000000 937.319641,854.997864 
	C938.056702,854.989746 938.474060,854.983765 938.891479,854.977783 
	C938.938049,856.130371 938.959717,857.284607 939.035828,858.435242 
	C939.284912,862.199524 938.668945,863.983582 933.810730,863.391296 
	C926.821411,862.539124 919.650330,862.989868 912.567810,863.221130 
	C910.781677,863.279358 909.037231,864.616394 907.076904,865.697388 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M272.988495,883.007324 
	C278.123474,883.131958 283.258453,883.256592 288.694336,883.889832 
	C288.995270,884.398499 289.016266,884.845276 289.016266,884.845276 
	C283.995178,884.871338 278.974091,884.897400 273.485596,884.568481 
	C273.008270,883.811462 272.998383,883.409424 272.988495,883.007324 
z"
      />
      <path
        fill="#97D7E3"
        opacity="1.000000"
        stroke="none"
        d="
M272.524048,883.007507 
	C272.998383,883.409424 273.008270,883.811462 273.023682,884.512695 
	C268.214203,884.798462 263.399139,884.785034 258.232605,884.552185 
	C262.607269,883.891052 267.333435,883.449341 272.524048,883.007507 
z"
      />
      <path
        fill="#97D7E3"
        opacity="1.000000"
        stroke="none"
        d="
M929.390137,846.038696 
	C926.525940,845.893738 924.045715,845.745056 921.283081,845.316711 
	C925.935486,844.989075 930.871582,844.993469 935.804626,844.871948 
	C938.419128,844.807556 939.499451,845.831055 938.286011,848.746277 
	C937.490967,849.030579 937.249146,849.011658 937.007324,848.992676 
	C934.596252,848.006775 932.185242,847.020874 929.390137,846.038696 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M295.974457,885.668945 
	C295.181702,886.039307 294.390350,886.037720 293.201050,886.076416 
	C292.803162,886.116577 292.392761,886.020264 292.202911,885.629761 
	C292.009521,884.820496 292.005981,884.401672 292.002441,883.982910 
	C293.936035,882.579041 295.528992,882.205200 295.974457,885.668945 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M241.383743,884.884644 
	C241.022858,884.425049 241.014297,884.020081 241.000885,883.310486 
	C242.317307,883.191956 243.638565,883.378052 245.021423,883.937378 
	C243.967377,884.520142 242.851730,884.729736 241.383743,884.884644 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M857.537109,833.862427 
	C858.559570,834.665283 859.282166,835.459045 859.637085,836.623962 
	C858.512878,836.996704 857.756470,836.998352 857.000000,837.000000 
	C856.746155,836.169800 856.492310,835.339661 856.137329,834.192749 
	C856.436462,833.868530 856.836914,833.860962 857.537109,833.862427 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M937.004150,849.422058 
	C937.249146,849.011658 937.490967,849.030579 938.097900,849.088928 
	C938.642639,850.828735 938.822449,852.529114 938.946838,854.603638 
	C938.474060,854.983765 938.056702,854.989746 937.325745,854.999390 
	C937.008484,853.285828 937.004761,851.568604 937.004150,849.422058 
z"
      />
      <path
        fill="#97D7E3"
        opacity="1.000000"
        stroke="none"
        d="
M291.626953,883.986206 
	C292.005981,884.401672 292.009521,884.820496 292.007538,885.550659 
	C291.140137,885.703674 290.278259,885.545288 289.216309,885.116089 
	C289.016266,884.845276 288.995270,884.398499 288.997192,884.174255 
	C289.749908,883.963196 290.500702,883.976318 291.626953,883.986206 
z"
      />
      <path
        fill="#97D7E3"
        opacity="1.000000"
        stroke="none"
        d="
M777.262329,884.844482 
	C777.220154,884.432495 777.481934,884.353027 777.898560,884.684570 
	C777.989563,884.820374 777.502502,884.901855 777.262329,884.844482 
z"
      />
      <path
        fill="#FAFBFD"
        opacity="1.000000"
        stroke="none"
        d="
M330.967987,570.084351 
	C328.803802,570.054016 326.639618,570.000366 324.475403,569.997559 
	C316.925995,569.987854 316.942261,569.994568 316.943695,562.722168 
	C316.965118,453.771545 316.981873,344.820953 317.001038,235.870331 
	C317.001312,234.371155 316.568024,232.660385 317.146515,231.428436 
	C317.811401,230.012497 319.261261,228.192642 320.577209,228.005722 
	C323.972687,227.523392 327.482269,227.844177 331.530792,227.844177 
	C331.530792,222.609482 331.530792,217.853455 331.530792,212.689026 
	C450.798920,212.689026 569.477051,212.689026 688.877197,212.689026 
	C688.877197,217.481277 688.877197,222.251007 688.825317,227.922028 
	C688.550049,234.255310 688.326721,239.687302 687.627869,245.102005 
	C682.987305,245.079422 678.813660,244.907394 674.659851,245.120972 
	C671.263916,245.295593 669.826538,244.190079 670.025208,240.621109 
	C670.270935,236.208130 670.083984,231.771088 670.083984,227.258759 
	C564.013062,227.258759 458.814148,227.258759 352.834839,227.258759 
	C352.834839,233.319778 352.834839,239.023712 352.834839,245.276489 
	C345.136597,245.276489 338.213898,245.276489 330.865570,245.276489 
	C330.865570,248.000732 330.864838,250.140137 330.865692,252.279541 
	C330.890198,313.588043 330.923431,374.896545 330.936371,436.205048 
	C330.944214,473.190125 330.889160,510.175293 330.998169,547.160034 
	C331.003998,549.134521 332.273163,551.105347 332.971619,553.519409 
	C333.319519,558.955750 333.649994,563.950562 333.661621,569.014404 
	C332.551239,569.417053 331.759613,569.750732 330.967987,570.084351 
z"
      />
      <path
        fill="#53637D"
        opacity="1.000000"
        stroke="none"
        d="
M701.959106,376.982086 
	C702.021057,416.553436 702.103088,456.124756 702.137024,495.696136 
	C702.156006,517.811096 702.118774,539.926208 702.058167,562.041077 
	C702.036377,569.991028 701.454407,570.471191 693.559448,569.792419 
	C692.404968,569.693115 691.262817,569.448975 689.598938,569.169189 
	C689.082886,569.066467 688.827881,568.840271 688.831909,568.387207 
	C688.598206,562.919617 688.360413,557.905029 688.155457,552.449707 
	C689.456665,517.483948 688.996643,482.974884 688.488831,447.811005 
	C682.968933,448.278290 678.751526,448.635345 673.946594,449.042114 
	C673.946594,453.131897 674.036438,456.765991 673.896301,460.391144 
	C673.849060,461.614716 673.507202,463.643524 672.806396,463.887726 
	C669.252563,465.126038 669.972778,467.917206 669.919250,470.488678 
	C669.590149,486.294647 669.263855,502.100708 668.474670,517.947083 
	C663.440308,517.987427 658.867920,517.987427 653.817383,517.987427 
	C653.817383,523.038147 654.003235,527.166870 653.723999,531.263855 
	C653.615417,532.856323 652.470093,535.710327 651.774780,535.717102 
	C646.777649,535.766113 641.774780,535.230530 636.794739,534.522766 
	C636.723633,533.458679 636.631592,532.752258 636.649048,531.584961 
	C636.720032,504.148651 636.681396,477.173248 636.740967,449.722046 
	C636.810547,437.469666 636.781860,425.693115 636.824341,413.460419 
	C636.926514,407.928467 636.957458,402.852661 636.919861,397.592163 
	C636.902527,397.085266 637.090576,396.938782 637.819092,397.104156 
	C638.504761,397.271362 638.786865,397.302429 639.442505,397.486664 
	C644.132446,397.693878 648.448853,397.747955 653.243286,397.863220 
	C655.552002,397.945404 657.382874,397.985260 659.213684,397.984192 
	C665.958801,397.980225 665.982483,397.975098 665.980835,391.462555 
	C665.976318,373.982513 665.949707,356.502472 665.954529,338.581390 
	C665.998047,336.358276 666.018860,334.576202 666.368896,332.909363 
	C673.783875,339.008423 680.869507,344.992310 689.009399,351.866547 
	C689.009399,345.919464 689.009399,341.459747 689.397949,337.088562 
	C690.758545,342.326141 691.730652,347.475220 692.929321,353.824219 
	C698.423096,349.058044 694.533813,341.593414 699.537354,336.745056 
	C699.744507,338.933411 699.664246,340.359314 700.062805,341.635712 
	C700.438293,342.838257 701.318298,343.883301 701.976379,344.997620 
	C702.019653,347.701996 702.062927,350.406403 701.757568,353.847412 
	C701.596191,358.384888 701.783325,362.185791 701.970459,365.986664 
	C702.009216,366.746002 702.047974,367.505341 701.749268,368.910583 
	C701.594238,372.031677 701.776672,374.506866 701.959106,376.982086 
z"
      />
      <path
        fill="#F9FAFB"
        opacity="1.000000"
        stroke="none"
        d="
M116.379829,416.906586 
	C116.379829,412.468292 116.379829,408.029968 116.379829,403.235718 
	C112.992516,403.070892 110.219170,402.843079 107.444504,402.825562 
	C104.336357,402.805939 102.857140,401.336609 102.863625,398.268433 
	C102.899757,381.163361 102.857033,364.057587 103.018730,346.953705 
	C103.059273,342.665436 109.050179,338.285522 114.678841,338.196106 
	C126.799164,338.003571 138.921555,337.941437 151.375580,338.240173 
	C150.593552,340.850342 149.302353,342.976624 148.403610,345.257538 
	C146.831100,349.248291 144.432739,351.336151 139.781464,351.062286 
	C133.824829,350.711517 127.826279,351.144012 121.851105,351.007141 
	C118.791122,350.937042 117.805870,352.112061 117.862350,355.101318 
	C118.019196,363.402924 117.939621,371.708984 117.950966,380.013306 
	C117.954918,382.906555 117.951607,385.799805 117.951607,389.050293 
	C122.241875,389.050293 125.551880,389.050293 128.861877,389.050293 
	C128.924179,392.523682 128.654755,396.050964 129.206558,399.444763 
	C129.438202,400.869415 131.398712,403.102539 132.589188,403.112762 
	C144.169083,403.212250 155.752625,402.931183 167.333847,402.695923 
	C167.883514,402.684723 168.421219,402.085236 169.581802,401.414734 
	C176.450455,401.049988 182.701797,401.030182 188.953140,401.010376 
	C189.919342,407.065857 194.667709,404.779602 198.044159,404.869873 
	C201.515930,404.962708 202.228607,402.316650 202.125366,399.308411 
	C202.023483,396.339386 202.103134,393.364136 202.103134,389.905365 
	C207.468567,389.635315 212.510193,389.381561 217.930344,389.108765 
	C218.099243,386.851105 218.361618,384.906921 218.371719,382.961456 
	C218.452942,367.328796 218.475098,351.695892 218.541077,336.063171 
	C218.563614,330.723999 217.294342,329.415222 211.773758,329.270782 
	C200.610321,328.978699 200.094666,328.487823 200.233490,317.113464 
	C200.336639,308.661102 201.812164,304.059143 212.395020,304.389221 
	C213.224594,304.415070 214.057022,304.332550 214.888443,304.317871 
	C224.984619,304.139648 224.399475,303.518250 225.620682,313.298187 
	C226.174606,317.734222 228.056976,319.950134 232.619766,319.702057 
	C234.106857,319.621216 235.608109,319.801086 237.512787,320.447174 
	C237.922699,333.255035 237.922699,345.477417 237.922699,357.834534 
	C240.473129,357.972046 242.119171,358.060822 244.012939,358.449005 
	C246.489120,359.509521 248.717529,360.270660 250.891205,361.495422 
	C250.854584,371.957092 250.872711,381.955078 250.642929,392.172913 
	C244.942337,396.886627 239.992340,402.328796 233.876129,405.570892 
	C229.081726,408.112274 225.960968,411.133881 223.595703,415.857056 
	C222.853561,416.279541 222.387421,416.611176 221.484222,416.958862 
	C213.206100,417.070312 213.567383,417.104462 212.865112,424.531952 
	C212.591080,427.430145 210.284241,430.136108 208.599457,433.070953 
	C204.758469,435.442200 201.342484,439.322571 197.661438,439.592834 
	C186.587311,440.405853 175.409897,439.812408 164.164459,439.445343 
	C163.444321,438.343109 162.991516,437.291687 162.195831,436.825531 
	C154.990677,432.604584 151.961182,426.349823 152.764542,418.114624 
	C152.787155,417.882843 152.396027,417.610718 151.845108,416.906586 
	C140.255798,416.906586 128.317810,416.906586 116.379829,416.906586 
z"
      />
      <path
        fill="#A3B0BE"
        opacity="1.000000"
        stroke="none"
        d="
M631.737183,698.355957 
	C628.317139,698.613586 624.897095,698.871277 621.198181,699.149902 
	C621.068481,701.589661 621.017395,703.870972 620.812134,706.138245 
	C620.377869,710.935608 617.958313,713.295593 613.145630,713.348755 
	C607.191589,713.414551 601.234619,713.203674 595.280151,713.255615 
	C593.518494,713.270996 591.761047,713.770386 589.227417,713.941772 
	C582.018921,713.591064 575.584717,713.206970 569.150208,713.200012 
	C567.531921,713.198303 565.718323,714.127991 564.325073,715.102356 
	C556.055115,720.885620 547.811829,726.712646 539.702759,732.718323 
	C534.600281,736.497192 529.747925,740.613831 524.783386,744.578918 
	C524.184021,746.814148 523.142334,749.039856 523.093628,751.286987 
	C523.027771,754.320923 522.416504,755.933655 518.940918,755.844238 
	C510.613495,755.629944 502.277130,755.759949 493.949829,755.542542 
	C492.695282,755.509766 491.469971,754.356689 490.390503,753.185547 
	C489.359558,749.985596 488.471863,747.124207 486.894989,744.713501 
	C485.277344,742.240417 482.985809,740.208191 481.088013,737.660278 
	C482.075714,736.349426 483.189331,735.485229 483.814209,734.351624 
	C486.402100,729.656677 490.342377,728.254150 495.483185,728.792358 
	C498.606232,729.119324 501.797302,728.852905 504.955688,728.771301 
	C508.443451,728.681213 510.497101,727.437927 510.067749,723.330627 
	C509.694763,719.762695 509.990173,716.124817 509.990173,712.222900 
	C512.216003,712.107300 513.835632,711.957642 515.455994,711.949890 
	C524.104797,711.908630 532.759155,712.053467 541.397949,711.748596 
	C542.991089,711.692444 545.666748,710.056580 545.872864,708.821106 
	C546.575073,704.611694 546.396790,700.255310 547.003418,695.878662 
	C551.213196,695.571350 554.973877,695.169067 558.735291,695.162415 
	C561.896484,695.156860 563.241455,693.876343 563.138733,690.742004 
	C563.020325,687.128540 563.070374,683.509644 563.047241,679.893066 
	C563.260803,679.638489 563.474426,679.383972 564.324768,679.027771 
	C569.546753,678.987305 574.131836,679.090515 578.717163,679.101440 
	C590.134766,679.128479 601.560547,678.864319 612.966431,679.231506 
	C617.917786,679.390869 619.380676,677.681274 618.892151,673.039673 
	C618.582703,670.099670 618.908752,667.092712 618.949829,664.115784 
	C622.420776,664.006104 625.896301,663.735840 629.361450,663.833618 
	C632.589050,663.924622 633.882568,662.435547 633.885925,659.407837 
	C633.889771,655.927246 633.754822,652.435974 633.979858,648.968811 
	C634.239624,644.968567 632.422607,643.875793 628.666626,643.917297 
	C612.754272,644.093567 596.839478,644.048340 580.445984,643.690552 
	C586.137146,636.352356 592.192261,629.303284 603.036621,630.912292 
	C605.622253,631.295959 608.326599,630.879761 611.443787,630.890503 
	C621.382568,630.983765 630.853943,631.010193 640.325195,631.054443 
	C651.232056,631.105408 660.465759,640.585754 661.109253,651.541382 
	C661.814331,663.545410 657.394348,673.232727 649.112366,681.463074 
	C643.382935,687.156738 637.533203,692.729248 631.737183,698.355957 
z"
      />
      <path
        fill="#FAFBFC"
        opacity="1.000000"
        stroke="none"
        d="
M826.884644,329.273621 
	C826.270325,329.943207 825.656067,330.612823 824.564880,331.676270 
	C823.059937,334.010620 821.169556,335.938293 821.140381,337.893677 
	C820.909729,353.358856 821.086975,368.829590 820.999817,384.297852 
	C820.983948,387.115540 821.980042,388.312347 824.744873,388.061157 
	C829.852234,388.755493 834.959595,389.449829 840.901245,390.257599 
	C840.901245,392.553223 841.052185,395.453461 840.864868,398.331635 
	C840.603516,402.346283 841.415222,404.979431 846.292908,405.075775 
	C853.530701,405.218719 854.052063,405.032074 855.172424,398.420044 
	C861.056946,399.373444 866.478333,400.706604 871.899658,402.039764 
	C873.345215,402.336517 874.790710,402.890076 876.236267,402.890015 
	C889.722168,402.889191 903.208008,402.793365 917.339600,402.339600 
	C919.893921,401.026855 921.802490,400.099487 923.711060,399.172119 
	C924.162109,400.980072 924.672241,402.776215 925.036987,404.601440 
	C925.196716,405.401031 925.063538,406.260010 925.055969,407.091980 
	C924.968506,416.741608 924.859680,416.878723 915.305115,416.925110 
	C907.041382,416.965271 898.777161,416.933472 889.969238,416.933472 
	C889.969238,420.771332 890.065308,423.896515 889.946777,427.013519 
	C889.768188,431.707794 883.240662,439.742615 878.931274,439.748871 
	C866.984680,439.766235 855.037964,439.277222 843.090088,439.057739 
	C839.672913,438.994965 836.251343,439.168732 832.403687,439.174805 
	C829.389282,438.965912 826.803101,438.816284 824.137451,438.227936 
	C823.944275,431.833923 823.694702,425.876709 823.776123,419.924072 
	C823.814880,417.092743 822.795715,415.967072 820.027283,416.007599 
	C816.031067,416.066193 812.032593,415.969421 808.014160,415.564819 
	C806.566162,410.199036 805.254761,405.114349 799.002502,403.998932 
	C788.195618,400.918610 788.190735,400.918610 788.184082,389.471985 
	C788.179932,382.311554 788.155029,375.150818 788.208801,367.990753 
	C788.264954,360.520294 788.828491,359.887787 796.033081,358.507660 
	C796.843201,358.352478 797.639282,358.124298 798.879333,357.824036 
	C798.879333,347.539703 799.110779,337.248932 798.783142,326.975983 
	C798.615723,321.724457 800.658997,319.616302 805.697144,319.820068 
	C807.186340,319.880310 808.689209,319.552856 810.182007,319.584045 
	C813.784424,319.659363 815.808594,318.252472 816.966370,314.551666 
	C820.027405,304.766846 820.213379,304.791931 830.413879,304.436584 
	C833.736511,304.320831 837.067078,304.429596 840.743652,305.125305 
	C840.314148,310.165039 839.534973,314.515076 838.411133,318.893738 
	C834.339172,322.372803 830.611938,325.823212 826.884644,329.273621 
z"
      />
      <path
        fill="#505E78"
        opacity="1.000000"
        stroke="none"
        d="
M754.949219,247.932388 
	C755.030640,267.905426 755.160156,287.878387 755.183411,307.851501 
	C755.231567,349.311890 755.223572,390.772308 755.238403,432.232727 
	C755.239502,435.363342 755.238525,438.493958 755.238525,443.071075 
	C750.801636,439.669586 747.311157,437.071533 743.909546,434.362000 
	C741.017334,432.058167 738.593811,430.951202 735.499573,434.523499 
	C732.619080,437.849030 728.915039,440.461273 724.973572,443.913055 
	C724.609619,442.078033 724.228638,441.052307 724.228088,440.026428 
	C724.192993,374.257202 724.188904,308.487976 724.720093,242.295929 
	C728.411499,241.844147 731.716309,242.417709 734.689148,241.670700 
	C742.865112,239.616302 749.242615,242.469955 754.949219,247.932388 
z"
      />
      <path
        fill="#A2AEBE"
        opacity="1.000000"
        stroke="none"
        d="
M361.614136,617.199585 
	C361.767365,621.175903 365.308228,619.621094 367.810242,621.030518 
	C371.903534,622.882690 373.181641,625.570923 373.017761,629.343445 
	C372.808350,634.165100 372.916656,639.000549 372.888031,643.830017 
	C361.749115,643.879944 350.608948,644.044373 339.472015,643.918579 
	C335.484528,643.873535 333.786133,645.090576 333.890594,649.333557 
	C334.297180,665.849792 331.620331,663.480774 348.094269,663.765503 
	C348.426544,663.771240 348.759247,663.753540 349.558533,664.213440 
	C353.082886,668.739014 355.965332,672.950684 359.296509,676.770935 
	C360.407135,678.044617 362.654846,678.908997 364.403168,678.944153 
	C372.160248,679.100464 379.924713,678.887451 388.008026,679.119507 
	C396.754425,679.434265 405.179352,679.437622 413.604248,679.440918 
	C414.171204,679.626404 414.738129,679.811951 415.585693,680.563232 
	C415.897003,682.120300 415.941650,683.111328 415.956055,684.102844 
	C416.075500,692.338623 416.115448,692.236023 423.691559,695.310669 
	C424.792358,695.757446 425.876495,697.702026 425.935577,699.000488 
	C426.160706,703.946533 425.962677,708.911865 425.922791,713.869995 
	C404.347107,713.865723 382.771423,713.868591 361.195740,713.853455 
	C353.097137,713.847778 349.791046,710.887329 348.845123,702.902588 
	C348.692749,701.616516 348.445557,700.341736 348.191650,698.743347 
	C343.364258,698.743347 338.744843,698.743347 333.598572,698.365662 
	C327.118866,693.191406 322.319397,686.086243 313.027222,687.973694 
	C310.674103,685.261292 308.320984,682.548950 306.054504,679.160767 
	C308.404053,675.128662 312.384583,671.882019 312.607483,668.395203 
	C313.273102,657.983154 312.620941,647.470032 312.151031,637.011292 
	C311.983032,633.272400 313.089691,631.820618 316.845459,631.978516 
	C321.430511,632.171265 326.029785,632.024414 331.042877,632.024414 
	C331.042877,628.979919 331.058929,626.841370 331.040070,624.703003 
	C330.964325,616.126282 330.962738,616.228760 339.852051,616.020081 
	C343.598755,615.932190 347.332916,615.309998 351.464233,614.905945 
	C355.108490,615.653992 358.361328,616.426819 361.614136,617.199585 
z"
      />
      <path
        fill="#55647E"
        opacity="1.000000"
        stroke="none"
        d="
M331.042908,570.532837 
	C331.759613,569.750732 332.551239,569.417053 334.121216,569.121094 
	C340.598450,569.123901 346.297363,569.088928 352.321106,569.305542 
	C354.301819,569.648621 355.957642,569.818970 357.613586,569.820190 
	C405.605103,569.855408 453.596954,569.997864 501.588043,569.862488 
	C557.724487,569.704041 613.860291,569.301025 670.432251,569.053101 
	C676.854736,569.014771 682.841309,568.927551 688.827881,568.840271 
	C688.827881,568.840271 689.082886,569.066467 689.211914,569.178528 
	C689.078796,572.928284 688.606873,576.566284 688.611389,580.203674 
	C688.616333,584.153992 686.821228,585.132874 683.100464,585.109314 
	C660.293274,584.964722 637.484802,585.032471 614.676758,585.032776 
	C522.945679,585.034119 431.214569,585.036560 339.483459,585.038635 
	C337.176025,585.038635 334.868561,585.038635 331.734955,585.038635 
	C331.518860,580.116272 331.318359,575.548828 331.042908,570.532837 
z"
      />
      <path
        fill="#A2AFBE"
        opacity="1.000000"
        stroke="none"
        d="
M238.965393,514.077515 
	C241.948013,514.032837 244.963959,513.682678 247.906723,514.003479 
	C255.329987,514.812744 260.273987,509.728149 265.858337,506.437866 
	C267.109985,505.700378 267.031097,502.231445 267.080627,500.009216 
	C267.287567,490.725739 270.985962,486.961548 280.325195,486.826691 
	C294.167480,486.626831 294.903290,487.369690 294.228668,500.862366 
	C293.925720,506.921478 292.696014,508.460510 285.011200,509.386688 
	C280.504242,509.929871 278.907288,511.341797 278.999237,516.085205 
	C279.345673,533.958008 278.153625,530.663391 264.562927,530.922668 
	C263.912415,530.935120 263.263336,531.022156 262.712006,531.066467 
	C262.414703,531.433167 262.083679,531.654663 262.041870,531.921448 
	C261.531372,535.175659 262.210938,539.999084 260.344879,541.369568 
	C256.491455,544.199524 251.407379,546.131226 246.613693,546.743896 
	C236.169998,548.078796 225.665390,548.682251 217.795532,538.944031 
	C216.378174,537.190247 212.353165,537.043335 209.532745,537.045471 
	C205.649124,537.048401 202.828217,536.217712 200.228043,532.934814 
	C194.970078,526.296265 189.073029,520.167603 183.538986,513.742981 
	C179.501572,509.055847 177.255035,503.795380 177.700317,497.327789 
	C178.122192,491.200226 177.779846,485.020020 178.312744,478.349915 
	C188.792343,477.948639 198.732773,478.060425 208.673203,478.172241 
	C208.721207,478.828522 208.884018,479.499939 208.799927,480.138855 
	C207.586563,489.360046 209.781052,496.810425 218.378143,502.010315 
	C220.469940,503.275543 220.891251,507.302612 221.788300,510.552917 
	C221.517151,512.589844 220.948715,515.074646 221.658600,515.519531 
	C225.323364,517.816162 229.152512,520.158142 233.254135,521.335571 
	C235.751572,522.052551 238.845139,520.693115 241.670929,520.266357 
	C240.769073,518.203369 239.867233,516.140503 238.965393,514.077515 
z"
      />
      <path
        fill="#525E77"
        opacity="1.000000"
        stroke="none"
        d="
M750.582031,583.687500 
	C748.071350,580.959473 745.891968,578.419617 743.712524,575.879822 
	C743.917786,575.677795 744.122986,575.475769 744.328186,575.273682 
	C744.017761,575.170410 743.697510,574.954956 743.398315,574.981079 
	C740.332458,575.248169 734.909912,574.913940 734.625122,575.963257 
	C733.532410,579.989929 734.066711,584.464050 734.063110,588.771179 
	C734.062744,589.191406 734.546082,589.983582 734.791138,589.978088 
	C740.989624,589.841248 739.311218,595.060364 740.568237,598.924194 
	C742.952332,598.924194 745.403687,598.924133 747.855042,598.924133 
	C750.307068,598.924133 752.759094,598.924194 755.217346,598.924194 
	C755.217346,605.107544 755.127991,610.725159 755.254944,616.337891 
	C755.318787,619.160400 754.161621,620.071228 751.420532,620.106018 
	C745.414062,620.182251 739.625061,620.081665 734.056763,617.014648 
	C725.759644,612.444763 717.078857,608.576355 708.712463,604.125488 
	C703.443237,601.322327 703.336121,593.992065 708.851074,591.642700 
	C721.215454,586.375793 725.405884,577.902161 724.425415,564.373779 
	C723.105225,546.157959 724.086853,527.774841 724.101074,509.463531 
	C724.102417,507.699890 724.248230,505.936371 724.393860,502.673248 
	C728.081360,505.711334 730.873962,507.827057 733.455444,510.174896 
	C740.217773,516.325256 746.922729,522.540222 753.551270,528.834045 
	C754.389282,529.629761 755.080200,531.048218 755.086304,532.182190 
	C755.177490,549.159363 755.145874,566.137207 755.117310,583.114868 
	C755.116516,583.583435 754.828918,584.051514 754.509094,585.024719 
	C753.280029,584.632019 752.096619,584.253906 750.582031,583.687500 
z"
      />
      <path
        fill="#A3B0BE"
        opacity="1.000000"
        stroke="none"
        d="
M853.078491,467.176636 
	C857.042297,467.129669 861.006104,467.082733 865.408325,467.581909 
	C865.926636,473.782410 865.931396,479.439056 866.100891,485.090759 
	C866.574524,500.877869 859.402100,513.544800 849.510803,524.920715 
	C845.750610,529.245300 841.088684,532.785828 836.837708,536.683716 
	C835.641479,537.546692 834.312012,538.273499 833.269836,539.293823 
	C823.705872,548.657410 808.790100,549.981812 797.250549,543.209656 
	C792.187744,540.238464 793.264038,535.776367 792.613586,530.912354 
	C789.411499,530.912354 786.300964,530.816711 783.198608,530.935181 
	C778.766418,531.104431 776.774841,528.977905 776.879150,524.654480 
	C776.891174,524.156982 776.757690,523.647705 776.822266,523.161133 
	C777.851196,515.404846 776.444763,509.376495 767.280518,507.663361 
	C763.332764,506.925385 762.952454,503.420135 762.943298,499.796204 
	C762.910950,486.958466 763.011108,486.631104 775.965027,486.852203 
	C778.973572,486.903534 782.026855,487.993591 784.942322,488.959595 
	C789.101562,490.337769 790.530884,493.311096 790.416260,497.793823 
	C790.178833,507.077942 796.778992,513.539673 806.212341,513.877441 
	C808.537659,513.960693 810.867859,513.906494 813.629822,514.408569 
	C813.069458,516.685913 812.075195,518.469971 810.726135,520.890564 
	C820.196899,520.832214 829.390808,523.577332 835.399231,514.232117 
	C835.811646,513.590576 836.829285,513.371765 837.397400,512.791870 
	C846.406982,503.594513 856.885620,495.133026 853.974854,479.908539 
	C853.541504,477.642365 853.317017,475.336273 852.961792,472.628510 
	C852.977905,470.531128 853.028198,468.853882 853.078491,467.176636 
z"
      />
      <path
        fill="#A8C4D6"
        opacity="1.000000"
        stroke="none"
        d="
M832.831787,439.234039 
	C836.251343,439.168732 839.672913,438.994965 843.090088,439.057739 
	C855.037964,439.277222 866.984680,439.766235 878.931274,439.748871 
	C883.240662,439.742615 889.768188,431.707794 889.946777,427.013519 
	C890.065308,423.896515 889.969238,420.771332 889.969238,416.933472 
	C898.777161,416.933472 907.041382,416.965271 915.305115,416.925110 
	C924.859680,416.878723 924.968506,416.741608 925.055969,407.091980 
	C925.063538,406.260010 925.196716,405.401031 925.036987,404.601440 
	C924.672241,402.776215 924.162109,400.980072 923.711060,399.172119 
	C921.802490,400.099487 919.893921,401.026855 917.635376,402.006958 
	C917.285461,397.949432 917.285461,393.839111 917.285461,389.619263 
	C920.339111,389.220734 922.432190,388.947571 924.858154,388.630951 
	C924.858154,381.143433 924.858154,373.757599 924.858154,365.874969 
	C918.052917,365.874969 911.375244,365.874969 904.927002,365.874969 
	C902.328735,371.023895 908.252502,378.475525 899.432007,380.671692 
	C899.064026,370.610962 899.064026,361.086365 899.064026,351.013550 
	C906.873352,351.013550 914.962646,351.028778 923.051819,351.009705 
	C933.241211,350.985626 936.088501,348.124390 935.981201,338.075562 
	C943.121521,339.964661 945.136719,342.437653 945.133301,349.281982 
	C945.125671,364.583038 945.145264,379.884155 945.107910,395.185120 
	C945.090759,402.195526 944.531433,402.705048 937.362366,402.817200 
	C935.401001,402.847870 933.438782,402.821869 931.034851,402.821869 
	C931.034851,405.441772 930.651306,407.635498 931.105774,409.639221 
	C932.549622,416.005219 928.600159,420.739929 926.303772,425.901276 
	C925.827209,426.972382 923.198364,427.510895 921.541016,427.556793 
	C914.919556,427.740204 908.290039,427.632477 900.963684,427.632477 
	C902.273987,436.296143 897.477905,441.356354 891.204651,445.381439 
	C889.528015,446.457184 887.382996,447.345734 885.438477,447.375549 
	C868.977173,447.628143 852.512451,447.661743 835.574707,447.340576 
	C834.344299,444.358063 833.588013,441.796051 832.831787,439.234039 
z"
      />
      <path
        fill="#669FCE"
        opacity="1.000000"
        stroke="none"
        d="
M799.892456,853.650635 
	C797.427063,858.091797 793.112000,857.699829 789.334656,857.884827 
	C782.700989,858.209717 776.032288,858.121643 769.390991,857.871094 
	C765.818665,857.736328 764.654846,859.055359 764.933044,862.479431 
	C765.161072,865.286316 764.961304,868.127991 764.519043,871.447632 
	C760.389771,872.303284 756.692749,872.665527 752.552490,873.007812 
	C749.742065,873.009033 747.374878,873.030090 744.539917,873.030640 
	C738.712341,873.025085 733.352478,873.040100 727.525269,873.031250 
	C713.700684,873.042603 700.343323,873.077759 686.596130,873.072021 
	C685.691528,872.943848 685.176758,872.856628 684.662048,872.769409 
	C684.662048,867.149841 684.662048,861.530334 684.662048,855.335938 
	C697.654541,855.335938 710.206970,855.335938 723.215515,855.335938 
	C723.215515,851.373657 723.215515,847.994202 723.215515,844.109985 
	C716.677979,844.109985 710.447876,844.109985 703.300537,844.109985 
	C703.300537,840.353821 703.300537,837.089355 703.303467,833.377991 
	C703.690430,832.958801 704.074463,832.986572 704.749023,833.010010 
	C724.034485,833.013794 743.029480,833.022034 762.613037,833.309204 
	C763.755859,834.086548 764.309998,834.584961 764.864136,835.083374 
	C764.912781,838.751465 764.961426,842.419617 765.047485,846.876404 
	C765.725708,848.129395 766.366394,848.593689 767.007080,849.057983 
	C767.753967,849.065857 768.500854,849.073730 770.038574,849.077393 
	C772.220459,849.067566 773.611450,849.061829 775.002441,849.056030 
	C778.046204,849.329834 781.090027,849.603577 785.031006,849.888733 
	C787.906311,849.914246 789.930847,849.665771 791.851440,850.005310 
	C794.586670,850.488708 797.245544,851.403870 799.937012,852.134644 
	C799.930847,852.539307 799.924744,852.943848 799.892456,853.650635 
z"
      />
      <path
        fill="#505F79"
        opacity="1.000000"
        stroke="none"
        d="
M610.976318,630.831421 
	C608.326599,630.879761 605.622253,631.295959 603.036621,630.912292 
	C592.192261,629.303284 586.137146,636.352356 579.978271,643.647949 
	C569.198853,643.998840 558.407654,643.998840 546.851990,643.998840 
	C546.851990,633.704285 546.779663,623.947632 546.991821,614.197205 
	C547.008423,613.433167 548.929138,612.279114 550.105347,612.059998 
	C552.702148,611.576172 555.390625,611.597839 558.037781,611.367737 
	C560.932068,611.116150 563.113037,610.303650 563.259216,606.661255 
	C563.296448,605.735046 565.399719,604.131897 566.562988,604.123535 
	C586.886658,603.976501 607.213806,603.867554 627.535339,604.127869 
	C634.529053,604.217468 634.789124,605.053101 635.061096,612.190674 
	C635.134888,614.127136 635.744080,616.043213 635.776489,618.432739 
	C629.020752,618.933044 622.595764,619.029358 616.171936,618.978210 
	C613.276489,618.955078 612.018250,620.116577 611.947083,623.074890 
	C611.884766,625.666687 611.319458,628.246399 610.976318,630.831421 
z"
      />
      <path
        fill="#F7FAFB"
        opacity="1.000000"
        stroke="none"
        d="
M871.183594,324.922974 
	C871.105774,318.011353 871.028015,311.099762 870.946899,303.304749 
	C871.658813,297.697968 872.374146,292.974518 873.185303,288.102539 
	C873.281189,287.954041 873.052002,287.684814 873.052002,287.684814 
	C877.026978,287.684814 881.001892,287.684814 885.886780,287.684814 
	C885.886780,282.245819 886.160095,276.822601 885.810242,271.439880 
	C885.458069,266.022552 888.189270,262.876648 892.267212,260.032410 
	C893.675476,259.050140 894.958679,256.886475 895.048767,255.195892 
	C895.375244,249.067703 895.142517,242.911118 895.176270,236.765533 
	C895.202515,231.998138 897.960388,228.194382 902.568054,227.793747 
	C908.505676,227.277496 914.509155,227.455826 920.483276,227.495514 
	C921.308228,227.500992 922.127136,228.426712 923.067871,229.738007 
	C923.192322,240.678833 923.203918,250.808044 923.201172,260.937256 
	C923.199341,267.751129 921.842407,269.249420 914.574341,269.997345 
	C912.541992,270.067841 910.950256,270.180420 909.066772,270.313629 
	C909.066772,276.213348 909.066772,281.653870 909.066772,287.094421 
	C909.066772,287.094421 909.008057,287.010986 908.625305,287.004333 
	C899.123108,287.504883 898.947876,287.509277 899.169067,296.324005 
	C899.270203,300.356750 898.582092,302.763275 893.847473,302.076111 
	C890.141968,301.538361 889.126465,303.521484 888.919250,307.032654 
	C888.591736,312.583099 888.345459,318.466309 881.698730,320.898712 
	C881.273376,321.054382 881.142883,322.015900 880.505005,322.704224 
	C877.151062,323.511597 874.167297,324.217285 871.183594,324.922974 
z"
      />
      <path
        fill="#F9FAFA"
        opacity="1.000000"
        stroke="none"
        d="
M515.937134,680.035889 
	C497.142487,680.019348 478.836060,679.904236 460.532166,680.070007 
	C456.510864,680.106384 454.680695,679.044312 454.995514,674.787659 
	C455.263092,671.169678 455.050751,667.516296 455.050751,663.027344 
	C457.762238,663.027344 460.013702,663.080017 462.261810,663.015564 
	C466.044037,662.907166 469.121857,663.126465 472.484802,659.294067 
	C476.887268,654.277039 483.647827,651.304993 489.486145,647.598389 
	C490.365143,647.040405 491.743439,647.161377 492.892181,647.152466 
	C503.375275,647.070923 513.860535,646.914124 524.340271,647.082520 
	C526.268066,647.113464 528.459045,648.138611 530.036194,649.347229 
	C534.678772,652.904846 537.770813,658.674194 544.542480,659.260620 
	C544.757690,659.279236 545.410828,662.823120 544.713867,663.450378 
	C538.559753,668.989502 532.406433,674.610291 525.657837,679.361572 
	C523.494202,680.884888 519.553772,679.884277 515.937134,680.035889 
z"
      />
      <path
        fill="#F9FAFB"
        opacity="1.000000"
        stroke="none"
        d="
M408.811310,847.120972 
	C408.540497,846.945679 408.249939,846.794556 408.001709,846.591553 
	C399.183777,839.383240 396.229187,830.299194 397.262604,818.887268 
	C398.325226,807.152405 397.897247,795.262146 397.721039,783.445862 
	C397.653992,778.950500 399.110718,777.047180 403.589874,777.272583 
	C405.739410,777.380737 407.909729,777.075989 410.538757,777.463379 
	C410.837952,789.440491 410.838043,789.438171 422.143921,789.943604 
	C422.635468,789.965576 423.121948,790.101074 423.658569,790.635498 
	C423.706329,795.176697 423.706329,799.265991 423.706329,803.763123 
	C430.185638,803.763123 435.910187,803.763123 441.548309,803.763123 
	C442.406891,808.478943 443.187958,812.769043 443.937988,817.412354 
	C437.673553,821.834900 438.976868,828.183594 438.957245,834.132019 
	C438.953400,835.294006 438.870728,836.462585 438.963470,837.616882 
	C439.323578,842.099670 437.827698,844.619141 432.796204,844.132080 
	C429.834686,843.845398 425.899719,845.255981 424.005127,843.807312 
	C417.169281,838.580505 413.585388,844.947998 408.811310,847.120972 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M753.000000,889.998657 
	C751.276978,890.330017 749.554443,890.947266 747.830872,890.950378 
	C713.967590,891.011353 680.104126,891.034424 646.240967,890.961914 
	C642.132141,890.953064 638.024658,890.350708 633.469238,890.028809 
	C634.133484,889.023804 635.214661,887.180054 636.361572,887.138245 
	C643.756897,886.868347 651.166565,886.992004 658.571594,886.987183 
	C660.487610,886.985962 662.403625,886.987061 664.319641,886.987061 
	C664.370056,886.520691 664.420471,886.054321 664.470825,885.587952 
	C660.039551,884.725342 655.615784,883.165161 651.175598,883.117004 
	C628.040100,882.866150 604.900452,883.000000 581.762207,883.000000 
	C563.702271,883.000000 545.641602,883.091980 527.582703,882.954285 
	C520.465210,882.900024 513.349182,882.006836 506.236694,882.060181 
	C483.306244,882.232117 460.378479,882.730286 437.448303,882.975159 
	C424.542999,883.113037 411.633423,883.121704 398.728729,882.957153 
	C390.112396,882.847351 381.154480,883.640625 373.021667,881.488892 
	C368.364563,880.256775 363.048523,879.211304 358.610382,876.565369 
	C357.090881,875.659424 355.616852,874.677307 354.417847,873.396729 
	C355.624512,873.294373 356.535400,873.525330 357.446289,873.756287 
	C358.406097,873.846985 359.365936,873.937744 360.785583,874.521118 
	C367.164429,875.345703 373.083435,875.677734 379.018311,876.424072 
	C380.414673,877.558899 381.779724,878.865662 383.178192,878.902466 
	C390.915558,879.105835 398.661774,879.054688 406.403168,878.953613 
	C407.947906,878.933472 409.484894,878.317993 411.393982,877.979492 
	C412.499542,877.980164 413.236542,877.978394 414.270325,878.275879 
	C438.417511,878.714478 462.267975,878.888855 486.118317,878.866455 
	C487.078979,878.865540 488.037872,877.002686 489.466370,876.005127 
	C497.624237,876.004395 505.313324,876.005859 513.018311,876.421753 
	C513.928528,877.553589 514.806091,878.867065 515.719788,878.892700 
	C522.181580,879.074280 528.655823,879.118713 535.112244,878.859863 
	C536.110535,878.819763 537.037598,877.002686 538.414612,876.005005 
	C540.221924,876.005066 541.612183,876.007446 543.018372,876.424072 
	C544.099854,877.557861 545.161926,878.899475 546.231384,878.905334 
	C568.355591,879.027405 590.480957,879.041870 612.604492,878.870361 
	C613.742798,878.861572 614.866821,877.002502 616.451904,876.003052 
	C619.938293,876.002441 622.970398,876.003662 626.016602,876.409790 
	C627.901367,877.535339 629.733032,878.558655 631.664307,878.807007 
	C632.311340,878.890198 633.212524,876.997009 634.397949,876.004944 
	C635.866455,876.005005 636.934448,876.007385 638.009827,876.377563 
	C638.630798,877.413635 639.244385,878.081848 639.857910,878.750061 
	C640.237793,877.836609 640.617676,876.923157 641.435303,876.006775 
	C643.916260,876.005737 645.959351,876.007751 648.018188,876.423523 
	C650.247437,879.579529 660.885315,880.065796 662.840088,877.470581 
	C663.092712,877.135254 662.954651,876.505554 663.466309,876.008606 
	C671.287354,876.005798 678.639648,876.006531 685.996277,876.403198 
	C685.956909,878.981689 686.321899,880.019836 689.168945,879.573608 
	C693.320801,878.922852 697.619019,878.976807 701.840576,879.128357 
	C703.275146,879.179810 704.666382,880.435486 706.077454,881.140930 
	C698.075745,883.941650 690.570251,882.789978 683.116577,881.221191 
	C682.725159,881.138794 682.731995,879.164368 682.587219,878.277710 
	C679.602722,878.277710 676.647766,878.277710 673.575256,878.277710 
	C673.350037,879.124268 673.146362,879.734741 673.027405,880.361267 
	C671.659790,887.562988 673.220154,888.941162 680.531555,888.154785 
	C687.188049,887.438904 693.922852,886.930359 700.603699,887.099243 
	C708.354736,887.295227 716.073242,888.604309 723.826721,888.924622 
	C731.976135,889.261353 740.150879,888.948364 748.313049,889.045105 
	C749.878906,889.063660 751.437866,889.665771 753.000000,889.998657 
z"
      />
      <path
        fill="#F9FAFB"
        opacity="1.000000"
        stroke="none"
        d="
M135.854202,309.030396 
	C135.798370,303.730408 135.391388,298.403564 135.793762,293.138580 
	C136.138412,288.628906 134.615005,286.675385 130.163513,286.836060 
	C129.377914,286.864410 128.581894,286.603088 127.368065,286.407410 
	C127.368065,281.178955 127.368065,276.090820 127.368065,271.405426 
	C126.665955,270.790100 126.446083,270.429565 126.170250,270.379974 
	C116.762497,268.686951 116.710052,268.691437 116.664185,259.021637 
	C116.631889,252.212936 116.731705,245.402939 116.833115,238.594437 
	C116.911072,233.361221 121.097878,228.303482 126.292114,227.992752 
	C132.394714,227.627686 138.538605,227.952499 144.827728,228.792709 
	C144.693909,237.639511 144.418701,245.672623 144.088028,253.703461 
	C143.973679,256.480591 144.925278,258.078491 147.982986,258.091064 
	C149.058746,258.095490 150.132065,258.695465 151.186935,259.489807 
	C151.369049,269.681976 151.570724,279.407837 151.760956,289.472504 
	C151.786270,290.487762 151.823029,291.164215 151.832184,292.301239 
	C151.816757,294.910522 151.828918,297.059265 151.797668,299.584534 
	C151.720551,300.968781 151.686829,301.976501 151.420395,303.253235 
	C150.564682,303.995789 150.001282,304.752625 149.309235,304.898254 
	C143.605591,306.098724 143.079514,306.818939 143.965927,312.727661 
	C142.591873,312.752899 141.217804,312.778168 139.422058,312.394714 
	C139.214371,311.001831 139.428406,310.017700 139.643112,309.030396 
	C138.486145,309.030396 137.170166,309.030396 135.854202,309.030396 
z"
      />
      <path
        fill="#66A1CF"
        opacity="1.000000"
        stroke="none"
        d="
M357.284668,873.326660 
	C356.535400,873.525330 355.624512,873.294373 354.356628,873.053711 
	C348.643127,873.028748 343.286591,873.013550 337.013550,872.995483 
	C333.400055,873.007141 330.703064,873.021667 328.006104,873.036194 
	C326.613708,873.024353 325.221344,873.012451 323.005341,872.999634 
	C319.472290,871.989624 316.762848,870.980530 314.053406,869.971436 
	C314.053406,865.856689 314.053406,861.741943 314.053406,857.054016 
	C303.402527,857.054016 293.455811,856.994690 283.510620,857.090820 
	C280.593567,857.119019 278.710052,856.689026 278.908508,853.063904 
	C279.103943,849.492859 281.395782,849.037598 284.022400,849.105347 
	C291.989899,849.310791 299.966797,849.469910 307.914093,850.021057 
	C312.798859,850.359802 314.249634,848.541565 314.113525,843.752808 
	C313.808105,833.010437 314.024078,833.009705 324.577606,833.008240 
	C331.741882,833.007202 338.906128,832.988342 346.975464,832.979370 
	C350.582733,832.684692 353.284943,832.388123 356.281006,832.082520 
	C356.770813,832.072510 356.966797,832.071411 357.162781,832.070251 
	C356.616058,843.880005 356.616058,843.881836 344.425659,843.977295 
	C342.607666,843.991516 340.789490,843.979248 338.524841,843.979248 
	C338.524841,847.697388 338.524841,851.052124 338.524841,855.249756 
	C344.652252,855.249756 350.548828,855.249756 357.123047,855.249756 
	C357.123047,861.685303 357.123047,867.291138 357.284668,873.326660 
z"
      />
      <path
        fill="#515F7A"
        opacity="1.000000"
        stroke="none"
        d="
M373.361267,643.882935 
	C372.916656,639.000549 372.808350,634.165100 373.017761,629.343445 
	C373.181641,625.570923 371.903534,622.882690 368.181030,621.090820 
	C377.585938,620.539001 387.203339,619.761475 396.731506,620.373413 
	C399.742340,620.566772 402.841675,624.162903 405.323822,626.754150 
	C409.689423,631.311646 414.899536,632.869324 420.974457,632.817871 
	C430.289154,632.738831 439.607483,632.986816 448.920349,632.842407 
	C452.823303,632.781860 454.314545,634.311157 454.177460,638.126465 
	C454.010864,642.763977 454.137848,647.411987 454.137848,652.654053 
	C451.961090,652.753906 450.211243,652.894348 448.460724,652.903320 
	C442.804718,652.932251 437.130615,652.627197 431.496429,652.978760 
	C422.993439,653.509277 415.123138,652.941833 408.486237,646.439819 
	C406.668793,644.659302 403.086853,644.106262 400.281067,644.014343 
	C391.474152,643.725891 382.651215,643.925354 373.361267,643.882935 
z"
      />
      <path
        fill="#563446"
        opacity="1.000000"
        stroke="none"
        d="
M531.703247,832.068604 
	C527.684082,829.378235 523.456726,826.948792 519.689148,823.943054 
	C511.848206,817.687622 511.621796,810.743103 518.880615,803.791870 
	C522.105713,800.703430 525.636414,797.934082 529.471436,795.379761 
	C530.145935,797.373291 530.327637,799.015869 530.618713,800.638855 
	C531.322449,804.561218 534.078491,805.245728 536.840820,803.460510 
	C540.838623,800.877014 544.827698,797.855225 544.230713,791.682251 
	C545.964355,790.493164 547.621338,789.679016 549.301941,788.916809 
	C551.243286,788.036438 554.245422,787.721680 554.977356,786.257019 
	C558.039246,780.129578 555.119751,775.890015 548.379333,775.884277 
	C547.220154,775.883301 546.061035,775.817383 544.901855,775.781494 
	C551.696777,769.013489 557.619446,761.218628 567.923950,755.959229 
	C567.923950,760.023987 567.490845,762.959412 568.050659,765.691467 
	C568.569153,768.221985 569.704529,770.919250 571.371338,772.849609 
	C573.616272,775.449463 576.951294,777.102417 579.225647,779.683960 
	C581.510986,782.278198 583.069214,785.513000 584.512817,788.822571 
	C583.218628,788.462891 582.357666,787.747742 581.462769,787.004395 
	C581.230042,787.811584 580.971191,788.709229 580.264648,789.628235 
	C574.555298,789.649597 569.293762,789.649597 563.509460,789.649597 
	C563.509460,794.881104 563.509460,799.131592 563.509460,803.955566 
	C556.937317,803.955566 550.898254,803.955566 544.814148,803.955566 
	C544.483093,808.673401 544.192566,812.812439 543.445190,816.985474 
	C539.401123,817.019409 535.814026,817.019409 531.703247,817.019409 
	C531.703247,822.462830 531.703247,827.265747 531.703247,832.068604 
z"
      />
      <path
        fill="#556580"
        opacity="1.000000"
        stroke="none"
        d="
M702.030334,344.531677 
	C701.318298,343.883301 700.438293,342.838257 700.062805,341.635712 
	C699.664246,340.359314 699.744507,338.933411 699.537354,336.745056 
	C694.533813,341.593414 698.423096,349.058044 692.929321,353.824219 
	C691.730652,347.475220 690.758545,342.326141 689.362671,336.620941 
	C688.934387,332.086456 688.960510,328.107788 688.920593,324.129822 
	C688.656067,297.792877 688.377380,271.456116 688.103394,245.119308 
	C688.326721,239.687302 688.550049,234.255310 688.930542,228.373810 
	C702.088501,227.186722 702.085510,227.186722 702.084900,239.854004 
	C702.083191,274.591248 702.084290,309.328522 702.030334,344.531677 
z"
      />
      <path
        fill="#F6F7F8"
        opacity="1.000000"
        stroke="none"
        d="
M819.759521,488.471283 
	C819.579407,492.518433 817.919495,494.321014 814.099243,494.156311 
	C809.800293,493.970947 805.487244,494.113617 801.267578,494.113617 
	C798.299194,489.601196 803.239685,479.519318 791.118225,482.042633 
	C791.118225,478.374634 790.975464,475.128876 791.155945,471.901123 
	C791.361023,468.233429 789.680725,466.713226 786.167114,466.766418 
	C783.216553,466.811096 780.264832,466.775574 777.120728,466.775574 
	C777.120728,453.486237 777.120728,440.924347 777.120728,428.244293 
	C779.534485,428.978485 781.668762,429.627655 784.959534,430.628632 
	C784.959534,433.733185 785.210449,437.330627 784.899109,440.878693 
	C784.463074,445.846680 786.917114,447.395203 791.366638,447.309570 
	C795.989258,447.220551 800.614929,447.289734 805.897705,447.289734 
	C805.897705,450.477173 805.888855,453.580902 805.899536,456.684570 
	C805.920654,462.836639 807.663208,464.590790 813.930054,464.736969 
	C815.721863,464.778778 817.515442,464.743347 819.775269,464.743347 
	C819.775269,472.790466 819.775269,480.405396 819.759521,488.471283 
z"
      />
      <path
        fill="#5B6984"
        opacity="1.000000"
        stroke="none"
        d="
M611.443787,630.890503 
	C611.319458,628.246399 611.884766,625.666687 611.947083,623.074890 
	C612.018250,620.116577 613.276489,618.955078 616.171936,618.978210 
	C622.595764,619.029358 629.020752,618.933044 635.950073,618.801147 
	C638.616699,618.734253 640.778381,618.773071 642.940247,618.790344 
	C657.230286,618.904297 665.191162,625.020386 668.230164,639.223694 
	C671.375793,653.925293 671.202515,668.456543 661.734131,681.176514 
	C658.203857,685.919189 653.778687,690.068420 649.386353,694.077026 
	C644.700195,698.353638 638.859741,699.516541 632.177246,698.523071 
	C637.533203,692.729248 643.382935,687.156738 649.112366,681.463074 
	C657.394348,673.232727 661.814331,663.545410 661.109253,651.541382 
	C660.465759,640.585754 651.232056,631.105408 640.325195,631.054443 
	C630.853943,631.010193 621.382568,630.983765 611.443787,630.890503 
z"
      />
      <path
        fill="#A5C1D1"
        opacity="1.000000"
        stroke="none"
        d="
M580.712402,789.606873 
	C580.971191,788.709229 581.230042,787.811584 581.462769,787.004395 
	C582.357666,787.747742 583.218628,788.462891 584.388550,789.193176 
	C590.245972,793.142761 590.002136,798.881226 589.792297,804.774658 
	C589.448914,814.415955 589.555786,824.074768 589.115540,833.709900 
	C589.022034,835.755676 587.766235,838.043762 586.395508,839.672852 
	C583.728149,842.843018 580.129639,845.295654 577.777710,848.649170 
	C574.568176,853.225525 570.505676,854.332214 565.354797,853.945740 
	C561.549255,853.660217 557.696838,853.744263 553.879700,853.941833 
	C550.348267,854.124634 547.463989,853.789124 545.805786,849.936523 
	C545.342285,848.859619 543.164490,848.178345 541.677368,847.955627 
	C538.594238,847.493896 535.446838,847.461487 531.870544,847.222046 
	C531.870544,842.252991 531.870544,837.617798 531.786865,832.525635 
	C531.703247,827.265747 531.703247,822.462830 531.703247,817.019409 
	C535.814026,817.019409 539.401123,817.019409 543.506592,817.342346 
	C549.698486,821.750549 549.229553,827.692017 548.920166,833.629761 
	C548.790466,836.118530 548.736816,838.644348 549.035278,841.106506 
	C549.158691,842.124939 550.309875,843.855957 550.971252,843.845520 
	C559.617859,843.709045 568.594360,846.344360 575.591858,837.499939 
	C579.650330,832.370361 582.444336,827.844055 582.109314,821.150024 
	C581.668945,812.351379 582.097046,803.511841 581.982178,794.692078 
	C581.960022,792.991760 581.155823,791.301697 580.712402,789.606873 
z"
      />
      <path
        fill="#F8F9FA"
        opacity="1.000000"
        stroke="none"
        d="
M546.554565,695.955078 
	C546.396790,700.255310 546.575073,704.611694 545.872864,708.821106 
	C545.666748,710.056580 542.991089,711.692444 541.397949,711.748596 
	C532.759155,712.053467 524.104797,711.908630 515.455994,711.949890 
	C513.835632,711.957642 512.216003,712.107300 509.990173,712.222900 
	C509.990173,716.124817 509.694763,719.762695 510.067749,723.330627 
	C510.497101,727.437927 508.443451,728.681213 504.955688,728.771301 
	C501.797302,728.852905 498.606232,729.119324 495.483185,728.792358 
	C490.342377,728.254150 486.402100,729.656677 483.814209,734.351624 
	C483.189331,735.485229 482.075714,736.349426 480.782654,737.565613 
	C479.295105,737.191589 478.211731,736.589478 477.128357,735.987366 
	C473.759674,730.018066 475.174683,723.756653 475.669098,716.741089 
	C481.896698,716.741089 487.809631,716.741089 494.180420,716.741089 
	C494.180420,711.183044 494.180420,706.406738 494.180420,701.696655 
	C495.041656,701.339111 495.466553,701.041321 495.918182,700.993042 
	C506.638489,699.845886 517.362549,698.733521 528.081116,697.570251 
	C528.497681,697.525024 528.866333,697.038269 529.952148,696.391602 
	C535.819458,696.020386 540.991943,696.014771 546.263184,696.004395 
	C546.361938,695.999573 546.554565,695.955078 546.554565,695.955078 
z"
      />
      <path
        fill="#F9FAFB"
        opacity="1.000000"
        stroke="none"
        d="
M189.405090,288.604706 
	C189.165421,293.027344 188.925751,297.449951 188.661911,302.318542 
	C184.591782,302.559296 180.831604,302.781708 176.244385,302.982727 
	C172.238617,302.310944 169.059860,301.660492 165.939087,300.657684 
	C168.347290,296.269257 170.697510,292.233185 173.047729,288.197113 
	C173.581207,286.417542 174.553162,284.644287 174.578812,282.857422 
	C174.784225,268.554047 174.778046,254.247787 174.907333,239.943039 
	C174.962402,233.848206 178.728943,228.760330 184.948517,228.030090 
	C189.433746,227.503494 194.123260,228.716843 198.878189,229.978058 
	C199.088104,234.874298 199.141495,238.946625 198.739380,242.991150 
	C196.292160,243.029160 194.143967,242.660141 192.359665,243.302292 
	C191.002090,243.790894 189.158813,245.557602 189.150665,246.766571 
	C189.056702,260.711426 189.271988,274.658325 189.405090,288.604706 
z"
      />
      <path
        fill="#9CB7CB"
        opacity="1.000000"
        stroke="none"
        d="
M871.848450,401.652283 
	C866.478333,400.706604 861.056946,399.373444 855.254517,398.030182 
	C854.811279,393.553680 854.454285,389.066345 854.770020,384.626801 
	C855.085999,380.182861 853.478149,378.459564 849.101501,379.027161 
	C847.818604,379.193481 846.479492,378.926117 844.704773,378.833466 
	C844.704773,370.062225 844.716614,361.449371 844.701904,352.836578 
	C844.680908,340.563904 844.674744,340.607300 856.730591,340.018799 
	C864.254089,339.651489 866.907898,333.585327 870.740845,328.247620 
	C872.636475,330.710968 873.946716,333.188416 875.257019,335.665863 
	C866.796448,337.478729 865.613525,338.927277 865.591614,347.683838 
	C865.561218,359.792542 865.418274,371.904205 865.667603,384.007507 
	C865.716858,386.397644 867.347534,388.747314 868.207703,391.130585 
	C869.424255,394.501434 870.602539,397.886078 871.848450,401.652283 
z"
      />
      <path
        fill="#A2C0D3"
        opacity="1.000000"
        stroke="none"
        d="
M128.971588,388.589539 
	C125.551880,389.050293 122.241875,389.050293 117.951607,389.050293 
	C117.951607,385.799805 117.954918,382.906555 117.950966,380.013306 
	C117.939621,371.708984 118.019196,363.402924 117.862350,355.101318 
	C117.805870,352.112061 118.791122,350.937042 121.851105,351.007141 
	C127.826279,351.144012 133.824829,350.711517 139.781464,351.062286 
	C144.432739,351.336151 146.831100,349.248291 148.403610,345.257538 
	C149.302353,342.976624 150.593552,340.850342 151.833954,338.332886 
	C153.425842,339.721832 156.110596,341.396423 156.162247,343.148590 
	C156.529266,355.599640 156.357742,368.066589 156.357742,380.988953 
	C154.223251,381.085266 152.441101,381.113434 150.668121,381.255768 
	C145.872864,381.640686 143.814621,379.781952 143.893936,374.667969 
	C144.033386,365.674927 143.768311,365.674988 134.840469,365.818695 
	C133.203751,365.845062 131.566254,365.822449 129.081284,365.822449 
	C129.081284,373.579620 129.081284,380.854218 128.971588,388.589539 
z"
      />
      <path
        fill="#5B3246"
        opacity="1.000000"
        stroke="none"
        d="
M473.103027,820.256775 
	C470.627747,822.753479 468.152466,825.250183 464.924927,827.879395 
	C462.255035,827.915833 460.337463,827.819824 457.576843,827.681580 
	C459.629272,818.490845 457.160370,812.485779 446.572937,812.014893 
	C447.944916,810.502686 449.037842,809.778809 450.151276,809.088013 
	C452.926575,807.366028 457.394531,806.220398 458.186615,803.844421 
	C460.915710,795.658203 466.675903,799.160034 471.657043,799.248657 
	C471.959167,799.254028 472.263580,799.131653 473.155762,798.946655 
	C473.729523,796.725220 474.599457,794.274841 474.947144,791.752441 
	C475.293549,789.239807 476.586853,788.093567 478.478668,789.665649 
	C483.207397,793.595093 485.463623,790.113525 488.266724,786.768433 
	C488.091064,785.026611 487.647919,783.516357 487.135010,781.610352 
	C487.062744,780.143799 487.060242,779.072998 487.289459,778.002808 
	C487.521179,778.003479 487.984589,778.007324 488.209747,778.242188 
	C488.974426,777.543701 489.352509,776.446716 490.092499,775.716553 
	C491.120667,774.701965 492.627350,773.049744 493.567841,773.263062 
	C497.668976,774.193481 501.639343,775.700378 505.657196,776.997925 
	C505.726471,778.389954 505.795715,779.782104 505.460815,781.648560 
	C504.366486,783.123901 503.389099,784.034912 503.041473,785.143372 
	C502.099426,788.147034 501.394684,790.975891 497.053497,789.358887 
	C496.454651,789.135742 495.038300,791.107422 494.000366,792.062988 
	C493.465302,792.311462 492.930237,792.559937 491.787292,792.964722 
	C488.536407,796.030640 487.666809,798.973694 491.011536,801.996521 
	C491.753326,805.923767 490.115356,808.135803 486.538757,810.005127 
	C483.325256,811.684631 480.752106,814.589294 477.296295,817.084473 
	C476.473450,817.498047 476.250763,817.780579 476.028076,818.063171 
	C476.028076,818.063171 476.019318,817.995911 475.666748,818.012512 
	C474.577148,818.771667 473.840088,819.514221 473.103027,820.256775 
z"
      />
      <path
        fill="#F7FAFB"
        opacity="1.000000"
        stroke="none"
        d="
M935.519836,337.992554 
	C936.088501,348.124390 933.241211,350.985626 923.051819,351.009705 
	C914.962646,351.028778 906.873352,351.013550 899.064026,351.013550 
	C899.064026,361.086365 899.064026,370.610962 899.039612,380.609314 
	C895.110901,381.379974 891.204773,381.857544 887.303894,381.817902 
	C886.544983,381.810150 885.173401,379.799133 885.156433,378.695740 
	C884.983398,367.416412 884.736389,356.122864 885.150452,344.856812 
	C885.341125,339.668457 889.958862,338.491547 894.249939,338.364655 
	C904.198120,338.070465 914.155579,338.090485 924.109375,337.989990 
	C927.759033,337.953156 931.408752,337.935791 935.519836,337.992554 
z"
      />
      <path
        fill="#F4F6F8"
        opacity="1.000000"
        stroke="none"
        d="
M351.072784,614.930725 
	C347.332916,615.309998 343.598755,615.932190 339.852051,616.020081 
	C330.962738,616.228760 330.964325,616.126282 331.040070,624.703003 
	C331.058929,626.841370 331.042877,628.979919 331.042877,632.024414 
	C326.029785,632.024414 321.430511,632.171265 316.845459,631.978516 
	C313.089691,631.820618 311.983032,633.272400 312.151031,637.011292 
	C312.620941,647.470032 313.273102,657.983154 312.607483,668.395203 
	C312.384583,671.882019 308.404053,675.128662 306.047546,678.819885 
	C297.227112,668.216492 296.226929,646.512085 303.942688,635.368591 
	C304.320404,634.823059 304.650513,634.036560 305.181427,633.836609 
	C312.246155,631.175598 312.826141,625.065002 312.857880,617.812134 
	C317.175598,617.812134 321.142120,618.133118 325.016785,617.675842 
	C326.934967,617.449524 328.940033,616.076782 330.457703,614.721130 
	C333.885132,611.659668 337.033997,608.286316 340.701233,605.371582 
	C344.428467,608.779114 347.750610,611.854919 351.072784,614.930725 
z"
      />
      <path
        fill="#F9FAFB"
        opacity="1.000000"
        stroke="none"
        d="
M854.988708,286.885315 
	C851.685486,286.829193 848.327454,287.095856 845.107727,286.548462 
	C843.921082,286.346741 842.194214,284.232422 842.183472,282.977875 
	C842.054199,267.835327 842.122009,252.689362 842.330994,237.546951 
	C842.382019,233.846954 848.271912,228.319702 852.477783,227.990997 
	C856.592957,227.669357 860.754578,227.941147 865.114624,228.753387 
	C865.442261,233.999557 865.551025,238.444824 865.228149,242.912445 
	C863.469543,242.955032 862.122742,243.131577 860.819092,242.966888 
	C856.459900,242.416199 854.635925,243.775925 854.881836,248.615952 
	C855.310669,257.055603 855.012146,265.532257 855.009644,274.434906 
	C854.996765,278.879364 854.992737,282.882355 854.988708,286.885315 
z"
      />
      <path
        fill="#A5C1D1"
        opacity="1.000000"
        stroke="none"
        d="
M408.992859,847.411011 
	C413.585388,844.947998 417.169281,838.580505 424.005127,843.807312 
	C425.899719,845.255981 429.834686,843.845398 432.796204,844.132080 
	C437.827698,844.619141 439.323578,842.099670 438.963470,837.616882 
	C438.870728,836.462585 438.953400,835.294006 438.957245,834.132019 
	C438.976868,828.183594 437.673553,821.834900 444.397034,817.344116 
	C447.036102,816.898376 449.201080,817.032043 451.330566,816.815979 
	C455.366913,816.406433 457.037415,818.086060 456.896667,822.127319 
	C456.770386,825.752869 457.026001,829.391785 457.113129,833.024780 
	C457.052124,835.854187 456.670593,838.721497 457.000366,841.504639 
	C457.589478,846.476074 455.125671,848.094971 450.735931,847.916565 
	C446.998260,847.764709 443.990204,848.728882 441.691071,852.021301 
	C440.943298,853.092041 438.873444,853.679016 437.387329,853.719543 
	C430.562927,853.905762 423.656830,854.405457 416.937592,853.551941 
	C414.147125,853.197510 411.750275,849.744812 408.992859,847.411011 
z"
      />
      <path
        fill="#DAE3E8"
        opacity="1.000000"
        stroke="none"
        d="
M189.046448,400.549133 
	C182.701797,401.030182 176.450455,401.049988 169.800568,401.058838 
	C170.763000,397.432678 172.157883,393.829712 173.472488,390.197723 
	C174.287292,387.946533 175.584473,385.662415 175.626892,383.373840 
	C175.852142,371.223419 175.748764,359.066956 176.105042,346.313873 
	C176.305481,345.145416 176.160095,344.575684 176.014709,344.005920 
	C175.885559,343.271240 175.756424,342.536560 176.054214,341.366608 
	C178.856400,340.419128 181.231644,339.906921 183.606888,339.394745 
	C185.110443,339.658325 186.613998,339.921875 188.503540,340.735840 
	C189.037491,353.997345 189.185501,366.708405 189.333511,379.419464 
	C189.268921,386.308929 189.204330,393.198395 189.046448,400.549133 
z"
      />
      <path
        fill="#5B6883"
        opacity="1.000000"
        stroke="none"
        d="
M426.390320,713.952515 
	C425.962677,708.911865 426.160706,703.946533 425.935577,699.000488 
	C425.876495,697.702026 424.792358,695.757446 423.691559,695.310669 
	C416.115448,692.236023 416.075500,692.338623 415.956055,684.102844 
	C415.941650,683.111328 415.897003,682.120300 415.856201,680.690430 
	C429.749969,678.945801 435.315155,682.402466 440.789185,695.245544 
	C441.395569,696.668335 443.504883,698.125793 445.080078,698.333862 
	C449.807983,698.958313 454.616608,698.971802 459.304535,699.218994 
	C461.251678,706.483887 455.174164,713.924316 447.301819,714.056152 
	C440.489227,714.170349 433.672699,714.051086 426.390320,713.952515 
z"
      />
      <path
        fill="#F9FAFA"
        opacity="1.000000"
        stroke="none"
        d="
M618.492798,664.017944 
	C618.908752,667.092712 618.582703,670.099670 618.892151,673.039673 
	C619.380676,677.681274 617.917786,679.390869 612.966431,679.231506 
	C601.560547,678.864319 590.134766,679.128479 578.717163,679.101440 
	C574.131836,679.090515 569.546753,678.987305 564.552490,678.922241 
	C565.409302,674.710449 566.675110,670.502502 568.415466,664.717285 
	C584.438721,664.459839 601.237244,664.190002 618.492798,664.017944 
z"
      />
      <path
        fill="#BA8F97"
        opacity="1.000000"
        stroke="none"
        d="
M423.610809,790.183655 
	C423.121948,790.101074 422.635468,789.965576 422.143921,789.943604 
	C410.838043,789.438171 410.837952,789.440491 410.987549,777.511658 
	C411.676270,775.416321 412.959625,773.791565 412.999146,772.137207 
	C413.257782,761.309753 413.249054,750.476013 413.322968,739.644287 
	C413.335327,737.832581 413.324707,736.020691 413.324707,733.386658 
	C427.159302,733.386658 440.575226,733.386658 454.438232,733.871582 
	C455.253998,739.590759 455.622681,744.825134 455.878143,750.370117 
	C452.042786,754.436829 448.487915,758.380737 444.546570,761.890747 
	C441.272461,764.806641 440.108612,767.870728 442.069916,772.290894 
	C442.094360,773.704346 442.089935,774.731689 441.690063,775.653076 
	C437.862061,775.378845 434.429535,775.210571 430.630157,775.124084 
	C428.541656,775.575867 426.819977,775.945923 425.109558,775.928711 
	C426.239044,774.715820 427.243683,773.570801 428.497009,773.125061 
	C432.060760,771.857483 436.569092,771.901306 436.270844,766.203918 
	C436.239899,765.613220 437.769196,765.055054 438.316650,764.295898 
	C439.408325,762.781982 440.320770,761.138855 441.307251,759.549133 
	C439.809601,758.697998 438.374756,757.298401 436.800262,757.118164 
	C434.119812,756.811218 432.855377,755.793884 433.455444,753.028992 
	C437.560852,752.851440 441.213074,752.846497 444.851959,752.623596 
	C447.082581,752.486877 449.634369,752.731750 449.391205,749.006592 
	C449.180206,745.774292 448.005737,744.137451 444.623871,744.224121 
	C441.476410,744.304749 438.296417,744.411743 435.179474,744.066650 
	C431.014221,743.605408 429.784058,745.478333 430.271759,749.230164 
	C430.421021,750.378357 430.496887,751.536011 430.226715,752.739075 
	C429.286713,751.825378 428.142181,750.780640 428.262970,749.911926 
	C428.926941,745.137207 429.900604,740.405457 430.887970,735.002075 
	C427.955658,735.002075 425.499969,735.001404 423.044312,735.002197 
	C415.436768,735.004517 415.290955,735.428406 414.939941,742.777710 
	C414.317383,755.813660 416.829498,768.981140 413.239319,781.939148 
	C412.914001,783.113403 415.309692,785.041565 416.441772,786.619629 
	C417.956757,785.254883 419.979889,784.148438 420.841949,782.451904 
	C421.682526,780.797791 420.218384,777.837402 423.836090,778.415649 
	C423.765442,782.610962 423.688141,786.397278 423.610809,790.183655 
z"
      />
      <path
        fill="#5B6984"
        opacity="1.000000"
        stroke="none"
        d="
M837.302124,536.792358 
	C841.088684,532.785828 845.750610,529.245300 849.510803,524.920715 
	C859.402100,513.544800 866.574524,500.877869 866.100891,485.090759 
	C865.931396,479.439056 865.926636,473.782410 865.853638,467.675232 
	C867.180176,467.103485 868.501160,466.998535 869.818909,466.862854 
	C873.335205,466.500854 875.006226,467.781860 874.922852,471.609314 
	C874.737854,480.100616 874.943665,488.599670 874.843201,497.093811 
	C874.721008,507.422638 869.144226,515.260376 862.830078,522.720032 
	C861.432983,524.370544 859.681274,525.821594 858.667053,527.678223 
	C854.024353,536.177490 846.757385,538.418152 837.302124,536.792358 
z"
      />
      <path
        fill="#5B6883"
        opacity="1.000000"
        stroke="none"
        d="
M525.247559,744.720337 
	C529.747925,740.613831 534.600281,736.497192 539.702759,732.718323 
	C547.811829,726.712646 556.055115,720.885620 564.325073,715.102356 
	C565.718323,714.127991 567.531921,713.198303 569.150208,713.200012 
	C575.584717,713.206970 582.018921,713.591064 588.831482,714.055115 
	C580.605347,724.563416 569.122559,730.317444 556.879639,734.935547 
	C554.094421,735.986206 550.997253,736.981567 548.892639,738.922668 
	C542.126770,745.163574 534.166077,745.746826 525.247559,744.720337 
z"
      />
      <path
        fill="#F6F7F8"
        opacity="1.000000"
        stroke="none"
        d="
M852.995972,473.048645 
	C853.317017,475.336273 853.541504,477.642365 853.974854,479.908539 
	C856.885620,495.133026 846.406982,503.594513 837.397400,512.791870 
	C836.829285,513.371765 835.811646,513.590576 835.399231,514.232117 
	C829.390808,523.577332 820.196899,520.832214 810.726135,520.890564 
	C812.075195,518.469971 813.069458,516.685913 814.076050,514.548218 
	C819.293701,509.864990 825.612671,511.136932 832.135315,510.901459 
	C831.018372,504.517761 834.526794,500.677063 839.004395,497.153534 
	C840.157898,496.245880 840.811951,494.115051 840.875854,492.513428 
	C841.146851,485.722229 841.109253,478.918671 841.645020,471.597961 
	C845.735779,471.733765 849.365845,472.391205 852.995972,473.048645 
z"
      />
      <path
        fill="#553C4F"
        opacity="1.000000"
        stroke="none"
        d="
M423.658569,790.635498 
	C423.688141,786.397278 423.765442,782.610962 423.985291,778.194214 
	C424.451355,777.147888 424.774841,776.731934 425.098297,776.315918 
	C426.819977,775.945923 428.541656,775.575867 430.603088,775.579712 
	C431.011749,780.138977 431.080658,784.324402 431.111908,788.906860 
	C434.717224,792.465637 438.356384,795.631958 442.041901,798.743286 
	C442.093964,798.787231 442.727692,798.142090 443.391602,797.973633 
	C445.331757,799.737549 446.794525,801.606445 448.665588,802.871277 
	C450.103821,803.843567 452.496552,804.964417 453.676727,804.392578 
	C455.453705,803.531616 457.473389,801.362427 457.692139,799.545105 
	C457.975647,797.189392 456.591064,794.632874 455.964233,791.871460 
	C457.550903,791.086182 459.097992,790.592224 460.781189,790.424805 
	C461.134460,791.194702 461.264679,791.716675 461.582794,792.068848 
	C463.758545,794.477234 465.525970,797.702881 469.710754,796.221924 
	C473.837585,794.761475 472.905884,791.247375 472.846344,788.227600 
	C472.788269,785.282837 472.336121,782.345886 472.029633,779.117493 
	C474.291229,781.126099 476.050598,782.616150 477.728210,784.193054 
	C481.980988,788.190491 482.852325,788.159302 486.900909,784.069031 
	C487.297974,785.289978 487.648590,786.145020 487.999207,787.000000 
	C485.463623,790.113525 483.207397,793.595093 478.478668,789.665649 
	C476.586853,788.093567 475.293549,789.239807 474.947144,791.752441 
	C474.599457,794.274841 473.729523,796.725220 473.155762,798.946655 
	C472.263580,799.131653 471.959167,799.254028 471.657043,799.248657 
	C466.675903,799.160034 460.915710,795.658203 458.186615,803.844421 
	C457.394531,806.220398 452.926575,807.366028 450.151276,809.088013 
	C449.037842,809.778809 447.944916,810.502686 446.572937,812.014893 
	C457.160370,812.485779 459.629272,818.490845 457.576843,827.681580 
	C460.337463,827.819824 462.255035,827.915833 464.625366,828.022949 
	C462.649261,829.651550 460.220428,831.269226 457.452362,832.955811 
	C457.026001,829.391785 456.770386,825.752869 456.896667,822.127319 
	C457.037415,818.086060 455.366913,816.406433 451.330566,816.815979 
	C449.201080,817.032043 447.036102,816.898376 444.428101,816.990845 
	C443.187958,812.769043 442.406891,808.478943 441.548309,803.763123 
	C435.910187,803.763123 430.185638,803.763123 423.706329,803.763123 
	C423.706329,799.265991 423.706329,795.176697 423.658569,790.635498 
z"
      />
      <path
        fill="#DAE3E8"
        opacity="1.000000"
        stroke="none"
        d="
M165.881104,301.010040 
	C169.059860,301.660492 172.238617,302.310944 175.805023,303.025269 
	C176.513290,312.486023 176.833923,321.882904 176.876404,331.666931 
	C171.092087,328.693024 165.585938,325.331909 160.079773,321.970795 
	C156.067001,321.435120 152.054230,320.899475 148.343475,320.404114 
	C147.150803,317.976776 145.931946,315.496124 144.339508,312.871582 
	C143.079514,306.818939 143.605591,306.098724 149.309235,304.898254 
	C150.001282,304.752625 150.564682,303.995789 151.917496,303.260681 
	C157.058563,302.336090 161.469833,301.673065 165.881104,301.010040 
z"
      />
      <path
        fill="#563446"
        opacity="1.000000"
        stroke="none"
        d="
M455.991333,750.059448 
	C455.622681,744.825134 455.253998,739.590759 454.892944,733.947021 
	C456.889801,727.690063 461.789429,730.183411 465.448395,730.283997 
	C468.574493,730.369995 471.490295,731.922607 471.128571,736.275146 
	C470.940979,738.532349 472.150238,740.953918 471.776947,743.138672 
	C470.481445,750.720215 474.485870,755.607849 480.043732,759.300659 
	C487.466309,764.232422 495.322021,768.508972 502.918457,773.185913 
	C504.068359,773.893921 504.821442,775.246460 505.707642,776.649414 
	C501.639343,775.700378 497.668976,774.193481 493.567841,773.263062 
	C492.627350,773.049744 491.120667,774.701965 490.092499,775.716553 
	C489.352509,776.446716 488.974426,777.543701 488.077698,778.012695 
	C487.412262,777.389832 487.104065,777.231262 486.548279,776.863403 
	C484.332703,774.654785 482.364746,772.655518 480.212769,770.376953 
	C474.017487,763.803894 468.136230,757.375488 461.883484,751.331482 
	C460.656250,750.145203 457.992584,750.445007 455.991333,750.059448 
z"
      />
      <path
        fill="#ABC6D5"
        opacity="1.000000"
        stroke="none"
        d="
M116.263687,417.263550 
	C128.317810,416.906586 140.255798,416.906586 151.845108,416.906586 
	C152.396027,417.610718 152.787155,417.882843 152.764542,418.114624 
	C151.961182,426.349823 154.990677,432.604584 162.195831,436.825531 
	C162.991516,437.291687 163.444321,438.343109 163.807251,439.635864 
	C162.405533,441.278381 161.252701,442.408081 160.099869,443.537811 
	C161.664200,444.356842 163.200531,445.812256 164.797821,445.882538 
	C171.052734,446.157684 177.328705,445.914612 183.592514,446.055878 
	C185.052185,446.088837 186.494858,446.875793 187.945358,447.315826 
	C178.498535,447.451965 169.039200,447.892548 159.613953,447.504486 
	C157.332062,447.410583 155.162766,444.581787 152.936127,442.410187 
	C152.592850,440.531738 152.651489,438.820312 151.831131,438.069885 
	C150.333954,436.700500 148.316132,435.900330 146.515503,434.862701 
	C145.353271,427.807892 145.353271,427.807129 138.016068,427.777832 
	C134.851501,427.765228 131.686935,427.754669 128.522369,427.746643 
	C122.039940,427.730225 117.869919,424.319031 116.263687,417.263550 
z"
      />
      <path
        fill="#FAFBFD"
        opacity="1.000000"
        stroke="none"
        d="
M387.686523,678.808044 
	C379.924713,678.887451 372.160248,679.100464 364.403168,678.944153 
	C362.654846,678.908997 360.407135,678.044617 359.296509,676.770935 
	C355.965332,672.950684 353.082886,668.739014 350.011536,664.324951 
	C362.280212,664.024414 374.562683,664.078979 387.260071,664.686890 
	C387.678864,669.762817 387.682678,674.285461 387.686523,678.808044 
z"
      />
      <path
        fill="#BDCCD9"
        opacity="1.000000"
        stroke="none"
        d="
M189.810074,288.603210 
	C189.271988,274.658325 189.056702,260.711426 189.150665,246.766571 
	C189.158813,245.557602 191.002090,243.790894 192.359665,243.302292 
	C194.143967,242.660141 196.292160,243.029160 199.021271,243.292114 
	C199.856110,257.098541 199.975555,270.576172 200.001602,284.053955 
	C200.003342,284.955353 199.297607,285.858124 198.920944,286.760254 
	C196.018982,287.374054 193.117035,287.987885 189.810074,288.603210 
z"
      />
      <path
        fill="#ABC6D5"
        opacity="1.000000"
        stroke="none"
        d="
M880.875183,322.602539 
	C881.142883,322.015900 881.273376,321.054382 881.698730,320.898712 
	C888.345459,318.466309 888.591736,312.583099 888.919250,307.032654 
	C889.126465,303.521484 890.141968,301.538361 893.847473,302.076111 
	C898.582092,302.763275 899.270203,300.356750 899.169067,296.324005 
	C898.947876,287.509277 899.123108,287.504883 908.667664,287.032776 
	C909.016052,294.049927 908.881714,301.032104 908.889893,308.014099 
	C908.893372,310.989838 907.903442,313.169891 904.660645,312.709595 
	C898.281006,311.804230 896.352295,314.976471 897.203674,321.466400 
	C893.622742,321.466400 890.506409,321.286865 887.427124,321.546600 
	C886.012878,321.665863 884.671326,322.647156 882.813660,323.583740 
	C881.845459,323.487518 881.360291,323.045013 880.875183,322.602539 
z"
      />
      <path
        fill="#92AABE"
        opacity="1.000000"
        stroke="none"
        d="
M189.757156,379.288574 
	C189.185501,366.708405 189.037491,353.997345 188.872864,340.843719 
	C200.306778,339.104004 200.283051,339.103912 200.238617,350.404846 
	C200.202240,359.657654 200.231216,368.910736 200.231216,379.157654 
	C196.599884,379.157654 193.390350,379.157654 189.757156,379.288574 
z"
      />
      <path
        fill="#7F91A9"
        opacity="1.000000"
        stroke="none"
        d="
M865.659790,242.890076 
	C865.551025,238.444824 865.442261,233.999557 865.433105,229.085983 
	C871.425110,231.096039 873.540161,235.240707 873.292969,241.848892 
	C872.733215,256.810883 873.066101,271.806244 873.043945,287.236420 
	C873.052002,287.684814 873.281189,287.954041 872.797729,287.999756 
	C868.821960,288.072388 866.252502,286.933807 865.981323,282.515320 
	C865.918945,268.998657 865.789368,255.944366 865.659790,242.890076 
z"
      />
      <path
        fill="#A2AFBE"
        opacity="1.000000"
        stroke="none"
        d="
M755.007568,247.476837 
	C749.242615,242.469955 742.865112,239.616302 734.689148,241.670700 
	C731.716309,242.417709 728.411499,241.844147 724.823547,241.823792 
	C724.388916,237.490005 724.388916,233.205521 724.388916,228.239792 
	C731.762817,228.564697 739.189941,225.686478 745.892456,230.849747 
	C746.019836,230.947891 746.187439,231.021301 746.346069,231.047531 
	C756.064026,232.655853 755.977539,239.613037 755.007568,247.476837 
z"
      />
      <path
        fill="#E4E9EC"
        opacity="1.000000"
        stroke="none"
        d="
M865.914062,282.977661 
	C866.252502,286.933807 868.821960,288.072388 872.701904,288.148254 
	C872.374146,292.974518 871.658813,297.697968 870.907959,302.855591 
	C866.124084,302.922272 861.375732,302.554718 855.678162,302.113647 
	C855.678162,297.198608 855.678162,292.309418 855.333435,287.152771 
	C854.992737,282.882355 854.996765,278.879364 855.384888,274.484009 
	C857.402344,274.726837 859.019958,275.840576 860.672241,275.894440 
	C863.793640,275.996216 865.180176,277.165436 865.052979,280.318451 
	C865.017700,281.191833 865.609802,282.090546 865.914062,282.977661 
M869.407471,299.483521 
	C869.407471,299.483521 869.525024,299.410675 869.407471,299.483521 
z"
      />
      <path
        fill="#A2C0D3"
        opacity="1.000000"
        stroke="none"
        d="
M188.317368,447.454620 
	C186.494858,446.875793 185.052185,446.088837 183.592514,446.055878 
	C177.328705,445.914612 171.052734,446.157684 164.797821,445.882538 
	C163.200531,445.812256 161.664200,444.356842 160.099869,443.537811 
	C161.252701,442.408081 162.405533,441.278381 163.915573,439.958130 
	C175.409897,439.812408 186.587311,440.405853 197.661438,439.592834 
	C201.342484,439.322571 204.758469,435.442200 208.926682,433.223877 
	C211.075912,435.564209 212.594086,437.892944 214.085846,440.599976 
	C213.697311,442.963623 213.335205,444.948914 212.973099,446.934235 
	C212.566620,447.008820 212.160126,447.083374 211.108475,447.106476 
	C210.250793,447.137115 210.038300,447.219208 209.825790,447.301300 
	C209.825790,447.301300 209.385727,447.292969 209.136627,446.826355 
	C207.151276,444.263275 205.804550,444.249390 204.991470,447.088501 
	C203.924484,447.177124 202.857498,447.265778 201.342926,446.876221 
	C197.602081,446.699860 194.308807,447.001709 191.015533,447.303589 
	C190.240143,447.400177 189.464767,447.496796 188.317368,447.454620 
z"
      />
      <path
        fill="#5B6984"
        opacity="1.000000"
        stroke="none"
        d="
M250.945953,361.031769 
	C248.717529,360.270660 246.489120,359.509521 244.070023,357.972656 
	C243.946365,345.105011 244.013367,333.013092 244.080368,320.921173 
	C251.072128,322.454926 253.983093,325.960815 253.967377,332.879791 
	C253.946732,341.977875 253.905609,351.075928 253.451996,360.643219 
	C252.335800,361.085571 251.640869,361.058655 250.945953,361.031769 
z"
      />
      <path
        fill="#697B96"
        opacity="1.000000"
        stroke="none"
        d="
M199.343872,286.711792 
	C199.297607,285.858124 200.003342,284.955353 200.001602,284.053955 
	C199.975555,270.576172 199.856110,257.098541 199.476776,243.319901 
	C199.141495,238.946625 199.088104,234.874298 199.101196,230.334091 
	C203.614120,231.444199 206.105591,234.047501 205.989670,239.312408 
	C205.686676,253.072601 205.704758,266.846008 205.936096,280.608429 
	C206.015060,285.305939 204.636292,287.501068 199.343872,286.711792 
z"
      />
      <path
        fill="#6F7F97"
        opacity="1.000000"
        stroke="none"
        d="
M388.007996,679.119507 
	C387.682678,674.285461 387.678864,669.762817 387.700195,664.797852 
	C394.457581,663.801880 401.547821,662.483459 406.771149,668.174927 
	C409.540466,671.192444 411.245392,675.186768 413.517517,679.090515 
	C405.179352,679.437622 396.754425,679.434265 388.007996,679.119507 
z"
      />
      <path
        fill="#5B6984"
        opacity="1.000000"
        stroke="none"
        d="
M208.780579,477.710419 
	C198.732773,478.060425 188.792343,477.948639 178.377869,477.880127 
	C177.903839,474.481049 177.903839,471.038635 177.903839,467.097412 
	C187.818497,467.097412 197.261597,467.036469 206.700287,467.212463 
	C207.459091,467.226624 208.712418,468.808411 208.827316,469.770264 
	C209.121216,472.230347 208.901962,474.751740 208.780579,477.710419 
z"
      />
      <path
        fill="#6B839C"
        opacity="1.000000"
        stroke="none"
        d="
M213.329559,447.042664 
	C213.335205,444.948914 213.697311,442.963623 214.400269,440.388672 
	C216.978485,438.914581 219.215881,438.030090 221.921280,436.960632 
	C221.921280,430.512024 221.921280,423.727417 221.921280,416.942841 
	C222.387421,416.611176 222.853561,416.279541 223.975418,416.064087 
	C230.178391,416.549500 235.725647,416.918671 241.272903,417.287872 
	C237.564865,430.844513 224.693008,436.468109 216.339569,446.002533 
	C215.765686,446.657532 214.584671,446.780640 213.329559,447.042664 
z"
      />
      <path
        fill="#A9B7C4"
        opacity="1.000000"
        stroke="none"
        d="
M808.035095,415.938965 
	C812.032593,415.969421 816.031067,416.066193 820.027283,416.007599 
	C822.795715,415.967072 823.814880,417.092743 823.776123,419.924072 
	C823.694702,425.876709 823.944275,431.833923 823.732483,438.351440 
	C822.567810,438.970276 821.728577,439.026947 820.889404,439.083618 
	C817.093811,435.914398 813.144897,432.905731 809.577576,429.497559 
	C808.135864,428.120178 807.711121,425.738159 806.495361,424.049683 
	C804.881165,421.808075 802.945740,419.797729 801.438782,417.345154 
	C803.831177,416.647827 805.933167,416.293396 808.035095,415.938965 
z"
      />
      <path
        fill="#92DAF2"
        opacity="1.000000"
        stroke="none"
        d="
M648.002441,876.009766 
	C645.959351,876.007751 643.916260,876.005737 641.060974,876.004028 
	C639.500000,876.006104 638.751221,876.007996 638.002441,876.009766 
	C636.934448,876.007385 635.866455,876.005005 633.954224,876.002441 
	C630.740784,876.003174 628.371582,876.004028 626.002441,876.004883 
	C622.970398,876.003662 619.938293,876.002441 615.983521,876.001343 
	C591.041321,876.004211 567.021912,876.007019 543.002441,876.009766 
	C541.612183,876.007446 540.221924,876.005066 537.946533,876.002686 
	C530.725464,875.692322 524.389526,875.381958 518.235352,874.986328 
	C518.654785,874.760315 518.833313,874.565491 518.952698,874.316406 
	C530.016174,874.279602 541.079712,874.242737 552.694092,874.412354 
	C553.245056,874.618774 553.638611,874.661011 553.638611,874.661011 
	C553.638611,874.661011 553.934753,874.375671 553.934753,874.375671 
	C554.419739,874.310547 554.904663,874.245361 556.086426,874.490112 
	C562.825806,874.657227 568.868408,874.514343 574.911011,874.371460 
	C586.891052,874.296143 598.870972,874.181213 610.851074,874.154114 
	C633.493713,874.102966 656.136597,874.120117 678.779114,874.045471 
	C680.520691,874.039734 682.260498,873.534790 684.331604,873.015625 
	C685.176758,872.856628 685.691528,872.943848 686.573181,873.376587 
	C686.629883,874.478760 686.319641,875.235535 686.005188,875.995422 
	C686.000916,875.998596 685.991882,876.007324 685.991882,876.007324 
	C678.639648,876.006531 671.287354,876.005798 663.000488,876.005005 
	C657.378113,876.006531 652.690247,876.008118 648.002441,876.009766 
z"
      />
      <path
        fill="#A5C1D1"
        opacity="1.000000"
        stroke="none"
        d="
M241.639114,417.207153 
	C235.725647,416.918671 230.178391,416.549500 224.251434,415.973267 
	C225.960968,411.133881 229.081726,408.112274 233.876129,405.570892 
	C239.992340,402.328796 244.942337,396.886627 250.915787,392.369934 
	C252.277176,393.234009 253.117783,394.120911 253.958389,395.007843 
	C253.989822,402.070618 253.989822,402.070618 244.754959,403.318970 
	C244.754959,405.794312 245.109833,408.420074 244.648056,410.893341 
	C244.243530,413.059967 242.921875,415.055359 241.639114,417.207153 
z"
      />
      <path
        fill="#92DAF2"
        opacity="1.000000"
        stroke="none"
        d="
M379.002441,876.009766 
	C373.083435,875.677734 367.164429,875.345703 361.126953,874.640747 
	C383.601685,874.226685 406.194916,874.178223 428.788116,874.147095 
	C443.244354,874.127197 457.700684,874.132935 472.942932,874.446899 
	C477.128693,874.618164 480.528412,874.469604 483.928162,874.320984 
	C493.336121,874.265747 502.744080,874.210510 512.848755,874.442749 
	C514.347961,874.845093 515.150452,874.960022 515.747742,875.324951 
	C514.695862,875.719055 513.849182,875.863220 513.002441,876.007324 
	C505.313324,876.005859 497.624237,876.004395 488.997986,876.002380 
	C464.993073,876.000366 441.925079,875.958923 418.858032,876.082764 
	C417.226532,876.091492 415.601593,877.316956 413.973572,877.976685 
	C413.236542,877.978394 412.499542,877.980164 411.293701,877.586426 
	C409.328186,876.794800 407.835205,876.073059 406.334198,876.055786 
	C397.224640,875.951233 388.113220,876.007324 379.002441,876.009766 
z"
      />
      <path
        fill="#9CB7CB"
        opacity="1.000000"
        stroke="none"
        d="
M915.015015,270.039429 
	C921.842407,269.249420 923.199341,267.751129 923.201172,260.937256 
	C923.203918,250.808044 923.192322,240.678833 923.261475,230.056610 
	C927.466736,230.946671 929.319641,233.632965 929.181702,238.160812 
	C928.933472,246.310196 929.155090,254.472931 929.105774,262.629639 
	C929.060059,270.196594 929.017029,270.194519 921.216553,270.602356 
	C920.242676,270.653290 919.270508,270.735931 917.792908,270.839142 
	C917.631714,274.057983 917.476257,277.161346 916.870300,280.358398 
	C915.951477,276.981201 915.483276,273.510315 915.015015,270.039429 
z"
      />
      <path
        fill="#ABC6D5"
        opacity="1.000000"
        stroke="none"
        d="
M165.939087,300.657684 
	C161.469833,301.673065 157.058563,302.336090 152.150192,302.991699 
	C151.686829,301.976501 151.720551,300.968781 152.095322,299.300323 
	C152.244171,296.373322 152.051971,294.106995 151.859772,291.840698 
	C151.823029,291.164215 151.786270,290.487762 152.105591,289.399567 
	C152.973816,288.325653 153.485992,287.663452 153.999084,287.000610 
	C154.000000,287.000000 154.001373,286.998260 154.377563,286.992767 
	C156.151230,285.330292 157.548721,283.673340 158.946198,282.016388 
	C159.111328,283.624878 159.276459,285.233337 159.547516,287.873810 
	C163.787109,287.873810 167.976715,287.873810 172.607025,288.035461 
	C170.697510,292.233185 168.347290,296.269257 165.939087,300.657684 
z"
      />
      <path
        fill="#F6F7F8"
        opacity="1.000000"
        stroke="none"
        d="
M547.003418,695.878662 
	C546.554565,695.955078 546.361938,695.999573 546.208313,695.539429 
	C546.001770,690.641968 545.948853,686.204712 545.895935,681.767456 
	C547.809875,681.190063 549.693298,680.291382 551.644470,680.105957 
	C555.111877,679.776611 558.620361,679.879517 562.579468,679.847351 
	C563.070374,683.509644 563.020325,687.128540 563.138733,690.742004 
	C563.241455,693.876343 561.896484,695.156860 558.735291,695.162415 
	C554.973877,695.169067 551.213196,695.571350 547.003418,695.878662 
z"
      />
      <path
        fill="#A2C0D3"
        opacity="1.000000"
        stroke="none"
        d="
M151.206497,259.023499 
	C150.132065,258.695465 149.058746,258.095490 147.982986,258.091064 
	C144.925278,258.078491 143.973679,256.480591 144.088028,253.703461 
	C144.418701,245.672623 144.693909,237.639511 145.102417,229.143463 
	C150.738251,230.358414 153.567505,233.626999 153.059326,239.849716 
	C152.566284,245.887115 152.919708,251.993607 152.555237,258.528046 
	C151.874893,258.998718 151.540695,259.011108 151.206497,259.023499 
z"
      />
      <path
        fill="#A5C1D1"
        opacity="1.000000"
        stroke="none"
        d="
M243.765594,320.682983 
	C244.013367,333.013092 243.946365,345.105011 243.822266,357.673279 
	C242.119171,358.060822 240.473129,357.972046 237.922699,357.834534 
	C237.922699,345.477417 237.922699,333.255035 237.931854,320.570953 
	C239.777603,320.221100 241.614212,320.332916 243.765594,320.682983 
z"
      />
      <path
        fill="#D8E2E7"
        opacity="1.000000"
        stroke="none"
        d="
M545.438110,681.825317 
	C545.948853,686.204712 546.001770,690.641968 546.109497,695.544250 
	C540.991943,696.014771 535.819458,696.020386 530.233643,696.057251 
	C529.820251,691.657471 529.820251,687.226318 529.820251,681.883240 
	C535.135986,681.883240 540.058167,681.883240 545.438110,681.825317 
z"
      />
      <path
        fill="#ABC6D5"
        opacity="1.000000"
        stroke="none"
        d="
M544.659607,776.027222 
	C546.061035,775.817383 547.220154,775.883301 548.379333,775.884277 
	C555.119751,775.890015 558.039246,780.129578 554.977356,786.257019 
	C554.245422,787.721680 551.243286,788.036438 549.301941,788.916809 
	C547.621338,789.679016 545.964355,790.493164 543.864014,791.524963 
	C540.688049,790.405701 537.945007,789.045776 535.201965,787.685913 
	C536.900330,786.099182 538.598755,784.512451 540.637817,783.576660 
	C541.042786,785.138916 541.107239,786.050171 541.171631,786.961365 
	C541.457458,786.904419 541.743347,786.847473 542.029175,786.790527 
	C541.685181,785.669800 541.341248,784.549011 540.997192,783.428284 
	C542.137268,781.043152 543.277344,778.658020 544.659607,776.027222 
z"
      />
      <path
        fill="#92AABE"
        opacity="1.000000"
        stroke="none"
        d="
M160.014374,322.424103 
	C165.585938,325.331909 171.092087,328.693024 176.972137,332.098450 
	C179.325272,334.368408 181.304535,336.594086 183.445343,339.107239 
	C181.231644,339.906921 178.856400,340.419128 176.029144,340.990356 
	C172.610809,339.145477 169.856781,336.246887 166.631058,335.558380 
	C161.046936,334.366516 159.463364,331.396240 159.995636,326.316437 
	C160.114288,325.184143 159.971420,324.024475 160.014374,322.424103 
z"
      />
      <path
        fill="#9CB7CB"
        opacity="1.000000"
        stroke="none"
        d="
M151.186935,259.489807 
	C151.540695,259.011108 151.874893,258.998718 152.461716,258.987579 
	C160.594559,264.690948 159.435837,273.018219 159.040619,281.556366 
	C157.548721,283.673340 156.151230,285.330292 154.323547,286.854492 
	C153.807114,286.673035 153.720871,286.624390 153.634644,286.575745 
	C153.755814,286.717590 153.876984,286.859406 153.998169,287.001251 
	C153.485992,287.663452 152.973816,288.325653 152.117020,289.060791 
	C151.570724,279.407837 151.369049,269.681976 151.186935,259.489807 
z"
      />
      <path
        fill="#5B6883"
        opacity="1.000000"
        stroke="none"
        d="
M351.464233,614.905945 
	C347.750610,611.854919 344.428467,608.779114 341.051025,605.368042 
	C347.281860,604.713806 353.567078,604.283569 359.855560,604.228455 
	C360.576141,604.222168 361.830902,606.262634 361.943909,607.439941 
	C362.224945,610.367615 361.989410,613.344788 361.787720,616.750854 
	C358.361328,616.426819 355.108490,615.653992 351.464233,614.905945 
z"
      />
      <path
        fill="#8C9BAB"
        opacity="1.000000"
        stroke="none"
        d="
M838.755859,318.865143 
	C839.534973,314.515076 840.314148,310.165039 841.087219,305.356384 
	C847.279846,306.744263 848.658447,309.814697 847.541504,319.285675 
	C846.533203,327.835999 845.324890,329.221924 838.269287,329.000580 
	C838.264404,325.200134 838.510132,322.032654 838.755859,318.865143 
z"
      />
      <path
        fill="#E4E9EC"
        opacity="1.000000"
        stroke="none"
        d="
M238.668045,513.862305 
	C239.867233,516.140503 240.769073,518.203369 241.670929,520.266357 
	C238.845139,520.693115 235.751572,522.052551 233.254135,521.335571 
	C229.152512,520.158142 225.323364,517.816162 221.658600,515.519531 
	C220.948715,515.074646 221.517151,512.589844 221.859406,510.940613 
	C227.652649,511.501648 233.621933,509.069824 238.668045,513.862305 
z"
      />
      <path
        fill="#A9B7C4"
        opacity="1.000000"
        stroke="none"
        d="
M534.915283,787.806458 
	C537.945007,789.045776 540.688049,790.405701 543.797791,791.922852 
	C544.827698,797.855225 540.838623,800.877014 536.840820,803.460510 
	C534.078491,805.245728 531.322449,804.561218 530.618713,800.638855 
	C530.327637,799.015869 530.145935,797.373291 529.799744,795.333191 
	C531.332947,792.593506 532.980774,790.260254 534.915283,787.806458 
z"
      />
      <path
        fill="#BDCCD9"
        opacity="1.000000"
        stroke="none"
        d="
M824.811646,387.701843 
	C821.980042,388.312347 820.983948,387.115540 820.999817,384.297852 
	C821.086975,368.829590 820.909729,353.358856 821.140381,337.893677 
	C821.169556,335.938293 823.059937,334.010620 824.445923,332.009674 
	C824.815308,333.061646 824.826782,334.174042 824.351074,335.823486 
	C823.567383,337.076477 823.014404,337.791595 823.012146,338.508484 
	C822.965149,353.266907 822.948181,368.025726 823.053589,382.783569 
	C823.061035,383.835114 824.058105,384.879578 824.595093,385.927338 
	C824.689514,386.399048 824.783936,386.870789 824.811646,387.701843 
z"
      />
      <path
        fill="#ABC6D5"
        opacity="1.000000"
        stroke="none"
        d="
M914.574402,269.997345 
	C915.483276,273.510315 915.951477,276.981201 916.708130,280.726501 
	C917.037903,281.866394 917.079407,282.731873 916.672241,283.921844 
	C916.375854,284.220398 916.528198,284.194458 916.680542,284.168518 
	C916.435364,289.604034 912.154602,285.991699 909.533752,287.121124 
	C909.066772,281.653870 909.066772,276.213348 909.066772,270.313629 
	C910.950256,270.180420 912.541992,270.067841 914.574402,269.997345 
z"
      />
      <path
        fill="#A2AFBD"
        opacity="1.000000"
        stroke="none"
        d="
M880.505005,322.704224 
	C881.360291,323.045013 881.845459,323.487518 882.672241,323.970520 
	C883.410522,333.337372 883.410522,333.337372 875.654236,335.636658 
	C873.946716,333.188416 872.636475,330.710968 871.011597,328.042725 
	C870.790588,327.114288 870.884155,326.376617 871.080688,325.280975 
	C874.167297,324.217285 877.151062,323.511597 880.505005,322.704224 
z"
      />
      <path
        fill="#F4F6F8"
        opacity="1.000000"
        stroke="none"
        d="
M824.648682,385.466370 
	C824.058105,384.879578 823.061035,383.835114 823.053589,382.783569 
	C822.948181,368.025726 822.965149,353.266907 823.012146,338.508484 
	C823.014404,337.791595 823.567383,337.076477 824.218384,336.221313 
	C824.616089,352.389862 824.659180,368.697632 824.648682,385.466370 
z"
      />
      <path
        fill="#8C9BAB"
        opacity="1.000000"
        stroke="none"
        d="
M254.074280,394.550812 
	C253.117783,394.120911 252.277176,393.234009 251.163712,392.150085 
	C250.872711,381.955078 250.854584,371.957092 250.891205,361.495422 
	C251.640869,361.058655 252.335800,361.085571 253.378235,361.113220 
	C253.880539,372.107269 254.035355,383.100525 254.074280,394.550812 
z"
      />
      <path
        fill="#525E77"
        opacity="1.000000"
        stroke="none"
        d="
M313.158875,688.283386 
	C322.319397,686.086243 327.118866,693.191406 333.148926,698.309937 
	C328.516327,699.956177 324.922760,697.701172 321.466278,694.978760 
	C318.749695,692.839233 316.016418,690.720886 313.158875,688.283386 
z"
      />
      <path
        fill="#DAE3E8"
        opacity="1.000000"
        stroke="none"
        d="
M808.014160,415.564819 
	C805.933167,416.293396 803.831177,416.647827 801.319824,417.025879 
	C800.298889,413.003387 799.687378,408.957306 799.039185,404.455078 
	C805.254761,405.114349 806.566162,410.199036 808.014160,415.564819 
z"
      />
      <path
        fill="#55647E"
        opacity="1.000000"
        stroke="none"
        d="
M476.799438,736.111145 
	C478.211731,736.589478 479.295105,737.191589 480.683838,737.888367 
	C482.985809,740.208191 485.277344,742.240417 486.894989,744.713501 
	C488.471863,747.124207 489.359558,749.985596 490.252930,752.827393 
	C484.535583,748.159424 477.888123,744.299866 476.799438,736.111145 
z"
      />
      <path
        fill="#6B839C"
        opacity="1.000000"
        stroke="none"
        d="
M821.001343,439.402435 
	C821.728577,439.026947 822.567810,438.970276 823.811890,438.790100 
	C826.803101,438.816284 829.389282,438.965912 832.403687,439.174805 
	C833.588013,441.796051 834.344299,444.358063 835.116455,447.298523 
	C830.805542,449.074066 828.031372,446.514282 825.304626,443.825287 
	C823.912354,442.452332 822.510620,441.088959 821.001343,439.402435 
z"
      />
      <path
        fill="#CBD9E2"
        opacity="1.000000"
        stroke="none"
        d="
M838.411133,318.893738 
	C838.510132,322.032654 838.264404,325.200134 837.982788,328.806824 
	C834.531982,329.356812 831.117004,329.467682 827.293335,329.426086 
	C830.611938,325.823212 834.339172,322.372803 838.411133,318.893738 
z"
      />
      <path
        fill="#6F7F97"
        opacity="1.000000"
        stroke="none"
        d="
M852.961792,472.628510 
	C849.365845,472.391205 845.735779,471.733765 841.710815,471.146973 
	C840.573792,468.229980 841.683228,466.961853 844.801270,467.141510 
	C847.242432,467.282166 849.702942,467.087006 852.616699,467.108826 
	C853.028198,468.853882 852.977905,470.531128 852.961792,472.628510 
z"
      />
      <path
        fill="#7ED8F2"
        opacity="1.000000"
        stroke="none"
        d="
M374.000610,828.992981 
	C373.906586,829.348450 373.812561,829.703979 373.491211,830.566589 
	C372.988708,831.185974 372.704498,831.265869 372.181458,830.825439 
	C371.633698,829.892395 371.315643,829.447388 370.849304,828.698120 
	C370.280060,827.546875 369.431274,826.656067 369.512726,825.860535 
	C369.851349,822.553955 370.470428,819.276184 370.986572,815.987793 
	C374.158508,815.309998 374.915924,817.228943 374.977631,819.777954 
	C375.036652,822.215088 374.989960,824.654663 374.768127,827.657593 
	C374.281677,828.420227 374.099731,828.677246 374.000610,828.992981 
z"
      />
      <path
        fill="#563446"
        opacity="1.000000"
        stroke="none"
        d="
M494.447174,792.068848 
	C495.038300,791.107422 496.454651,789.135742 497.053497,789.358887 
	C501.394684,790.975891 502.099426,788.147034 503.041473,785.143372 
	C503.389099,784.034912 504.366486,783.123901 505.394745,782.063721 
	C506.392700,791.735840 506.127838,791.981934 494.447174,792.068848 
z"
      />
      <path
        fill="#7F91A9"
        opacity="1.000000"
        stroke="none"
        d="
M146.404755,435.192383 
	C148.316132,435.900330 150.333954,436.700500 151.831131,438.069885 
	C152.651489,438.820312 152.592850,440.531738 152.750305,442.136047 
	C150.478027,440.148834 148.386017,437.835419 146.404755,435.192383 
z"
      />
      <path
        fill="#97D7E3"
        opacity="1.000000"
        stroke="none"
        d="
M370.997559,829.002319 
	C371.315643,829.447388 371.633698,829.892395 371.965454,830.758606 
	C367.307556,831.415894 362.636017,831.652100 357.563599,831.979248 
	C356.966797,832.071411 356.770813,832.072510 356.285400,831.735840 
	C356.006195,830.937744 356.016418,830.477417 356.026672,830.017090 
	C356.026672,830.017090 356.513397,830.013611 357.216370,830.009155 
	C362.278778,829.670593 366.638153,829.336487 370.997559,829.002319 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M574.460999,874.264038 
	C568.868408,874.514343 562.825806,874.657227 556.444153,874.585388 
	C562.073730,874.299316 568.042358,874.227966 574.460999,874.264038 
z"
      />
      <path
        fill="#ABC6D5"
        opacity="1.000000"
        stroke="none"
        d="
M191.419006,447.433411 
	C194.308807,447.001709 197.602081,446.699860 200.952255,446.769470 
	C197.946930,447.281708 194.884689,447.422485 191.419006,447.433411 
z"
      />
      <path
        fill="#563446"
        opacity="1.000000"
        stroke="none"
        d="
M491.041260,801.575562 
	C487.666809,798.973694 488.536407,796.030640 491.502869,793.042969 
	C491.574493,795.694763 491.322723,798.424683 491.041260,801.575562 
z"
      />
      <path
        fill="#556580"
        opacity="1.000000"
        stroke="none"
        d="
M702.027832,365.530151 
	C701.783325,362.185791 701.596191,358.384888 701.698730,354.294922 
	C702.020569,357.695099 702.052856,361.384399 702.027832,365.530151 
z"
      />
      <path
        fill="#DAE3E8"
        opacity="1.000000"
        stroke="none"
        d="
M135.766907,309.404053 
	C137.170166,309.030396 138.486145,309.030396 139.643112,309.030396 
	C139.428406,310.017700 139.214371,311.001831 139.026917,312.269897 
	C137.928864,311.628448 136.804230,310.703094 135.766907,309.404053 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M483.487396,874.215515 
	C480.528412,874.469604 477.128693,874.618164 473.407654,874.545166 
	C476.406464,874.252380 479.726562,874.181213 483.487396,874.215515 
z"
      />
      <path
        fill="#556580"
        opacity="1.000000"
        stroke="none"
        d="
M702.012939,376.542542 
	C701.776672,374.506866 701.594238,372.031677 701.687439,369.286865 
	C701.997681,371.379181 702.032227,373.741119 702.012939,376.542542 
z"
      />
      <path
        fill="#ABC6D5"
        opacity="1.000000"
        stroke="none"
        d="
M205.385529,447.190918 
	C205.804550,444.249390 207.151276,444.263275 208.947571,446.713684 
	C207.931610,447.142853 206.855591,447.218109 205.385529,447.190918 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M515.952881,875.074951 
	C515.150452,874.960022 514.347961,874.845093 513.314819,874.532410 
	C514.767151,874.258301 516.450195,874.182007 518.542969,874.211060 
	C518.833313,874.565491 518.654785,874.760315 517.884888,874.964722 
	C516.886047,875.044006 516.419495,875.059448 515.952881,875.074951 
z"
      />
      <path
        fill="#553C4F"
        opacity="1.000000"
        stroke="none"
        d="
M473.415100,820.176514 
	C473.840088,819.514221 474.577148,818.771667 475.688110,818.038879 
	C475.283752,818.731201 474.505463,819.413757 473.415100,820.176514 
z"
      />
      <path
        fill="#F4F6F8"
        opacity="1.000000"
        stroke="none"
        d="
M175.937164,344.335876 
	C176.160095,344.575684 176.305481,345.145416 176.222137,345.857971 
	C175.948807,345.555786 175.904205,345.110840 175.937164,344.335876 
z"
      />
      <path
        fill="#9CB7CB"
        opacity="1.000000"
        stroke="none"
        d="
M916.660156,284.151733 
	C916.528198,284.194458 916.375854,284.220398 916.452576,284.167603 
	C916.681641,284.088898 916.639709,284.134918 916.660156,284.151733 
z"
      />
      <path
        fill="#553C4F"
        opacity="1.000000"
        stroke="none"
        d="
M476.274017,817.957275 
	C476.250763,817.780579 476.473450,817.498047 476.965088,817.141846 
	C476.996033,817.329224 476.757996,817.590271 476.274017,817.957275 
z"
      />
      <path
        fill="#5B6984"
        opacity="1.000000"
        stroke="none"
        d="
M209.967361,447.445831 
	C210.038300,447.219208 210.250793,447.137115 210.798126,447.122925 
	C210.791626,447.324005 210.450287,447.457184 209.967361,447.445831 
z"
      />
      <path
        fill="#7ED7F1"
        opacity="1.000000"
        stroke="none"
        d="
M685.504028,831.504883 
	C685.660095,831.298767 686.031799,831.298035 686.636658,831.452209 
	C686.486389,831.641541 686.103088,831.675903 685.504028,831.504883 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M553.716919,874.289795 
	C553.934753,874.375671 553.638611,874.661011 553.638611,874.661011 
	C553.638611,874.661011 553.245056,874.618774 553.158325,874.506958 
	C553.071594,874.395081 553.499084,874.203918 553.716919,874.289795 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M430.922180,890.623413 
	C390.903107,890.995117 350.805573,891.013550 310.708191,890.947449 
	C307.756927,890.942627 304.806671,890.328369 301.426758,889.991333 
	C302.080841,889.076538 303.513550,887.171143 304.194336,887.409851 
	C312.969727,890.487305 321.775879,889.014893 330.615753,888.098511 
	C335.709442,887.570496 340.831360,887.137146 345.947876,887.038025 
	C367.769745,886.615479 389.597687,885.993225 411.415833,886.208679 
	C417.904480,886.272766 424.368469,888.832886 430.922180,890.623413 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M286.994415,824.982910 
	C291.174103,823.234497 294.740082,820.820984 296.689209,816.167847 
	C295.739319,819.102234 294.575989,822.234253 293.168152,825.252319 
	C292.702240,826.251099 291.480042,826.867310 290.818878,827.809570 
	C286.511292,833.947998 286.272247,833.945251 278.762634,831.904602 
	C277.063446,831.442749 275.070557,832.138611 273.210175,832.179382 
	C267.129852,832.312744 261.047821,832.367554 254.967285,832.493774 
	C245.708252,832.685852 236.449936,832.912415 227.191315,833.124390 
	C222.006836,839.487732 214.313980,835.592163 208.076614,837.718872 
	C207.301666,837.983032 206.135406,837.099548 205.075760,836.375122 
	C209.897400,835.613892 208.943878,831.947327 209.438217,828.425598 
	C214.619492,828.066467 219.371124,828.117188 224.122131,828.202515 
	C237.202423,828.437256 250.281769,828.774597 263.363007,828.876892 
	C267.348083,828.908081 271.383270,828.578186 275.313202,827.921143 
	C279.260376,827.261292 283.104889,825.987610 286.994415,824.982910 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M497.959106,890.611572 
	C476.640747,890.666992 455.279419,890.346191 433.471008,890.031494 
	C432.794464,886.775269 435.018524,886.051697 437.507843,886.043762 
	C456.079590,885.984375 474.653198,885.905884 493.220764,886.189819 
	C494.806152,886.213989 496.351959,888.825684 497.959106,890.611572 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M572.064575,890.650513 
	C553.312439,891.001038 534.624878,891.002014 515.468628,890.999084 
	C518.704834,889.333862 522.342346,886.448059 526.127014,886.240234 
	C537.671936,885.606384 549.275879,885.868042 560.851990,886.064941 
	C565.708435,886.147583 570.552734,886.943298 576.191406,887.491577 
	C574.338257,888.773132 573.233704,889.537048 572.064575,890.650513 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M153.427048,846.988708 
	C156.077545,845.475342 158.477310,843.786560 158.132111,839.571533 
	C157.881012,836.505554 159.391327,834.721130 163.309753,834.869141 
	C175.666367,835.336182 188.043106,835.270020 200.705948,835.707581 
	C199.876709,836.669189 198.791962,837.829102 197.624420,837.919434 
	C188.076508,838.658630 178.513687,839.206543 168.953522,839.781372 
	C168.315598,839.819641 167.660873,839.611450 167.016418,839.503357 
	C160.255676,838.369507 159.423141,839.207886 160.612320,846.994873 
	C158.296234,846.994873 156.080399,846.994873 153.427048,846.988708 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M370.849304,828.698120 
	C366.638153,829.336487 362.278778,829.670593 357.459686,830.003601 
	C356.275665,827.974915 355.551331,825.947388 354.639618,823.395386 
	C359.127594,823.065369 363.823853,822.719971 368.520142,822.374634 
	C368.555573,821.555298 368.591003,820.735962 368.626434,819.916626 
	C365.643066,819.611084 362.623657,819.494141 359.689056,818.933472 
	C357.741150,818.561340 355.914551,817.554260 354.018066,816.418152 
	C359.353119,816.000000 364.703766,815.995056 370.520508,815.988953 
	C370.470428,819.276184 369.851349,822.553955 369.512726,825.860535 
	C369.431274,826.656067 370.280060,827.546875 370.849304,828.698120 
M358.834473,827.263428 
	C358.780853,827.669739 358.727203,828.076050 358.673553,828.482361 
	C359.194733,828.287781 359.715942,828.093201 360.237152,827.898621 
	C359.946808,827.635986 359.656494,827.373291 358.834473,827.263428 
z"
      />
      <path
        fill="#7ED7F1"
        opacity="1.000000"
        stroke="none"
        d="
M198.996338,873.977905 
	C187.195480,873.852783 175.394638,873.727661 163.293945,873.313049 
	C163.165512,872.462524 163.336914,871.901489 164.215027,871.170227 
	C168.614288,871.000000 172.306839,871.000000 175.999390,871.000000 
	C182.687241,871.000000 189.375076,871.000000 196.874237,871.053467 
	C198.122467,872.063904 198.559402,873.020935 198.996338,873.977905 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M352.288147,882.941162 
	C346.780457,882.982727 341.704956,882.982727 336.215607,882.982727 
	C338.357391,879.838684 347.792328,878.375366 356.362732,879.624756 
	C354.797974,881.031616 353.759186,881.965637 352.288147,882.941162 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M175.996979,870.578491 
	C172.306839,871.000000 168.614288,871.000000 164.461182,871.000000 
	C163.809036,869.898987 163.617447,868.798035 163.307175,867.014954 
	C166.809937,867.014954 170.073837,866.766052 173.258484,867.159241 
	C174.278320,867.285156 175.088470,869.109558 175.996979,870.578491 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M609.000061,890.594360 
	C604.304321,890.337646 599.606140,889.681580 594.465942,889.032288 
	C597.192688,885.264954 601.715820,886.640930 605.666626,886.318359 
	C606.664795,886.236816 607.881409,888.830627 609.000061,890.594360 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M156.151352,860.707153 
	C154.241531,861.342896 152.503510,861.660156 150.381531,861.971191 
	C149.186417,856.317932 149.182175,856.360596 155.064682,856.946106 
	C156.792007,857.117981 158.550919,856.972595 160.551804,858.028564 
	C159.142258,858.815247 157.732697,859.601929 156.151352,860.707153 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M213.001251,882.552917 
	C206.647263,882.787781 209.827332,877.840942 208.965454,874.465332 
	C215.389313,874.180420 212.172638,879.160095 213.001251,882.552917 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M313.780579,870.184204 
	C316.762848,870.980530 319.472290,871.989624 322.587952,873.019165 
	C321.022705,873.980103 318.759369,875.991882 317.162415,875.556885 
	C315.592255,875.129333 314.698364,872.218262 313.780579,870.184204 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M222.959930,882.621460 
	C221.286133,882.992981 219.570770,882.995728 217.426636,882.993164 
	C217.007263,882.341431 216.809830,881.594055 217.066452,881.068115 
	C217.725082,879.718323 218.585251,878.466797 219.364838,877.176025 
	C220.331909,878.261353 221.354370,879.303955 222.239838,880.452209 
	C222.614365,880.937927 222.700912,881.645813 222.959930,882.621460 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M199.425278,873.984253 
	C198.559402,873.020935 198.122467,872.063904 197.342773,871.053467 
	C201.207825,866.321655 202.571487,871.511719 204.961472,873.635254 
	C203.286331,873.988708 201.570282,873.989624 199.425278,873.984253 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M355.683136,829.982910 
	C356.016418,830.477417 356.006195,830.937744 355.991577,831.744751 
	C353.284943,832.388123 350.582733,832.684692 347.444031,832.955811 
	C349.784912,831.936462 352.562286,830.942566 355.683136,829.982910 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M589.910889,890.609070 
	C586.638733,891.001831 583.277405,891.003662 579.458069,891.002747 
	C582.359253,887.306519 586.088745,888.737976 589.910889,890.609070 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M328.092957,873.417480 
	C330.703064,873.021667 333.400055,873.007141 336.545898,873.013428 
	C334.340271,876.562134 331.265686,875.245789 328.092957,873.417480 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M158.390289,864.992432 
	C157.694794,864.216675 157.370682,863.431335 157.015289,862.327515 
	C158.255142,862.462036 159.526260,862.914978 160.797363,863.367920 
	C160.118790,863.906250 159.440247,864.444580 158.390289,864.992432 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M338.042053,816.301514 
	C338.446869,816.000610 338.889252,815.997192 339.663391,815.999329 
	C339.762665,816.520569 339.530182,817.036255 339.297668,817.551941 
	C338.891693,817.234314 338.485687,816.916687 338.042053,816.301514 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M359.075592,879.690186 
	C358.529816,879.721924 358.142609,879.594910 357.755432,879.467834 
	C357.861115,879.361816 357.990448,879.150024 358.068481,879.167480 
	C358.464355,879.256042 358.846924,879.404236 359.075592,879.690186 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M127.790344,860.730896 
	C127.134392,860.837402 126.271553,860.683899 125.204773,860.266846 
	C125.861717,860.159119 126.722588,860.315002 127.790344,860.730896 
z"
      />
      <path
        fill="#7ED8F2"
        opacity="1.000000"
        stroke="none"
        d="
M849.036255,838.640869 
	C840.540466,839.000000 832.078918,839.106201 823.622192,838.952148 
	C819.780579,838.882202 817.660095,839.887390 816.634705,844.111023 
	C815.686768,848.015442 813.180664,851.534180 811.441345,855.260376 
	C811.105896,855.979004 811.082886,856.866821 811.017944,857.684631 
	C810.471802,864.559509 810.122681,864.879456 802.423828,865.000000 
	C800.345520,864.343567 798.691040,863.687073 797.036560,863.030579 
	C797.189270,862.288513 797.341980,861.546448 797.494751,860.804382 
	C799.864929,860.955200 802.235107,861.106079 805.372070,861.305664 
	C806.657104,858.743164 808.285706,855.401550 810.002441,852.105774 
	C811.130859,849.939514 812.313416,847.794067 813.601074,845.719543 
	C815.908142,842.002563 815.042480,840.167175 810.693909,840.023865 
	C807.368164,839.914246 804.035828,839.977295 800.706726,840.006714 
	C797.414856,840.035828 794.301392,840.494324 792.329102,836.448181 
	C791.600647,834.953552 788.235229,834.744385 784.510376,833.374512 
	C787.343506,832.353882 789.143555,831.012756 790.106079,831.462830 
	C803.861938,837.894958 818.222656,833.229614 832.263611,833.964050 
	C836.746155,834.198608 841.255371,833.890076 845.743591,834.061462 
	C848.228210,834.156311 850.587402,834.813416 849.036255,838.640869 
z"
      />
      <path
        fill="#7BD9F3"
        opacity="1.000000"
        stroke="none"
        d="
M856.812622,837.270752 
	C857.756470,836.998352 858.512878,836.996704 859.648315,836.987793 
	C861.406128,836.981628 862.785095,836.982788 864.872437,837.283203 
	C875.424316,837.439453 885.268372,837.181580 895.110657,837.238831 
	C897.401611,837.252136 899.686340,838.337402 902.514709,839.069336 
	C900.727600,841.067932 899.863770,842.033936 899.000000,843.000000 
	C891.840515,842.635132 884.682007,842.247620 877.521179,841.912231 
	C870.943848,841.604126 864.354004,841.485413 857.791199,840.994690 
	C855.822449,840.847534 853.972168,839.717224 856.812622,837.270752 
z"
      />
      <path
        fill="#7BD9F3"
        opacity="1.000000"
        stroke="none"
        d="
M784.986267,823.997559 
	C786.712585,824.327026 788.437561,824.933655 790.165527,824.942383 
	C805.111145,825.018250 820.057312,824.973206 835.003296,825.004456 
	C836.809387,825.008179 838.614929,825.251282 840.726196,825.670227 
	C837.678650,826.351379 834.334717,826.939880 830.970581,827.096069 
	C825.517395,827.349243 820.050964,827.318787 814.044189,827.704163 
	C813.331299,828.000977 813.164429,828.001709 812.748779,828.001953 
	C812.500000,828.001526 812.002441,828.002441 811.752075,828.001282 
	C811.334778,827.999268 811.167908,827.998413 811.001038,827.997559 
	C802.331421,826.058655 793.754822,826.543701 785.218140,828.865906 
	C783.737854,829.268555 782.139771,829.237976 780.596069,829.407532 
	C780.627747,827.840881 780.659485,826.274292 781.244446,824.353821 
	C782.860596,823.999207 783.923401,823.998413 784.986267,823.997559 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M899.000732,843.375732 
	C899.863770,842.033936 900.727600,841.067932 902.514709,839.069336 
	C899.686340,838.337402 897.401611,837.252136 895.110657,837.238831 
	C885.268372,837.181580 875.424316,837.439453 865.286865,837.283325 
	C866.276611,836.322937 867.557190,835.102539 868.844788,835.095154 
	C875.228027,835.058350 881.612488,835.435303 887.997803,835.476379 
	C891.740051,835.500488 895.504700,835.353638 899.223022,834.949890 
	C903.643005,834.469849 905.812012,835.868469 905.034058,840.603271 
	C904.849060,841.729431 905.009949,842.912292 905.015625,844.524780 
	C903.876404,845.559204 902.733276,846.137878 901.295044,846.859497 
	C900.333862,845.918823 899.667725,844.835205 899.000732,843.375732 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M812.997559,828.002441 
	C813.164429,828.001709 813.331299,828.000977 813.748535,827.998901 
	C818.034241,828.331665 822.065063,828.748108 826.106567,828.971313 
	C828.833740,829.121887 831.575989,829.000000 835.138184,829.000000 
	C830.383179,832.831543 799.775146,833.101440 795.910217,829.396667 
	C801.139832,829.105042 805.776855,828.846436 810.707458,828.292725 
	C811.167908,827.998413 811.334778,827.999268 811.840576,828.166687 
	C812.452209,828.222961 812.724854,828.112671 812.997559,828.002441 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M799.958496,851.695801 
	C797.245544,851.403870 794.586670,850.488708 791.851440,850.005310 
	C789.930847,849.665771 787.906311,849.914246 785.468018,849.837646 
	C786.243774,848.533936 787.294434,846.941040 788.773682,846.162231 
	C789.969116,845.532898 791.710327,846.042664 793.158875,845.774048 
	C799.715271,844.558044 799.911743,844.685547 799.958496,851.695801 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M774.992554,848.631531 
	C773.611450,849.061829 772.220459,849.067566 770.411926,849.066284 
	C770.005981,845.994690 769.808594,842.908936 770.116577,839.874451 
	C770.272461,838.338623 771.341492,836.895325 771.997681,835.410217 
	C772.664490,836.729675 773.661865,837.985840 773.929260,839.381775 
	C774.485291,842.284302 774.654480,845.260864 774.992554,848.631531 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M784.836731,823.693848 
	C783.923401,823.998413 782.860596,823.999207 781.398865,824.000000 
	C779.750854,824.493408 778.490479,824.960693 777.256104,825.488586 
	C776.286926,825.903198 775.381042,826.514709 774.378662,826.770752 
	C774.098389,826.842346 773.171204,825.636353 773.224731,825.570129 
	C773.975037,824.643372 774.766541,823.712158 775.707031,822.988525 
	C776.562805,822.330200 777.611633,821.922791 778.150085,821.633301 
	C776.995605,819.851257 775.999634,818.313843 775.003052,816.389526 
	C778.230652,818.465149 781.458923,820.927673 784.836731,823.693848 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M767.003235,816.422852 
	C767.748962,815.997986 768.493103,815.992859 769.608765,815.986572 
	C770.632507,816.847656 771.966858,817.884338 771.799988,818.537354 
	C771.409241,820.066650 770.299072,821.412170 769.480103,822.832031 
	C768.655273,821.650879 767.693726,820.537415 767.062317,819.260620 
	C766.738098,818.604980 767.002930,817.657959 767.003235,816.422852 
z"
      />
      <path
        fill="#7ED8F2"
        opacity="1.000000"
        stroke="none"
        d="
M789.473877,863.358398 
	C788.624084,863.940125 787.626404,864.205811 786.628662,864.471497 
	C786.562500,863.893494 786.496399,863.315491 786.430298,862.737488 
	C787.395508,862.839050 788.360718,862.940674 789.473877,863.358398 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M853.658569,831.006348 
	C854.000305,831.443726 854.003113,831.885010 854.006226,832.657043 
	C853.150757,832.826843 852.294922,832.665894 851.439148,832.505005 
	C852.065979,832.006775 852.692810,831.508545 853.658569,831.006348 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M765.120483,834.906372 
	C764.309998,834.584961 763.755859,834.086548 763.078369,833.333374 
	C765.122925,831.632568 765.687012,832.539612 765.120483,834.906372 
z"
      />
      <path
        fill="#7BD9F3"
        opacity="1.000000"
        stroke="none"
        d="
M783.523376,839.696411 
	C783.529297,840.448547 783.324768,840.982239 783.120239,841.515991 
	C782.846802,841.381653 782.573364,841.247314 782.299927,841.112915 
	C782.637573,840.567932 782.975281,840.022949 783.523376,839.696411 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M766.967163,848.735168 
	C766.366394,848.593689 765.725708,848.129395 765.070190,847.335754 
	C765.679443,847.475037 766.303345,847.943726 766.967163,848.735168 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M890.017395,873.593811 
	C884.648071,873.982971 879.288757,873.987854 873.461060,873.983154 
	C872.334778,869.463013 874.117981,867.679199 878.804871,867.865234 
	C883.063660,868.034302 887.355652,867.148376 891.636597,867.108154 
	C892.952209,867.095764 894.279480,868.336853 895.601440,869.004028 
	C894.449524,869.678833 893.237183,870.271057 892.165588,871.055481 
	C891.359314,871.645691 890.733521,872.482483 890.017395,873.593811 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M864.940063,873.615723 
	C857.062317,871.957886 856.530334,872.263367 853.283630,880.774597 
	C853.763977,877.664124 854.397888,874.289917 855.388733,871.024048 
	C855.543152,870.514893 857.471313,870.057739 858.325500,870.341309 
	C860.582397,871.090393 862.700012,872.259460 864.940063,873.615723 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M936.615601,855.007690 
	C937.002808,855.758972 937.002380,856.505676 936.996094,857.625854 
	C936.828613,857.999451 936.652283,858.042542 936.507507,857.993225 
	C934.454529,857.293762 932.405762,856.581848 930.356018,855.872925 
	C932.313293,855.583008 934.270630,855.293091 936.615601,855.007690 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M908.797302,860.757507 
	C907.997986,862.437134 906.993591,863.866821 905.970154,865.653687 
	C905.016785,865.138733 904.082397,864.266541 903.147949,863.394287 
	C904.962708,862.432068 906.777466,861.469849 908.797302,860.757507 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M904.602295,871.999756 
	C903.161682,876.368591 900.045959,871.467041 897.409058,873.002686 
	C898.845825,868.721741 901.785522,872.203369 904.602295,871.999756 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M913.001221,852.001221 
	C912.276855,852.970825 911.551208,853.939209 910.825562,854.907593 
	C910.284790,854.444214 909.744019,853.980774 909.203308,853.517456 
	C910.206726,853.012329 911.210144,852.507263 912.605530,851.999878 
	C912.997559,851.997559 913.000000,852.000000 913.001221,852.001221 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M907.034790,846.369385 
	C908.094666,846.346375 909.196533,846.672302 910.648560,847.005798 
	C909.429260,850.399536 908.215088,849.063660 907.034790,846.369385 
z"
      />
      <path
        fill="#7ED8F2"
        opacity="1.000000"
        stroke="none"
        d="
M753.424622,890.000244 
	C751.437866,889.665771 749.878906,889.063660 748.313049,889.045105 
	C740.150879,888.948364 731.976135,889.261353 723.826721,888.924622 
	C716.073242,888.604309 708.354736,887.295227 700.603699,887.099243 
	C693.922852,886.930359 687.188049,887.438904 680.531555,888.154785 
	C673.220154,888.941162 671.659790,887.562988 673.027405,880.361267 
	C673.146362,879.734741 673.350037,879.124268 673.575256,878.277710 
	C676.647766,878.277710 679.602722,878.277710 682.587219,878.277710 
	C682.731995,879.164368 682.725159,881.138794 683.116577,881.221191 
	C690.570251,882.789978 698.075745,883.941650 706.077454,881.140930 
	C704.666382,880.435486 703.275146,879.179810 701.840576,879.128357 
	C697.619019,878.976807 693.320801,878.922852 689.168945,879.573608 
	C686.321899,880.019836 685.956909,878.981689 685.996277,876.403198 
	C685.991882,876.007324 686.000916,875.998596 686.347656,875.993408 
	C688.755432,875.460022 690.777283,873.787964 692.787231,876.480591 
	C693.254272,877.106262 695.268616,876.769653 696.524170,876.574524 
	C699.234558,876.153259 701.901428,875.171875 704.609802,875.073242 
	C710.925537,874.843506 717.259216,875.120239 723.576416,874.911011 
	C725.067322,874.861572 726.521484,873.703247 727.992615,873.055054 
	C733.352478,873.040100 738.712341,873.025085 744.624390,873.404297 
	C747.964417,875.381592 750.710327,876.540161 752.995728,873.027710 
	C756.692749,872.665527 760.389771,872.303284 764.499512,871.905151 
	C770.095093,876.240723 770.059143,877.384949 764.941711,882.087891 
	C764.068176,882.890625 763.643311,884.181641 763.007446,885.617432 
	C760.838440,889.346985 757.733215,890.562439 753.424622,890.000244 
M753.697388,882.566223 
	C754.734924,881.747314 755.772461,880.928406 756.809998,880.109497 
	C756.151245,879.475159 755.492432,878.840759 754.833679,878.206421 
	C754.299133,879.462524 753.764526,880.718628 753.697388,882.566223 
z"
      />
      <path
        fill="#7ED8F2"
        opacity="1.000000"
        stroke="none"
        d="
M286.526306,824.984741 
	C283.104889,825.987610 279.260376,827.261292 275.313202,827.921143 
	C271.383270,828.578186 267.348083,828.908081 263.363007,828.876892 
	C250.281769,828.774597 237.202423,828.437256 224.122131,828.202515 
	C219.371124,828.117188 214.619492,828.066467 209.446655,828.000000 
	C208.809082,824.049072 211.759537,825.020813 213.870270,825.017029 
	C237.932861,824.973633 261.995575,824.987793 286.526306,824.984741 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M374.216034,828.886780 
	C374.099731,828.677246 374.281677,828.420227 374.759399,828.113647 
	C374.881714,828.326416 374.701416,828.584778 374.216034,828.886780 
z"
      />
      <path
        fill="#7ED8F2"
        opacity="1.000000"
        stroke="none"
        d="
M125.723251,848.199158 
	C126.914124,848.447998 127.845383,848.904541 128.776642,849.361023 
	C127.769867,849.905334 126.763069,850.449707 125.365883,850.990112 
	C125.138206,850.126404 125.300934,849.266602 125.723251,848.199158 
z"
      />
      <path
        fill="#A2AFBD"
        opacity="1.000000"
        stroke="none"
        d="
M687.627808,245.102005 
	C688.377380,271.456116 688.656067,297.792877 688.920593,324.129822 
	C688.960510,328.107788 688.934387,332.086456 688.974121,336.532410 
	C689.009399,341.459747 689.009399,345.919464 689.009399,351.866547 
	C680.869507,344.992310 673.783875,339.008423 666.382690,332.463440 
	C666.072083,328.764099 665.843750,325.603912 666.137207,322.492889 
	C666.569458,317.910980 664.396606,316.558472 660.290894,317.080780 
	C657.846619,317.391724 655.400391,317.687225 652.982971,317.988586 
	C653.010864,317.987488 653.010010,318.043304 652.979126,317.609131 
	C652.646851,313.743958 652.614197,310.255371 651.898499,306.913086 
	C651.648193,305.744049 649.827026,304.307526 648.531311,304.078461 
	C644.509766,303.367523 640.396057,303.177673 636.551880,302.387848 
	C636.840637,296.393982 636.895569,290.797913 636.971069,284.721741 
	C636.989502,280.743866 636.997681,277.246033 636.983154,273.748230 
	C636.955261,267.050110 636.949890,267.039032 630.402100,267.037598 
	C548.119385,267.019836 465.836639,267.003754 383.553894,267.002106 
	C381.800049,267.002075 380.046173,267.178223 378.081970,267.283447 
	C378.081970,273.683685 378.081970,279.493622 378.106201,285.750885 
	C378.091705,291.445251 378.053009,296.692322 378.072052,302.408966 
	C378.097595,307.693909 378.065369,312.509247 378.116486,317.511902 
	C378.199829,317.699219 377.943024,318.018951 377.537659,317.957153 
	C374.672455,317.648407 372.204956,317.134857 369.754242,317.205322 
	C362.800293,317.405365 362.246246,318.091187 362.175812,325.117249 
	C362.145935,328.097931 362.084015,331.078278 361.568146,334.041809 
	C358.768250,334.008789 356.411774,334.203461 354.111115,333.929626 
	C350.213257,333.465607 348.931305,334.791687 348.994293,338.864075 
	C349.233734,354.348083 349.090576,369.837982 349.094910,385.325684 
	C349.098724,398.983215 349.101349,398.983215 363.617950,397.651215 
	C368.773346,397.804626 373.480591,397.880493 378.107910,397.944946 
	C378.027985,397.933533 378.068787,397.777313 378.071838,398.263489 
	C378.311493,403.961395 378.548035,409.173157 378.732239,414.576508 
	C378.668732,415.050201 378.762268,415.291687 378.666931,415.849731 
	C378.268799,424.117462 378.164276,432.028046 378.016602,440.408508 
	C378.001343,446.262909 378.029236,451.647461 378.037109,457.497375 
	C378.023926,465.961853 378.030701,473.960999 377.996765,482.429810 
	C377.988251,490.617096 378.020355,498.334656 378.034546,506.496277 
	C378.022400,509.614929 378.028198,512.289551 377.995392,515.370178 
	C377.982544,516.862549 378.008331,517.948792 378.019165,519.496094 
	C378.021393,524.284851 378.038605,528.612671 378.022888,533.289246 
	C378.008636,534.104126 378.027283,534.570190 378.105774,535.249084 
	C378.341339,535.730774 378.589783,535.868835 379.382568,535.929565 
	C463.559479,535.986084 547.264771,535.996338 630.970093,535.945190 
	C632.904846,535.944031 634.839172,535.250854 636.773743,534.880432 
	C641.774780,535.230530 646.777649,535.766113 651.774780,535.717102 
	C652.470093,535.710327 653.615417,532.856323 653.723999,531.263855 
	C654.003235,527.166870 653.817383,523.038147 653.817383,517.987427 
	C658.867920,517.987427 663.440308,517.987427 668.690735,518.225464 
	C670.529907,518.189331 671.868042,518.195129 672.821350,517.592346 
	C676.815308,515.067017 679.272644,515.804565 681.470947,520.464294 
	C680.429504,520.947021 678.966187,521.159851 678.444946,521.975098 
	C677.647034,523.222961 677.091187,524.922974 677.221008,526.359802 
	C677.304443,527.283813 678.835388,528.847473 679.659851,528.811279 
	C685.776001,528.542908 686.304443,532.307678 686.024353,536.938293 
	C685.884644,539.248840 685.672485,541.631775 686.064819,543.882385 
	C687.030029,549.419434 683.835388,550.576660 679.396240,551.063965 
	C677.549805,551.266602 675.792542,552.282715 673.572632,552.966370 
	C670.437439,552.784912 669.852173,554.284912 669.928467,556.663269 
	C670.060303,560.773376 669.984863,564.890137 669.996338,569.004150 
	C613.860291,569.301025 557.724487,569.704041 501.588043,569.862488 
	C453.596954,569.997864 405.605103,569.855408 357.613586,569.820190 
	C355.957642,569.818970 354.301819,569.648621 352.312683,568.833862 
	C351.962677,564.483093 351.780090,560.846375 351.979065,557.230774 
	C352.153778,554.055420 351.099792,552.771362 347.789764,552.935669 
	C342.854401,553.180786 337.900330,553.049194 332.954224,553.077881 
	C332.273163,551.105347 331.003998,549.134521 330.998169,547.160034 
	C330.889160,510.175293 330.944214,473.190125 330.936371,436.205048 
	C330.923431,374.896545 330.890198,313.588043 330.865692,252.279541 
	C330.864838,250.140137 330.865570,248.000732 330.865570,245.276489 
	C338.213898,245.276489 345.136597,245.276489 352.834839,245.276489 
	C352.834839,239.023712 352.834839,233.319778 352.834839,227.258759 
	C458.814148,227.258759 564.013062,227.258759 670.083984,227.258759 
	C670.083984,231.771088 670.270935,236.208130 670.025208,240.621109 
	C669.826538,244.190079 671.263916,245.295593 674.659851,245.120972 
	C678.813660,244.907394 682.987305,245.079422 687.627808,245.102005 
z"
      />
      <path
        fill="#D8E2E7"
        opacity="1.000000"
        stroke="none"
        d="
M332.971619,553.519409 
	C337.900330,553.049194 342.854401,553.180786 347.789764,552.935669 
	C351.099792,552.771362 352.153778,554.055420 351.979065,557.230774 
	C351.780090,560.846375 351.962677,564.483093 351.987854,568.582275 
	C346.297363,569.088928 340.598450,569.123901 334.440002,569.052124 
	C333.649994,563.950562 333.319519,558.955750 332.971619,553.519409 
z"
      />
      <path
        fill="#D8E2E7"
        opacity="1.000000"
        stroke="none"
        d="
M378.034119,519.035095 
	C378.008331,517.948792 377.982544,516.862549 378.284363,515.104675 
	C378.425446,511.639496 378.238953,508.845856 378.052460,506.052246 
	C378.020355,498.334656 377.988251,490.617096 378.293610,482.143066 
	C379.082153,474.545349 379.640930,467.707886 379.886261,460.859222 
	C379.931091,459.606934 378.699463,458.308899 378.057068,457.031982 
	C378.029236,451.647461 378.001343,446.262909 378.309387,440.121155 
	C378.750336,431.406830 378.855408,423.449738 378.960480,415.492615 
	C378.762268,415.291687 378.668732,415.050201 379.203369,414.448608 
	C399.987640,414.129059 420.248444,414.129059 441.644073,414.129059 
	C441.312927,408.011383 441.032135,402.823975 440.768250,397.718689 
	C440.785187,397.800781 440.626617,397.746277 441.115295,397.825104 
	C443.101379,397.935852 444.598724,397.991333 446.096191,397.995911 
	C455.984772,398.026215 456.250610,398.012970 455.883728,387.945129 
	C455.705902,383.064636 457.264343,381.133911 462.001129,382.030365 
	C465.995209,382.786255 467.338837,380.557983 467.734283,377.195831 
	C467.908386,375.715668 468.001740,374.211609 467.962036,372.722931 
	C467.669861,361.773010 467.276367,350.825500 467.035309,339.874603 
	C466.893158,333.416931 466.850067,333.170959 460.272797,333.076111 
	C457.586761,333.037354 456.029846,332.717194 455.891541,329.361389 
	C455.422272,317.975250 455.286102,317.980774 444.014526,317.995209 
	C443.015656,317.996490 442.016785,318.000275 440.923492,317.825195 
	C440.829071,317.647552 440.925720,317.256958 440.957062,316.821655 
	C441.320740,311.727203 441.653076,307.067993 442.173523,302.010986 
	C442.542877,300.137665 442.837372,298.665985 442.887604,297.186005 
	C443.320465,284.427490 443.311279,284.548615 430.537537,284.960266 
	C426.980072,285.074982 425.803467,286.679749 425.727509,289.805359 
	C425.630585,293.793182 425.544312,297.781281 424.959564,301.829407 
	C415.226746,301.884491 405.987823,301.879425 396.763855,301.416260 
	C396.834167,297.165466 396.880676,293.372650 396.947723,289.580170 
	C397.002319,286.490234 395.409271,284.974335 392.412018,285.000946 
	C387.634796,285.043304 382.858612,285.196960 378.081970,285.303558 
	C378.081970,279.493622 378.081970,273.683685 378.081970,267.283447 
	C380.046173,267.178223 381.800049,267.002075 383.553894,267.002106 
	C465.836639,267.003754 548.119385,267.019836 630.402100,267.037598 
	C636.949890,267.039032 636.955261,267.050110 636.983154,273.748230 
	C636.997681,277.246033 636.989502,280.743866 636.510742,284.663025 
	C632.243774,285.056122 628.453186,285.132294 624.672729,284.971527 
	C620.593140,284.798004 619.024780,286.788300 619.115723,290.653320 
	C619.200745,294.266876 619.128418,297.884064 619.129395,301.735382 
	C619.133423,301.971100 618.946594,302.403229 618.537720,302.274994 
	C608.956848,302.112915 599.784119,302.006073 590.613220,302.092621 
	C588.078369,302.116516 585.548950,302.716156 582.689758,303.009247 
	C581.883606,302.573181 581.404663,302.179474 580.926636,301.332520 
	C580.986694,297.077484 581.342529,293.245789 580.963989,289.488068 
	C580.805542,287.914856 579.251953,285.393280 578.050964,285.216492 
	C574.333252,284.669159 570.438904,284.730438 566.701904,285.225464 
	C565.392395,285.398895 563.391541,287.480560 563.342957,288.753296 
	C563.175232,293.149139 563.729187,297.572540 564.020264,302.453735 
	C564.026611,306.412354 563.853516,309.912750 564.063416,313.390076 
	C564.263916,316.711121 563.313354,318.489227 559.660278,318.008759 
	C556.045044,317.533234 554.587036,319.671539 554.021729,322.839508 
	C553.358093,326.558075 552.653320,330.269287 551.551147,334.001312 
	C550.410889,335.078766 549.099487,336.112793 549.051636,337.202332 
	C548.587524,347.768097 548.186462,358.341400 548.060242,368.916046 
	C547.903442,382.049622 548.023193,382.051056 561.161438,382.233276 
	C561.850159,382.668365 562.379883,382.861877 562.919800,383.423462 
	C562.948608,384.527832 562.967102,385.264069 562.980713,386.439270 
	C563.162537,390.433014 563.349304,393.987823 563.544495,397.702240 
	C568.286865,397.702240 572.863953,397.702240 577.347534,397.734009 
	C577.253967,397.765747 577.294739,397.572388 577.211914,398.066833 
	C577.091553,402.208557 577.225769,405.868103 576.966797,409.499603 
	C576.722412,412.927063 577.897095,414.137024 581.433777,414.083313 
	C594.199646,413.889374 606.969788,413.979919 619.836548,413.974365 
	C619.934692,413.985748 620.130981,414.008362 620.083069,414.473969 
	C619.993103,421.286255 619.950989,427.632935 619.524414,434.001099 
	C615.673645,435.682129 616.434387,437.871826 618.509705,440.535767 
	C620.550110,444.766418 618.438904,452.215668 626.979858,450.613586 
	C626.554565,457.140228 626.194031,463.310638 625.587952,469.456879 
	C625.493958,470.410004 624.424316,471.821350 623.552490,472.048401 
	C619.526306,473.097198 620.099976,476.105713 619.962036,479.092896 
	C619.278564,493.897797 622.070801,508.912994 617.039185,523.176147 
	C614.891724,524.622375 612.872070,526.181580 611.421265,528.158325 
	C610.558228,529.334290 609.901611,531.497192 610.431824,532.602600 
	C610.876282,533.529297 613.205322,534.017395 614.648193,533.921448 
	C621.954590,533.435974 629.244080,532.696411 636.539490,532.045837 
	C636.631592,532.752258 636.723633,533.458679 636.794739,534.522766 
	C634.839172,535.250854 632.904846,535.944031 630.970093,535.945190 
	C547.264771,535.996338 463.559479,535.986084 379.296600,535.738281 
	C378.563751,535.256592 378.332703,535.104248 378.045959,535.036316 
	C378.027283,534.570190 378.008636,534.104126 378.312866,533.003662 
	C378.435150,527.924561 378.234619,523.479858 378.034119,519.035095 
M538.052734,448.628357 
	C546.030579,442.921478 550.740540,435.099518 552.493958,425.563690 
	C553.570068,419.710571 553.093933,413.883759 549.779480,408.714447 
	C545.409424,401.898804 536.353821,401.213196 532.346375,407.617798 
	C530.999634,409.770142 531.108887,412.956726 530.930359,415.695160 
	C530.215332,426.669495 524.368042,432.417969 513.480530,432.610809 
	C510.650787,432.660919 507.794830,432.406281 504.993652,432.699677 
	C491.549713,434.107666 483.616302,425.384949 484.733276,413.164734 
	C485.101654,409.134186 483.394867,406.081482 479.606110,404.312103 
	C475.563812,402.424286 471.489288,402.609436 468.550323,405.839600 
	C466.198273,408.424713 463.964233,411.778259 463.287018,415.117157 
	C459.372345,434.418243 475.705688,453.903290 495.523712,454.009125 
	C503.349060,454.050934 511.183655,453.776306 518.997803,454.072906 
	C525.767334,454.329834 531.817139,452.637207 538.052734,448.628357 
M394.981171,438.633362 
	C394.995728,441.277313 394.778015,443.952698 395.129547,446.551056 
	C395.288940,447.729065 396.495880,449.615692 397.368134,449.698334 
	C400.960449,450.038788 404.638092,450.067963 408.213593,449.631592 
	C409.262390,449.503540 410.732574,447.545807 410.897888,446.281311 
	C412.491577,434.091461 412.160767,433.784363 400.091248,433.913727 
	C399.595520,433.919037 399.094727,433.964874 398.604797,433.912537 
	C395.753204,433.607819 394.677826,434.982361 394.981171,438.633362 
M442.871826,511.329041 
	C442.864532,501.964355 442.864532,501.963226 433.632050,502.056488 
	C432.475372,502.068176 431.176178,501.775696 430.199341,502.205994 
	C429.040710,502.716339 427.524689,503.695435 427.256653,504.749359 
	C424.564484,515.335083 426.985748,518.232849 437.481079,518.037781 
	C441.699188,517.959351 443.082245,516.014648 442.871826,511.329041 
z"
      />
      <path
        fill="#030213"
        opacity="1.000000"
        stroke="none"
        d="
M637.415527,396.968048 
	C637.090576,396.938782 636.902527,397.085266 636.486206,397.581543 
	C634.102661,397.788605 632.084106,397.821564 629.601196,397.757263 
	C611.856079,397.630768 594.575439,397.601593 577.294739,397.572388 
	C577.294739,397.572388 577.253967,397.765747 577.346008,397.402039 
	C577.372986,396.388336 577.307983,395.738342 577.400269,394.714661 
	C577.442749,391.234314 577.327942,388.127594 577.354858,384.578979 
	C577.362854,371.091431 577.229187,358.045837 577.224487,344.546875 
	C577.265015,335.729187 577.176636,327.364929 577.229309,318.785706 
	C578.101562,317.995209 578.832703,317.419647 579.895142,316.941895 
	C580.448425,316.978119 580.670349,316.916473 581.293335,316.944885 
	C586.130066,316.965363 590.565796,316.895752 595.459839,316.907928 
	C600.278137,316.929321 604.638184,316.868927 609.440063,316.887268 
	C613.296143,316.902863 616.710449,316.839752 620.542603,316.858398 
	C622.091248,316.888550 623.221924,316.837006 624.693726,316.871979 
	C627.722717,316.917725 630.410645,316.876923 633.296997,316.905090 
	C633.780823,316.956421 634.046570,316.874908 634.546631,316.837646 
	C635.165405,316.923920 635.530396,316.902008 636.219116,317.020386 
	C636.864014,317.500031 637.185059,317.839386 637.480591,318.634399 
	C637.310364,323.886383 637.165649,328.682709 636.551331,333.496094 
	C627.843079,333.513092 619.604370,333.513092 611.322632,333.513092 
	C611.322632,340.316681 611.322632,346.824615 611.322632,353.708679 
	C620.178345,353.708679 628.598389,353.708679 637.106079,353.959839 
	C637.404968,354.495758 637.616211,354.780518 637.714233,355.488770 
	C637.539307,369.597565 637.477417,383.282806 637.415527,396.968048 
z"
      />
      <path
        fill="#A5B1C1"
        opacity="1.000000"
        stroke="none"
        d="
M673.994751,552.928955 
	C675.792542,552.282715 677.549805,551.266602 679.396240,551.063965 
	C683.835388,550.576660 687.030029,549.419434 686.064819,543.882385 
	C685.672485,541.631775 685.884644,539.248840 686.024353,536.938293 
	C686.304443,532.307678 685.776001,528.542908 679.659851,528.811279 
	C678.835388,528.847473 677.304443,527.283813 677.221008,526.359802 
	C677.091187,524.922974 677.647034,523.222961 678.444946,521.975098 
	C678.966187,521.159851 680.429504,520.947021 681.470947,520.464294 
	C679.272644,515.804565 676.815308,515.067017 672.821350,517.592346 
	C671.868042,518.195129 670.529907,518.189331 669.152710,518.185059 
	C669.263855,502.100708 669.590149,486.294647 669.919250,470.488678 
	C669.972778,467.917206 669.252563,465.126038 672.806396,463.887726 
	C673.507202,463.643524 673.849060,461.614716 673.896301,460.391144 
	C674.036438,456.765991 673.946594,453.131897 673.946594,449.042114 
	C678.751526,448.635345 682.968933,448.278290 688.488831,447.811005 
	C688.996643,482.974884 689.456665,517.483948 687.681396,552.488647 
	C682.781311,552.955200 678.388000,552.942078 673.994751,552.928955 
z"
      />
      <path
        fill="#CBD9E2"
        opacity="1.000000"
        stroke="none"
        d="
M636.649048,531.584961 
	C629.244080,532.696411 621.954590,533.435974 614.648193,533.921448 
	C613.205322,534.017395 610.876282,533.529297 610.431824,532.602600 
	C609.901611,531.497192 610.558228,529.334290 611.421265,528.158325 
	C612.872070,526.181580 614.891724,524.622375 617.039185,523.176147 
	C622.070801,508.912994 619.278564,493.897797 619.962036,479.092896 
	C620.099976,476.105713 619.526306,473.097198 623.552490,472.048401 
	C624.424316,471.821350 625.493958,470.410004 625.587952,469.456879 
	C626.194031,463.310638 626.554565,457.140228 627.426270,450.537750 
	C630.785522,450.131134 633.714172,450.164490 636.642822,450.197876 
	C636.681396,477.173248 636.720032,504.148651 636.649048,531.584961 
z"
      />
      <path
        fill="#A8C4D6"
        opacity="1.000000"
        stroke="none"
        d="
M637.020874,333.479065 
	C637.165649,328.682709 637.310364,323.886383 637.908203,318.562195 
	C643.244202,318.037354 648.127075,318.040344 653.010010,318.043304 
	C653.010010,318.043304 653.010864,317.987488 652.958191,318.455811 
	C652.950134,323.946716 652.994690,328.969330 653.001892,334.459900 
	C652.898132,355.885895 652.831665,376.843964 652.765259,397.802063 
	C648.448853,397.747955 644.132446,397.693878 639.423218,396.985840 
	C639.209595,383.151794 639.410828,369.971863 639.493164,356.791199 
	C639.496765,356.219360 638.407532,355.640717 637.827393,355.065247 
	C637.616211,354.780518 637.404968,354.495758 637.075378,353.511230 
	C636.978271,346.367310 636.999573,339.923187 637.020874,333.479065 
z"
      />
      <path
        fill="#6984A0"
        opacity="1.000000"
        stroke="none"
        d="
M653.243225,397.863190 
	C652.831665,376.843964 652.898132,355.885895 653.384644,334.463806 
	C655.268860,334.595856 656.736389,335.183594 658.196350,335.789825 
	C660.777344,336.861420 663.353577,337.944275 665.931824,339.022461 
	C665.949707,356.502472 665.976318,373.982513 665.980835,391.462555 
	C665.982483,397.975098 665.958801,397.980225 659.213684,397.984192 
	C657.382874,397.985260 655.552002,397.945404 653.243225,397.863190 
z"
      />
      <path
        fill="#92AABE"
        opacity="1.000000"
        stroke="none"
        d="
M636.741028,449.722046 
	C633.714172,450.164490 630.785522,450.131134 627.410400,450.173645 
	C618.438904,452.215668 620.550110,444.766418 618.856750,440.412903 
	C619.341736,437.968170 619.625305,435.973907 619.908875,433.979614 
	C619.950989,427.632935 619.993103,421.286255 620.538086,414.467102 
	C626.278320,413.968628 631.515747,413.942596 636.753174,413.916565 
	C636.781860,425.693115 636.810547,437.469666 636.741028,449.722046 
z"
      />
      <path
        fill="#DAE3E8"
        opacity="1.000000"
        stroke="none"
        d="
M673.572632,552.966431 
	C678.388000,552.942078 682.781311,552.955200 687.648560,552.929382 
	C688.360413,557.905029 688.598206,562.919617 688.831909,568.387207 
	C682.841309,568.927551 676.854736,569.014771 670.432251,569.053101 
	C669.984863,564.890137 670.060303,560.773376 669.928467,556.663269 
	C669.852173,554.284912 670.437439,552.784912 673.572632,552.966431 
z"
      />
      <path
        fill="#697B96"
        opacity="1.000000"
        stroke="none"
        d="
M665.954529,338.581421 
	C663.353577,337.944275 660.777344,336.861420 658.196350,335.789825 
	C656.736389,335.183594 655.268860,334.595856 653.421997,333.995880 
	C652.994690,328.969330 652.950134,323.946716 652.930298,318.456879 
	C655.400391,317.687225 657.846619,317.391724 660.290894,317.080780 
	C664.396606,316.558472 666.569458,317.910980 666.137207,322.492889 
	C665.843750,325.603912 666.072083,328.764099 666.053345,332.348206 
	C666.018860,334.576202 665.998047,336.358276 665.954529,338.581421 
z"
      />
      <path
        fill="#ABC6D5"
        opacity="1.000000"
        stroke="none"
        d="
M636.824341,413.460419 
	C631.515747,413.942596 626.278320,413.968628 620.585938,414.001526 
	C620.130981,414.008362 619.934692,413.985748 619.922485,413.613007 
	C621.253418,411.031952 622.525940,408.776703 623.956848,406.626770 
	C625.930542,403.661072 628.023621,400.774811 630.065613,397.854523 
	C632.084106,397.821564 634.102661,397.788605 636.554810,397.766235 
	C636.957458,402.852661 636.926514,407.928467 636.824341,413.460419 
z"
      />
      <path
        fill="#BDCCD9"
        opacity="1.000000"
        stroke="none"
        d="
M637.714233,355.488770 
	C638.407532,355.640717 639.496765,356.219360 639.493164,356.791199 
	C639.410828,369.971863 639.209595,383.151794 639.049683,396.832703 
	C638.786865,397.302429 638.504761,397.271362 637.819092,397.104156 
	C637.477417,383.282806 637.539307,369.597565 637.714233,355.488770 
z"
      />
      <path
        fill="#ABC6D5"
        opacity="1.000000"
        stroke="none"
        d="
M221.484222,416.958862 
	C221.921280,423.727417 221.921280,430.512024 221.921280,436.960632 
	C219.215881,438.030090 216.978485,438.914581 214.426666,440.010345 
	C212.594086,437.892944 211.075912,435.564209 209.230530,433.082581 
	C210.284241,430.136108 212.591080,427.430145 212.865112,424.531952 
	C213.567383,417.104462 213.206100,417.070312 221.484222,416.958862 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M727.525269,873.031189 
	C726.521484,873.703247 725.067322,874.861572 723.576416,874.911011 
	C717.259216,875.120239 710.925537,874.843506 704.609802,875.073242 
	C701.901428,875.171875 699.234558,876.153259 696.524170,876.574524 
	C695.268616,876.769653 693.254272,877.106262 692.787231,876.480591 
	C690.777283,873.787964 688.755432,875.460022 686.351929,875.990234 
	C686.319641,875.235535 686.629883,874.478760 686.963074,873.417480 
	C700.343323,873.077759 713.700684,873.042603 727.525269,873.031189 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M752.552490,873.007812 
	C750.710327,876.540161 747.964417,875.381592 745.092163,873.424927 
	C747.374878,873.030090 749.742065,873.009033 752.552490,873.007812 
z"
      />
      <path
        fill="#7ED8F2"
        opacity="1.000000"
        stroke="none"
        d="
M414.270325,878.275879 
	C415.601593,877.316956 417.226532,876.091492 418.858032,876.082764 
	C441.925079,875.958923 464.993073,876.000366 488.529205,876.004639 
	C488.037872,877.002686 487.078979,878.865540 486.118317,878.866455 
	C462.267975,878.888855 438.417511,878.714478 414.270325,878.275879 
z"
      />
      <path
        fill="#7ED8F2"
        opacity="1.000000"
        stroke="none"
        d="
M543.018372,876.424072 
	C567.021912,876.007019 591.041321,876.004211 615.529175,876.003174 
	C614.866821,877.002502 613.742798,878.861572 612.604492,878.870361 
	C590.480957,879.041870 568.355591,879.027405 546.231384,878.905334 
	C545.161926,878.899475 544.099854,877.557861 543.018372,876.424072 
z"
      />
      <path
        fill="#7ED8F2"
        opacity="1.000000"
        stroke="none"
        d="
M379.018311,876.424072 
	C388.113220,876.007324 397.224640,875.951233 406.334198,876.055786 
	C407.835205,876.073059 409.328186,876.794800 410.925171,877.583984 
	C409.484894,878.317993 407.947906,878.933472 406.403168,878.953613 
	C398.661774,879.054688 390.915558,879.105835 383.178192,878.902466 
	C381.779724,878.865662 380.414673,877.558899 379.018311,876.424072 
z"
      />
      <path
        fill="#7ED8F2"
        opacity="1.000000"
        stroke="none"
        d="
M515.747742,875.324890 
	C516.419495,875.059448 516.886047,875.044006 517.703125,875.050049 
	C524.389526,875.381958 530.725464,875.692322 537.529480,876.005005 
	C537.037598,877.002686 536.110535,878.819763 535.112244,878.859863 
	C528.655823,879.118713 522.181580,879.074280 515.719788,878.892700 
	C514.806091,878.867065 513.928528,877.553589 513.018311,876.421753 
	C513.849182,875.863220 514.695862,875.719055 515.747742,875.324890 
z"
      />
      <path
        fill="#7ED8F2"
        opacity="1.000000"
        stroke="none"
        d="
M648.018188,876.423523 
	C652.690247,876.008118 657.378113,876.006531 662.531738,876.008545 
	C662.954651,876.505554 663.092712,877.135254 662.840088,877.470581 
	C660.885315,880.065796 650.247437,879.579529 648.018188,876.423523 
z"
      />
      <path
        fill="#7ED8F2"
        opacity="1.000000"
        stroke="none"
        d="
M626.016602,876.409790 
	C628.371582,876.004028 630.740784,876.003174 633.553711,876.004761 
	C633.212524,876.997009 632.311340,878.890198 631.664307,878.807007 
	C629.733032,878.558655 627.901367,877.535339 626.016602,876.409790 
z"
      />
      <path
        fill="#7ED8F2"
        opacity="1.000000"
        stroke="none"
        d="
M638.009827,876.377625 
	C638.751221,876.007996 639.500000,876.006104 640.623169,876.007019 
	C640.617676,876.923157 640.237793,877.836609 639.857910,878.750061 
	C639.244385,878.081848 638.630798,877.413635 638.009827,876.377625 
z"
      />
      <path
        fill="#9CB7CB"
        opacity="1.000000"
        stroke="none"
        d="
M151.832184,292.301239 
	C152.051971,294.106995 152.244171,296.373322 152.138718,298.923828 
	C151.828918,297.059265 151.816757,294.910522 151.832184,292.301239 
z"
      />
      <path
        fill="#FAFBFD"
        opacity="1.000000"
        stroke="none"
        d="
M580.264648,789.628235 
	C581.155823,791.301697 581.960022,792.991760 581.982178,794.692078 
	C582.097046,803.511841 581.668945,812.351379 582.109314,821.150024 
	C582.444336,827.844055 579.650330,832.370361 575.591858,837.499939 
	C568.594360,846.344360 559.617859,843.709045 550.971252,843.845520 
	C550.309875,843.855957 549.158691,842.124939 549.035278,841.106506 
	C548.736816,838.644348 548.790466,836.118530 548.920166,833.629761 
	C549.229553,827.692017 549.698486,821.750549 543.963501,817.308350 
	C544.192566,812.812439 544.483093,808.673401 544.814148,803.955566 
	C550.898254,803.955566 556.937317,803.955566 563.509460,803.955566 
	C563.509460,799.131592 563.509460,794.881104 563.509460,789.649597 
	C569.293762,789.649597 574.555298,789.649597 580.264648,789.628235 
z"
      />
      <path
        fill="#ABC6D5"
        opacity="1.000000"
        stroke="none"
        d="
M480.396790,770.656189 
	C482.364746,772.655518 484.332703,774.654785 486.576538,777.106262 
	C486.852386,777.558472 487.057770,778.002136 487.057739,778.002136 
	C487.060242,779.072998 487.062744,780.143799 487.037964,781.884888 
	C486.958588,782.937805 486.906525,783.320435 486.854462,783.703064 
	C482.852325,788.159302 481.980988,788.190491 477.728210,784.193054 
	C476.050598,782.616150 474.291229,781.126099 472.029633,779.117493 
	C472.336121,782.345886 472.788269,785.282837 472.846344,788.227600 
	C472.905884,791.247375 473.837585,794.761475 469.710754,796.221924 
	C465.525970,797.702881 463.758545,794.477234 461.582794,792.068848 
	C461.264679,791.716675 461.134460,791.194702 460.853882,790.200195 
	C460.790466,789.648987 460.962250,789.209656 461.080566,788.824341 
	C463.198425,784.580139 461.775085,781.476929 459.131927,778.553467 
	C457.480042,776.726501 455.330963,774.953857 454.651093,772.769043 
	C454.113251,771.040710 455.065186,768.435303 456.204407,766.816833 
	C456.618439,766.228699 459.683136,767.011475 461.185852,767.822144 
	C462.723389,768.651672 463.842407,770.268616 465.121307,771.563965 
	C473.234436,779.781372 473.232300,779.783447 480.396790,770.656189 
z"
      />
      <path
        fill="#563446"
        opacity="1.000000"
        stroke="none"
        d="
M486.900909,784.069031 
	C486.906525,783.320435 486.958588,782.937805 487.107727,782.280640 
	C487.647919,783.516357 488.091064,785.026611 488.266724,786.768433 
	C487.648590,786.145020 487.297974,785.289978 486.900909,784.069031 
z"
      />
      <path
        fill="#553C4F"
        opacity="1.000000"
        stroke="none"
        d="
M487.289490,778.002808 
	C487.057770,778.002136 486.852386,777.558472 486.824127,777.315613 
	C487.104065,777.231262 487.412262,777.389832 487.852539,777.777832 
	C487.984589,778.007324 487.521179,778.003479 487.289490,778.002808 
z"
      />
      <path
        fill="#D8E2E7"
        opacity="1.000000"
        stroke="none"
        d="
M865.981323,282.515320 
	C865.609802,282.090546 865.017700,281.191833 865.052979,280.318451 
	C865.180176,277.165436 863.793640,275.996216 860.672241,275.894440 
	C859.019958,275.840576 857.402344,274.726837 855.393799,274.042511 
	C855.012146,265.532257 855.310669,257.055603 854.881836,248.615952 
	C854.635925,243.775925 856.459900,242.416199 860.819092,242.966888 
	C862.122742,243.131577 863.469543,242.955032 865.228149,242.912445 
	C865.789368,255.944366 865.918945,268.998657 865.981323,282.515320 
z"
      />
      <path
        fill="#C58790"
        opacity="1.000000"
        stroke="none"
        d="
M425.109558,775.928711 
	C424.774841,776.731934 424.451355,777.147888 423.978638,777.785278 
	C420.218384,777.837402 421.682526,780.797791 420.841949,782.451904 
	C419.979889,784.148438 417.956757,785.254883 416.441772,786.619629 
	C415.309692,785.041565 412.914001,783.113403 413.239319,781.939148 
	C416.829498,768.981140 414.317383,755.813660 414.939941,742.777710 
	C415.290955,735.428406 415.436768,735.004517 423.044312,735.002197 
	C425.499969,735.001404 427.955658,735.002075 430.887970,735.002075 
	C429.900604,740.405457 428.926941,745.137207 428.262970,749.911926 
	C428.142181,750.780640 429.286713,751.825378 430.525269,752.790344 
	C431.801178,752.897827 432.398926,753.003723 432.996674,753.109619 
	C432.855377,755.793884 434.119812,756.811218 436.800262,757.118164 
	C438.374756,757.298401 439.809601,758.697998 441.307251,759.549133 
	C440.320770,761.138855 439.408325,762.781982 438.316650,764.295898 
	C437.769196,765.055054 436.239899,765.613220 436.270844,766.203918 
	C436.569092,771.901306 432.060760,771.857483 428.497009,773.125061 
	C427.243683,773.570801 426.239044,774.715820 425.109558,775.928711 
z"
      />
      <path
        fill="#F6F7F8"
        opacity="1.000000"
        stroke="none"
        d="
M480.212769,770.376953 
	C473.232300,779.783447 473.234436,779.781372 465.121307,771.563965 
	C463.842407,770.268616 462.723389,768.651672 461.185852,767.822144 
	C459.683136,767.011475 456.618439,766.228699 456.204407,766.816833 
	C455.065186,768.435303 454.113251,771.040710 454.651093,772.769043 
	C455.330963,774.953857 457.480042,776.726501 459.131927,778.553467 
	C461.775085,781.476929 463.198425,784.580139 460.733643,788.771240 
	C454.192596,783.370483 448.116821,777.637573 442.041077,771.904663 
	C440.108612,767.870728 441.272461,764.806641 444.546570,761.890747 
	C448.487915,758.380737 452.042786,754.436829 455.878174,750.370117 
	C457.992584,750.445007 460.656250,750.145203 461.883484,751.331482 
	C468.136230,757.375488 474.017487,763.803894 480.212769,770.376953 
z"
      />
      <path
        fill="#1D0F1B"
        opacity="1.000000"
        stroke="none"
        d="
M433.455444,753.028992 
	C432.398926,753.003723 431.801178,752.897827 430.904877,752.740601 
	C430.496887,751.536011 430.421021,750.378357 430.271759,749.230164 
	C429.784058,745.478333 431.014221,743.605408 435.179474,744.066650 
	C438.296417,744.411743 441.476410,744.304749 444.623871,744.224121 
	C448.005737,744.137451 449.180206,745.774292 449.391205,749.006592 
	C449.634369,752.731750 447.082581,752.486877 444.851959,752.623596 
	C441.213074,752.846497 437.560852,752.851440 433.455444,753.028992 
z"
      />
      <path
        fill="#F6F7F8"
        opacity="1.000000"
        stroke="none"
        d="
M431.149597,788.509766 
	C431.080658,784.324402 431.011749,780.138977 430.969910,775.497986 
	C434.429535,775.210571 437.862061,775.378845 441.830261,775.989136 
	C442.786011,777.799805 443.206085,779.168518 443.445709,780.962036 
	C442.324066,784.045471 444.205475,787.952515 439.421997,789.035583 
	C436.377228,788.815735 433.763397,788.662781 431.149597,788.509766 
z"
      />
      <path
        fill="#505E78"
        opacity="1.000000"
        stroke="none"
        d="
M443.626160,780.537170 
	C443.206085,779.168518 442.786011,777.799805 442.225739,776.095093 
	C442.089935,774.731689 442.094360,773.704346 442.069916,772.290894 
	C448.116821,777.637573 454.192596,783.370483 460.615295,789.156494 
	C460.962250,789.209656 460.790466,789.648987 460.717773,789.873596 
	C459.097992,790.592224 457.550903,791.086182 455.663269,791.716309 
	C451.423859,788.080627 447.524994,784.308899 443.626160,780.537170 
z"
      />
      <path
        fill="#A5C1D1"
        opacity="1.000000"
        stroke="none"
        d="
M443.445740,780.962036 
	C447.524994,784.308899 451.423859,788.080627 455.623657,792.007629 
	C456.591064,794.632874 457.975647,797.189392 457.692139,799.545105 
	C457.473389,801.362427 455.453705,803.531616 453.676727,804.392578 
	C452.496552,804.964417 450.103821,803.843567 448.665588,802.871277 
	C446.794525,801.606445 445.331757,799.737549 443.221558,797.671387 
	C441.784210,794.510193 440.818573,791.806335 439.852936,789.102417 
	C444.205475,787.952515 442.324066,784.045471 443.445740,780.962036 
z"
      />
      <path
        fill="#563446"
        opacity="1.000000"
        stroke="none"
        d="
M439.421997,789.035583 
	C440.818573,791.806335 441.784210,794.510193 442.919922,797.516357 
	C442.727692,798.142090 442.093964,798.787231 442.041901,798.743286 
	C438.356384,795.631958 434.717224,792.465637 431.111908,788.906860 
	C433.763397,788.662781 436.377228,788.815735 439.421997,789.035583 
z"
      />
      <path
        fill="#F7FAFB"
        opacity="1.000000"
        stroke="none"
        d="
M869.466248,299.447083 
	C869.525024,299.410675 869.407471,299.483521 869.466248,299.447083 
z"
      />
      <path
        fill="#A9B7C4"
        opacity="1.000000"
        stroke="none"
        d="
M541.034485,783.460205 
	C541.341248,784.549011 541.685181,785.669800 542.029175,786.790527 
	C541.743347,786.847473 541.457458,786.904419 541.171631,786.961365 
	C541.107239,786.050171 541.042786,785.138916 540.977661,783.845093 
	C540.976868,783.462524 541.071716,783.492065 541.034485,783.460205 
z"
      />
      <path
        fill="#ABC6D5"
        opacity="1.000000"
        stroke="none"
        d="
M153.999084,287.000610 
	C153.876984,286.859406 153.755814,286.717590 153.634644,286.575745 
	C153.720871,286.624390 153.807114,286.673035 153.947357,286.859985 
	C154.001373,286.998260 154.000000,287.000000 153.999084,287.000610 
z"
      />
      <path
        fill="#7ED8F2"
        opacity="1.000000"
        stroke="none"
        d="
M359.100311,827.187012 
	C359.656494,827.373291 359.946808,827.635986 360.237152,827.898621 
	C359.715942,828.093201 359.194733,828.287781 358.673553,828.482361 
	C358.727203,828.076050 358.780853,827.669739 359.100311,827.187012 
z"
      />
      <path
        fill="#7ED7F1"
        opacity="1.000000"
        stroke="none"
        d="
M812.748779,828.001953 
	C812.724854,828.112671 812.452209,828.222961 812.090942,828.167847 
	C812.002441,828.002441 812.500000,828.001526 812.748779,828.001953 
z"
      />
      <path
        fill="#82D8F1"
        opacity="1.000000"
        stroke="none"
        d="
M753.463623,882.270508 
	C753.764526,880.718628 754.299133,879.462524 754.833679,878.206421 
	C755.492432,878.840759 756.151245,879.475159 756.809998,880.109497 
	C755.772461,880.928406 754.734924,881.747314 753.463623,882.270508 
z"
      />
      <path
        fill="#060515"
        opacity="1.000000"
        stroke="none"
        d="
M440.925720,317.256958 
	C440.925720,317.256958 440.829071,317.647552 440.807983,318.273499 
	C440.786865,323.647125 440.786865,328.394775 440.786865,333.530029 
	C430.734070,333.530029 421.821106,333.530029 413.090912,333.530029 
	C413.090912,340.500519 413.090912,347.033142 413.090912,353.947052 
	C422.610352,353.947052 431.556335,353.947052 440.626617,353.947052 
	C440.626617,368.819305 440.626617,383.282776 440.626617,397.746277 
	C440.626617,397.746277 440.785187,397.800781 440.324341,397.804352 
	C419.265259,397.797729 398.667023,397.787506 378.068787,397.777283 
	C378.068787,397.777313 378.027985,397.933533 378.134460,397.479828 
	C378.141632,370.690399 378.042328,344.354675 377.942993,318.018951 
	C377.943024,318.018951 378.199829,317.699219 378.570892,317.396698 
	C399.603210,317.148438 420.264465,317.202698 440.925720,317.256958 
z"
      />
      <path
        fill="#FAFBFD"
        opacity="1.000000"
        stroke="none"
        d="
M377.537659,317.957153 
	C378.042328,344.354675 378.141632,370.690399 378.214386,397.491241 
	C373.480591,397.880493 368.773346,397.804626 363.555542,397.227661 
	C363.023529,389.584076 363.091003,382.440033 362.961487,375.299530 
	C362.712097,361.551575 362.351288,347.805573 362.036682,334.058777 
	C362.084015,331.078278 362.145935,328.097931 362.175812,325.117249 
	C362.246246,318.091187 362.800293,317.405365 369.754242,317.205322 
	C372.204956,317.134857 374.672455,317.648407 377.537659,317.957153 
z"
      />
      <path
        fill="#FCFEFD"
        opacity="1.000000"
        stroke="none"
        d="
M378.071838,398.263489 
	C398.667023,397.787506 419.265259,397.797729 440.307434,397.722229 
	C441.032135,402.823975 441.312927,408.011383 441.644073,414.129059 
	C420.248444,414.129059 399.987640,414.129059 379.255707,414.256958 
	C378.548035,409.173157 378.311493,403.961395 378.071838,398.263489 
z"
      />
      <path
        fill="#FAFBFD"
        opacity="1.000000"
        stroke="none"
        d="
M440.957062,316.821655 
	C420.264465,317.202698 399.603210,317.148438 378.487549,317.209381 
	C378.065369,312.509247 378.097595,307.693909 378.511475,302.582947 
	C384.845093,302.149689 390.796997,302.012024 396.748901,301.874359 
	C405.987823,301.879425 415.226746,301.884491 425.296997,302.013641 
	C431.414032,302.228088 436.699738,302.318451 441.985413,302.408813 
	C441.653076,307.067993 441.320740,311.727203 440.957062,316.821655 
z"
      />
      <path
        fill="#DAE3E8"
        opacity="1.000000"
        stroke="none"
        d="
M361.568115,334.041809 
	C362.351288,347.805573 362.712097,361.551575 362.961487,375.299530 
	C363.091003,382.440033 363.023529,389.584076 363.107422,397.150085 
	C349.101349,398.983215 349.098724,398.983215 349.094910,385.325684 
	C349.090576,369.837982 349.233734,354.348083 348.994293,338.864075 
	C348.931305,334.791687 350.213257,333.465607 354.111115,333.929626 
	C356.411774,334.203461 358.768250,334.008789 361.568115,334.041809 
z"
      />
      <path
        fill="#060515"
        opacity="1.000000"
        stroke="none"
        d="
M396.763855,301.416260 
	C390.796997,302.012024 384.845093,302.149689 378.453735,302.113342 
	C378.053009,296.692322 378.091705,291.445251 378.106201,285.750885 
	C382.858612,285.196960 387.634796,285.043304 392.412018,285.000946 
	C395.409271,284.974335 397.002319,286.490234 396.947723,289.580170 
	C396.880676,293.372650 396.834167,297.165466 396.763855,301.416260 
z"
      />
      <path
        fill="#060515"
        opacity="1.000000"
        stroke="none"
        d="
M619.125366,301.499664 
	C619.128418,297.884064 619.200745,294.266876 619.115723,290.653320 
	C619.024780,286.788300 620.593140,284.798004 624.672729,284.971527 
	C628.453186,285.132294 632.243774,285.056122 636.490234,285.143066 
	C636.895569,290.797913 636.840637,296.393982 636.498657,302.382629 
	C636.211548,302.775177 636.158447,302.884766 635.717285,302.759705 
	C629.892578,302.256317 624.509033,301.877991 619.125366,301.499664 
z"
      />
      <path
        fill="#6F7F97"
        opacity="1.000000"
        stroke="none"
        d="
M636.158447,302.884766 
	C636.158447,302.884766 636.211548,302.775177 636.264832,302.780396 
	C640.396057,303.177673 644.509766,303.367523 648.531311,304.078461 
	C649.827026,304.307526 651.648193,305.744049 651.898499,306.913086 
	C652.614197,310.255371 652.646851,313.743958 652.979126,317.609131 
	C648.127075,318.040344 643.244202,318.037354 637.933716,318.106567 
	C637.185059,317.839386 636.864014,317.500031 636.213989,316.795654 
	C635.885010,316.430634 635.722900,316.011353 635.813965,315.554504 
	C635.989502,311.026672 636.073975,306.955719 636.158447,302.884766 
z"
      />
      <path
        fill="#F4F6F8"
        opacity="1.000000"
        stroke="none"
        d="
M378.037109,457.497375 
	C378.699463,458.308899 379.931091,459.606934 379.886261,460.859222 
	C379.640930,467.707886 379.082153,474.545349 378.334290,481.673401 
	C378.030701,473.960999 378.023926,465.961853 378.037109,457.497375 
z"
      />
      <path
        fill="#F4F6F8"
        opacity="1.000000"
        stroke="none"
        d="
M378.666931,415.849731 
	C378.855408,423.449738 378.750336,431.406830 378.352509,439.651306 
	C378.164276,432.028046 378.268799,424.117462 378.666931,415.849731 
z"
      />
      <path
        fill="#F4F6F8"
        opacity="1.000000"
        stroke="none"
        d="
M378.019135,519.496094 
	C378.234619,523.479858 378.435150,527.924561 378.345764,532.654907 
	C378.038605,528.612671 378.021393,524.284851 378.019135,519.496094 
z"
      />
      <path
        fill="#F4F6F8"
        opacity="1.000000"
        stroke="none"
        d="
M378.034546,506.496277 
	C378.238953,508.845856 378.425446,511.639496 378.322968,514.698608 
	C378.028198,512.289551 378.022400,509.614929 378.034546,506.496277 
z"
      />
      <path
        fill="#F4F6F8"
        opacity="1.000000"
        stroke="none"
        d="
M378.105774,535.249084 
	C378.332703,535.104248 378.563751,535.256592 378.825012,535.684692 
	C378.589783,535.868835 378.341339,535.730774 378.105774,535.249084 
z"
      />
      <path
        fill="#060515"
        opacity="1.000000"
        stroke="none"
        d="
M537.733032,448.803375 
	C531.817139,452.637207 525.767334,454.329834 518.997803,454.072906 
	C511.183655,453.776306 503.349060,454.050934 495.523712,454.009125 
	C475.705688,453.903290 459.372345,434.418243 463.287018,415.117157 
	C463.964233,411.778259 466.198273,408.424713 468.550323,405.839600 
	C471.489288,402.609436 475.563812,402.424286 479.606110,404.312103 
	C483.394867,406.081482 485.101654,409.134186 484.733276,413.164734 
	C483.616302,425.384949 491.549713,434.107666 504.993652,432.699677 
	C507.794830,432.406281 510.650787,432.660919 513.480530,432.610809 
	C524.368042,432.417969 530.215332,426.669495 530.930359,415.695160 
	C531.108887,412.956726 530.999634,409.770142 532.346375,407.617798 
	C536.353821,401.213196 545.409424,401.898804 549.779480,408.714447 
	C553.093933,413.883759 553.570068,419.710571 552.493958,425.563690 
	C550.740540,435.099518 546.030579,442.921478 537.733032,448.803375 
z"
      />
      <path
        fill="#F8F9FA"
        opacity="1.000000"
        stroke="none"
        d="
M441.115295,397.825104 
	C440.626617,383.282776 440.626617,368.819305 440.626617,353.947052 
	C431.556335,353.947052 422.610352,353.947052 413.090912,353.947052 
	C413.090912,347.033142 413.090912,340.500519 413.090912,333.530029 
	C421.821106,333.530029 430.734070,333.530029 440.786865,333.530029 
	C440.786865,328.394775 440.786865,323.647125 440.902405,318.451172 
	C442.016785,318.000275 443.015656,317.996490 444.014526,317.995209 
	C455.286102,317.980774 455.422272,317.975250 455.891541,329.361389 
	C456.029846,332.717194 457.586761,333.037354 460.272797,333.076111 
	C466.850067,333.170959 466.893158,333.416931 467.035309,339.874603 
	C467.276367,350.825500 467.669861,361.773010 467.962036,372.722931 
	C468.001740,374.211609 467.908386,375.715668 467.734283,377.195831 
	C467.338837,380.557983 465.995209,382.786255 462.001129,382.030365 
	C457.264343,381.133911 455.705902,383.064636 455.883728,387.945129 
	C456.250610,398.012970 455.984772,398.026215 446.096191,397.995911 
	C444.598724,397.991333 443.101379,397.935852 441.115295,397.825104 
z"
      />
      <path
        fill="#FCFEFD"
        opacity="1.000000"
        stroke="none"
        d="
M577.213135,385.020905 
	C577.327942,388.127594 577.442749,391.234314 576.927490,394.689636 
	C572.826294,395.038269 569.355103,395.038269 565.883850,395.038269 
	C565.876953,394.554962 565.870056,394.071655 565.863159,393.588348 
	C567.072083,393.391815 568.378296,393.416840 569.464417,392.940369 
	C570.713440,392.392426 571.767883,391.400818 572.906860,390.601929 
	C571.526428,389.470276 570.296631,388.005066 568.725464,387.295990 
	C566.974060,386.505524 564.912292,386.402588 562.985657,386.000366 
	C562.967102,385.264069 562.948608,384.527832 563.227356,383.285034 
	C564.237000,382.143341 564.949341,381.508240 565.661682,380.873108 
	C565.384766,380.469971 565.107849,380.066833 564.830994,379.663727 
	C563.554810,380.439728 562.278625,381.215698 561.002441,381.991699 
	C548.023193,382.051056 547.903442,382.049622 548.060242,368.916046 
	C548.186462,358.341400 548.587524,347.768097 549.051636,337.202332 
	C549.099487,336.112793 550.410889,335.078766 551.912476,334.047821 
	C553.134033,334.281586 553.727234,334.368683 553.999084,334.710999 
	C555.440063,336.525818 557.074402,338.212250 559.115051,335.892883 
	C559.534302,335.416290 558.265930,333.023712 557.332214,331.874420 
	C555.146240,329.183838 555.685974,326.679382 558.185791,325.089722 
	C561.998230,322.665344 566.189331,320.836456 570.762512,318.474304 
	C570.933289,317.272919 571.218689,315.265381 571.504089,313.257812 
	C571.926514,313.117828 572.348938,312.977844 572.771423,312.837860 
	C573.510376,313.980865 574.219910,315.144348 574.997314,316.260559 
	C575.653320,317.202393 576.388245,318.089386 577.088196,319.000641 
	C577.176636,327.364929 577.265015,335.729187 576.825317,344.596191 
	C575.532410,346.395935 574.126282,347.682129 574.104126,348.991730 
	C573.923645,359.652039 573.886719,370.319519 574.143677,380.976685 
	C574.176758,382.348602 576.142578,383.673981 577.213135,385.020905 
M554.721069,363.440765 
	C556.888123,359.516998 556.555054,357.114227 552.528076,353.975647 
	C553.195618,357.571899 553.692139,360.246918 554.721069,363.440765 
M556.679749,377.143951 
	C555.826294,376.387146 554.972900,375.630310 554.119446,374.873505 
	C553.825989,375.504089 553.532471,376.134674 553.239014,376.765259 
	C554.224792,377.063385 555.210510,377.361481 556.679749,377.143951 
M568.564087,380.294525 
	C568.293396,380.010773 568.022705,379.727020 567.751953,379.443329 
	C567.634583,379.555573 567.408875,379.759186 567.415344,379.766846 
	C567.667969,380.066101 567.941772,380.347504 568.564087,380.294525 
z"
      />
      <path
        fill="#FCFEFD"
        opacity="1.000000"
        stroke="none"
        d="
M629.601196,397.757263 
	C628.023621,400.774811 625.930542,403.661072 623.956848,406.626770 
	C622.525940,408.776703 621.253418,411.031952 619.824341,413.601624 
	C606.969788,413.979919 594.199646,413.889374 581.433777,414.083313 
	C577.897095,414.137024 576.722412,412.927063 576.966797,409.499603 
	C577.225769,405.868103 577.091553,402.208557 577.211914,398.066833 
	C594.575439,397.601593 611.856079,397.630768 629.601196,397.757263 
z"
      />
      <path
        fill="#FCFEFD"
        opacity="1.000000"
        stroke="none"
        d="
M635.717285,302.759705 
	C636.073975,306.955719 635.989502,311.026672 635.426208,315.673767 
	C634.729248,316.409790 634.511047,316.569641 634.292786,316.729523 
	C634.046570,316.874908 633.780823,316.956421 633.262207,316.501160 
	C631.194153,314.049438 629.610107,311.695679 627.427979,310.236206 
	C626.172424,309.396393 623.404419,309.278198 622.280579,310.120453 
	C620.301758,311.603516 620.895630,313.487061 623.369995,314.595764 
	C623.915100,314.840057 624.036255,316.030640 624.352600,316.785461 
	C623.221924,316.837006 622.091248,316.888550 620.497192,316.451172 
	C617.427124,314.681122 614.905640,313.056274 612.150696,312.373688 
	C611.505676,312.213867 610.070068,315.245300 608.998169,316.808533 
	C604.638184,316.868927 600.278137,316.929321 595.449097,316.517151 
	C592.682312,311.602051 584.034302,312.099487 580.892273,316.854828 
	C580.670349,316.916473 580.448425,316.978119 580.012695,316.510498 
	C578.840759,311.538208 575.657349,307.226379 579.312683,302.374573 
	C580.055237,302.075134 580.490479,301.930450 580.925781,301.785767 
	C581.404663,302.179474 581.883606,302.573181 582.831299,303.323792 
	C585.492920,305.073700 588.362305,308.149261 589.767761,307.584106 
	C598.279907,304.161102 607.044312,305.263855 615.745544,304.886292 
	C616.846436,304.838501 617.881226,303.268188 618.946594,302.403259 
	C618.946594,302.403229 619.133423,301.971100 619.129395,301.735382 
	C624.509033,301.877991 629.892578,302.256317 635.717285,302.759705 
M633.521851,310.312897 
	C632.976990,309.975281 632.432129,309.637634 631.887268,309.300018 
	C631.752991,309.573395 631.618713,309.846741 631.484375,310.120087 
	C632.017944,310.324524 632.551453,310.528992 633.521851,310.312897 
z"
      />
      <path
        fill="#FAFBFD"
        opacity="1.000000"
        stroke="none"
        d="
M579.005432,302.529327 
	C575.657349,307.226379 578.840759,311.538208 579.681335,316.412659 
	C578.832703,317.419647 578.101562,317.995209 577.229309,318.785706 
	C576.388245,318.089386 575.653320,317.202393 574.997314,316.260559 
	C574.219910,315.144348 573.510376,313.980865 572.771423,312.837860 
	C572.348938,312.977844 571.926514,313.117828 571.504089,313.257812 
	C571.218689,315.265381 570.933289,317.272919 570.762512,318.474304 
	C566.189331,320.836456 561.998230,322.665344 558.185791,325.089722 
	C555.685974,326.679382 555.146240,329.183838 557.332214,331.874420 
	C558.265930,333.023712 559.534302,335.416290 559.115051,335.892883 
	C557.074402,338.212250 555.440063,336.525818 553.999084,334.710999 
	C553.727234,334.368683 553.134033,334.281586 552.327759,334.030182 
	C552.653320,330.269287 553.358093,326.558075 554.021729,322.839508 
	C554.587036,319.671539 556.045044,317.533234 559.660278,318.008759 
	C563.313354,318.489227 564.263916,316.711121 564.063416,313.390076 
	C563.853516,309.912750 564.026611,306.412354 564.486145,302.471619 
	C569.628479,302.190613 574.316956,302.359955 579.005432,302.529327 
z"
      />
      <path
        fill="#060515"
        opacity="1.000000"
        stroke="none"
        d="
M442.173523,302.010986 
	C436.699738,302.318451 431.414032,302.228088 425.790894,301.953491 
	C425.544312,297.781281 425.630585,293.793182 425.727509,289.805359 
	C425.803467,286.679749 426.980072,285.074982 430.537537,284.960266 
	C443.311279,284.548615 443.320465,284.427490 442.887604,297.186005 
	C442.837372,298.665985 442.542877,300.137665 442.173523,302.010986 
z"
      />
      <path
        fill="#060515"
        opacity="1.000000"
        stroke="none"
        d="
M579.312683,302.374573 
	C574.316956,302.359955 569.628479,302.190613 564.474121,302.003357 
	C563.729187,297.572540 563.175232,293.149139 563.342957,288.753296 
	C563.391541,287.480560 565.392395,285.398895 566.701904,285.225464 
	C570.438904,284.730438 574.333252,284.669159 578.050964,285.216492 
	C579.251953,285.393280 580.805542,287.914856 580.963989,289.488068 
	C581.342529,293.245789 580.986694,297.077484 580.926636,301.332520 
	C580.490479,301.930450 580.055237,302.075134 579.312683,302.374573 
z"
      />
      <path
        fill="#A2AFBD"
        opacity="1.000000"
        stroke="none"
        d="
M394.982117,438.162354 
	C394.677826,434.982361 395.753204,433.607819 398.604797,433.912537 
	C399.094727,433.964874 399.595520,433.919037 400.091248,433.913727 
	C412.160767,433.784363 412.491577,434.091461 410.897888,446.281311 
	C410.732574,447.545807 409.262390,449.503540 408.213593,449.631592 
	C404.638092,450.067963 400.960449,450.038788 397.368134,449.698334 
	C396.495880,449.615692 395.288940,447.729065 395.129547,446.551056 
	C394.778015,443.952698 394.995728,441.277313 394.982117,438.162354 
z"
      />
      <path
        fill="#8C9BAB"
        opacity="1.000000"
        stroke="none"
        d="
M442.869812,511.771545 
	C443.082245,516.014648 441.699188,517.959351 437.481079,518.037781 
	C426.985748,518.232849 424.564484,515.335083 427.256653,504.749359 
	C427.524689,503.695435 429.040710,502.716339 430.199341,502.205994 
	C431.176178,501.775696 432.475372,502.068176 433.632050,502.056488 
	C442.864532,501.963226 442.864532,501.964355 442.869812,511.771545 
z"
      />
      <path
        fill="#FAFBFD"
        opacity="1.000000"
        stroke="none"
        d="
M618.537720,302.275024 
	C617.881226,303.268188 616.846436,304.838501 615.745544,304.886292 
	C607.044312,305.263855 598.279907,304.161102 589.767761,307.584106 
	C588.362305,308.149261 585.492920,305.073700 583.158569,303.366150 
	C585.548950,302.716156 588.078369,302.116516 590.613220,302.092621 
	C599.784119,302.006073 608.956848,302.112915 618.537720,302.275024 
z"
      />
      <path
        fill="#FAFBFD"
        opacity="1.000000"
        stroke="none"
        d="
M562.980713,386.439270 
	C564.912292,386.402588 566.974060,386.505524 568.725464,387.295990 
	C570.296631,388.005066 571.526428,389.470276 572.906860,390.601929 
	C571.767883,391.400818 570.713440,392.392426 569.464417,392.940369 
	C568.378296,393.416840 567.072083,393.391815 565.863159,393.588348 
	C565.870056,394.071655 565.876953,394.554962 565.883850,395.038269 
	C569.355103,395.038269 572.826294,395.038269 576.770264,395.063293 
	C577.307983,395.738342 577.372986,396.388336 577.439575,397.370300 
	C572.863953,397.702240 568.286865,397.702240 563.544495,397.702240 
	C563.349304,393.987823 563.162537,390.433014 562.980713,386.439270 
z"
      />
      <path
        fill="#CBD9E2"
        opacity="1.000000"
        stroke="none"
        d="
M619.524414,434.001099 
	C619.625305,435.973907 619.341736,437.968170 618.711182,440.085297 
	C616.434387,437.871826 615.673645,435.682129 619.524414,434.001099 
z"
      />
      <path
        fill="#FAFBFD"
        opacity="1.000000"
        stroke="none"
        d="
M561.161438,382.233276 
	C562.278625,381.215698 563.554810,380.439728 564.830994,379.663727 
	C565.107849,380.066833 565.384766,380.469971 565.661682,380.873108 
	C564.949341,381.508240 564.237000,382.143341 563.217163,382.916931 
	C562.379883,382.861877 561.850159,382.668365 561.161438,382.233276 
z"
      />
      <path
        fill="#FAFBFD"
        opacity="1.000000"
        stroke="none"
        d="
M636.551270,333.496094 
	C636.999573,339.923187 636.978271,346.367310 636.987671,353.260071 
	C628.598389,353.708679 620.178345,353.708679 611.322632,353.708679 
	C611.322632,346.824615 611.322632,340.316681 611.322632,333.513092 
	C619.604370,333.513092 627.843079,333.513092 636.551270,333.496094 
z"
      />
      <path
        fill="#FAFBFD"
        opacity="1.000000"
        stroke="none"
        d="
M577.354858,384.578979 
	C576.142578,383.673981 574.176758,382.348602 574.143677,380.976685 
	C573.886719,370.319519 573.923645,359.652039 574.104126,348.991730 
	C574.126282,347.682129 575.532410,346.395935 576.696289,345.049561 
	C577.229187,358.045837 577.362854,371.091431 577.354858,384.578979 
z"
      />
      <path
        fill="#FAFBFD"
        opacity="1.000000"
        stroke="none"
        d="
M624.693726,316.871979 
	C624.036255,316.030640 623.915100,314.840057 623.369995,314.595764 
	C620.895630,313.487061 620.301758,311.603516 622.280579,310.120453 
	C623.404419,309.278198 626.172424,309.396393 627.427979,310.236206 
	C629.610107,311.695679 631.194153,314.049438 633.063721,316.432190 
	C630.410645,316.876923 627.722717,316.917725 624.693726,316.871979 
z"
      />
      <path
        fill="#FAFBFD"
        opacity="1.000000"
        stroke="none"
        d="
M581.293335,316.944885 
	C584.034302,312.099487 592.682312,311.602051 594.990723,316.435303 
	C590.565796,316.895752 586.130066,316.965363 581.293335,316.944885 
z"
      />
      <path
        fill="#FAFBFD"
        opacity="1.000000"
        stroke="none"
        d="
M609.440063,316.887268 
	C610.070068,315.245300 611.505676,312.213867 612.150696,312.373688 
	C614.905640,313.056274 617.427124,314.681122 620.079224,316.369446 
	C616.710449,316.839752 613.296143,316.902863 609.440063,316.887268 
z"
      />
      <path
        fill="#FAFBFD"
        opacity="1.000000"
        stroke="none"
        d="
M634.546631,316.837646 
	C634.511047,316.569641 634.729248,316.409790 635.335144,316.130615 
	C635.722900,316.011353 635.885010,316.430634 635.890137,316.655365 
	C635.530396,316.902008 635.165405,316.923920 634.546631,316.837646 
z"
      />
      <path
        fill="#FAFBFD"
        opacity="1.000000"
        stroke="none"
        d="
M554.454895,363.181335 
	C553.692139,360.246918 553.195618,357.571899 552.528076,353.975647 
	C556.555054,357.114227 556.888123,359.516998 554.454895,363.181335 
z"
      />
      <path
        fill="#FAFBFD"
        opacity="1.000000"
        stroke="none"
        d="
M556.437988,377.401764 
	C555.210510,377.361481 554.224792,377.063385 553.239014,376.765259 
	C553.532471,376.134674 553.825989,375.504089 554.119446,374.873505 
	C554.972900,375.630310 555.826294,376.387146 556.437988,377.401764 
z"
      />
      <path
        fill="#FAFBFD"
        opacity="1.000000"
        stroke="none"
        d="
M568.388428,380.462891 
	C567.941772,380.347504 567.667969,380.066101 567.415344,379.766846 
	C567.408875,379.759186 567.634583,379.555573 567.751953,379.443298 
	C568.022705,379.727020 568.293396,380.010773 568.388428,380.462891 
z"
      />
      <path
        fill="#FAFBFD"
        opacity="1.000000"
        stroke="none"
        d="
M633.303467,310.523193 
	C632.551453,310.528992 632.017944,310.324524 631.484375,310.120087 
	C631.618713,309.846741 631.752991,309.573395 631.887268,309.300018 
	C632.432129,309.637634 632.976990,309.975281 633.303467,310.523193 
z"
      />
    </svg>
  );
};
